import React, { useEffect, useState } from 'react';
import axios from 'axios';
import authHeader from "../services/auth-header";
import { Spinner, Box } from "@chakra-ui/react";  // Import Spinner and Box from Chakra UI

export default function FeedBackImage({ imgName }) {
    const [imgSrc, setImgSrc] = useState(null);
    const [loading, setLoading] = useState(true);  // New state variable for loading
    const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);  // Set loading to true when starting fetch
                const headers = authHeader();
                const response = await axios.get(API_URL + `/api/get_feedback_img?photo_file_name=${imgName}`, { headers, responseType: 'arraybuffer' });

                const blob = new Blob([response.data], { type: 'image/jpeg' });
                const imageSrc = URL.createObjectURL(blob);

                setImgSrc(imageSrc);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);  // Set loading to false when fetch is finished
            }
        };

        fetchData();
    }, [imgName]);

    return (
        <Box>
            {loading ? (
                <Spinner size="xl" />  // Display a spinner if loading is true
            ) : (
                <img src={imgSrc} alt="Feedback" />
            )}
        </Box>
    );
}
