import React from 'react';
import {
    Text,
    Heading,
    Link,
    List,
    ListItem,
    Box,
    Code,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
} from "@chakra-ui/react";

const ChakraMarkdownComponents = {
    table: ({ children }) => <Box overflowX="auto"><Table variant="striped" colorScheme='gray' size="sm" >{children}</Table></Box>,
    thead: ({ children }) => <Thead>{children}</Thead>,
    tbody: ({ children }) => <Tbody>{children}</Tbody>,
    tr: ({ children }) => <Tr>{children}</Tr>,
    th: ({ children }) => <Th>{children}</Th>,
    td: ({ children }) => <Td>{children}</Td>,
    h1: ({ children }) => <Heading as="h1" size="xl" my={4}>{children}</Heading>,
    h2: ({ children }) => <Heading as="h2" size="lg" my={4}>{children}</Heading>,
    h3: ({ children }) => <Heading as="h3" size="md" my={4}>{children}</Heading>,
    h4: ({ children }) => <Heading as="h4" size="sm" my={4}>{children}</Heading>,
    h5: ({ children }) => <Heading as="h5" size="xs" my={4}>{children}</Heading>,
    h6: ({ children }) => <Text as="h6" fontWeight="bold" my={4}>{children}</Text>,
    ul: ({ children }) => <List styleType="disc" pl={5}>{children}</List>,
    ol: ({ children }) => <List as="ol" styleType="decimal" pl={5}>{children}</List>,
    li: ({ children }) => <ListItem>{children}</ListItem>,
    p: ({ children }) => <Text my={4}>{children}</Text>,
    a: ({ href, children }) => <Link href={href} color="teal.500" isExternal>{children}</Link>,
    em: ({ children }) => <Text as="em">{children}</Text>,
    strong: ({ children }) => <Text as="strong">{children}</Text>,
    delete: ({ children }) => <Text as="del">{children}</Text>,
    inlineCode: ({ children }) => <Code>{children}</Code>,

};

export default ChakraMarkdownComponents;
