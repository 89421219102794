// Chakra imports
import {
    Flex, Grid, SimpleGrid,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import React from "react";
import {PurchasePanel} from "../../components/PurchasePanel";
import {QuickLinksPanel} from "../../components/QuickLinksPanel";

function Purchase() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");

    return (
        <Flex flexDirection='column' pt="80px">
            <Grid
                templateColumns={{sm: "1fr", lg: "4fr 1fr"}}
                templateRows={{lg: "repeat(2, auto)"}}
                gap='20px'>
                <Card overflowX="hidden">
                    <PurchasePanel></PurchasePanel>
                </Card>
                <QuickLinksPanel></QuickLinksPanel>
            </Grid>
        </Flex>
    );
}

export default Purchase;
