import {
    Box,
    Table,
    Thead,
    Text,
    Tr,
    Tbody,
    Textarea,
    Td,
    Th,
    Input,
    VStack,
    HStack
} from '@chakra-ui/react'
import {
    selectToeflData,
    updateReadingText,
    updateConversations
} from "../redux/toeflSlice";

import { useDispatch, useSelector } from "react-redux";
import { selectGrading } from 'redux/shareSlice';

export default function ConversationTable(props) {
    const isGrading = props && props.dataType ? props.dataType == 'grading' : false;
    const isTpo = props && props.dataType ? props.dataType == 'tpo' : false;
    const isToeflData = props && props.dataType ? props.dataType == 'toeflData' : false;
    const dataType = props && props.dataType ? props.dataType : 'toeflData'
    const gradingData = useSelector(selectGrading);
    const toeflData = useSelector(selectToeflData);
    const tabIndex = toeflData.tabIndex;
    const dispatch = useDispatch();
    const DataMap = {
        'grading': gradingData.conversations ? JSON.parse(gradingData.conversations) : {},
        'tpo': toeflData.conversations ? JSON.parse(toeflData.conversations) : {},
        'toeflData': toeflData.conversations ? JSON.parse(toeflData.conversations) : { "0": ['Professor', ""], "1": ["Student A", ""], "2": ["Student B", ""] }
    }
    let conversationsData = DataMap[dataType]
    const handleTextChange = (e, key, index) => {
        let currentConversations = { ...conversationsData };
        currentConversations[key][index] = e.target.value;
        let newConversations = JSON.stringify(currentConversations); // Convert the object to a JSON string
        const readingText = Object.values(currentConversations).map(conversation => conversation.join(": ")).join(" \n");
        dispatch(updateReadingText(readingText));
        dispatch(updateConversations(newConversations));
    }



    return (
        <Box w="full" overflowX={'auto'}>
            <Table variant="striped">
                <Thead>
                    <Tr>
                        <Th verticalAlign="middle">Name</Th>
                        <Th verticalAlign="middle">Conversation</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {Object.entries(conversationsData).map(([key, value]) => (
                        <Tr key={key}>
                            <Td verticalAlign="middle">
                                {isGrading || isTpo || tabIndex === 0 ? (
                                    <Text
                                        textAlign={'justify'}
                                        whiteSpace='pre-line'
                                        color="gray.600"
                                        px="20px"
                                        fontSize={'md'}
                                    >
                                        {value[0]}
                                    </Text>
                                ) : (
                                    <Input
                                        value={value[0]}
                                        onChange={(e) => handleTextChange(e, key, 0)}
                                    />
                                )}
                            </Td>
                            <Td verticalAlign="middle">
                                {isGrading || isTpo || tabIndex === 0 ? (
                                    <Text
                                        textAlign={'justify'}
                                        whiteSpace='pre-wrap' // Ensure text wraps inside the cell
                                        color="gray.600"
                                        px="20px"
                                        fontSize={'md'}
                                    >
                                        {value[1]}
                                    </Text>
                                ) : (
                                    <Textarea
                                        value={value[1]}
                                        onChange={(e) => handleTextChange(e, key, 1)}
                                        size="sm"
                                        whiteSpace='pre-wrap'
                                        spellcheck="false"// Ensure text wraps and increases row height as needed
                                    />
                                )}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
}