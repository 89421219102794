import React from "react";
import {
    Box,
    Center,
    chakra,
    Container,
    Flex,
    Icon,
    List,
    ListItem,
    Select,
    Switch,
    Text,
    Tooltip
} from "@chakra-ui/react";
import { useGetUserPreferencesQuery, useUpdateUserPreferencesMutation } from "../services/cmApi";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectProduct, selectUserPreferences } from "../redux/generalSlice";
import AuthService from "../services/auth.service";
import { selectToeflData } from "redux/toeflSlice";
import { updateSelectedProduct } from "redux/toeflSlice";
import { useDispatch } from "react-redux";
export function SettingsPanel(props) {
    const { t, i18n } = useTranslation();
    useGetUserPreferencesQuery({});
    const dispatch = useDispatch();
    const toeflData = useSelector(selectToeflData);
    const userPreferences = useSelector(selectUserPreferences);

    // console.log(toeflData.selectedProduct)

    const [updateUserPreferencesRequest, isUpdateUserPreferencesError] = useUpdateUserPreferencesMutation();

    return (
        <Box width="100%" p="20px">
            <Flex flexDirection="column" width="full">
                <Flex flexDirection="row">
                    <Text m="10px">{t("language")}</Text>
                    <Box flex="1"></Box>
                    <Center>
                        <Select
                            value={i18n.language}
                            onChange={(e) => {
                                let prefs = {};
                                prefs['lang'] = e.target.value;
                                updateUserPreferencesRequest(prefs);
                                localStorage.setItem("lang", e.target.value);
                                i18n.changeLanguage(e.target.value);
                            }}
                            display={'block'}>
                            <option value='en'>English</option>
                            <option value='cn'>中文</option>
                        </Select>
                    </Center>
                </Flex>
                <Box display={!AuthService.getCurrentUser() ? 'none' : 'block'}>
                    <Flex flexDirection="row">
                        <Text m="10px">{t("default_product")}</Text>
                        <Box flex="1"></Box>
                        <Center>
                            <Select
                                value={userPreferences.product || 'toefl'}
                                onChange={(e) => {
                                    let prefs = {};
                                    prefs['product'] = e.target.value;
                                    // console.log(e.target.value);
                                    dispatch(updateSelectedProduct(e.target.value));
                                    localStorage.setItem('selectedProduct', e.target.value);
                                    updateUserPreferencesRequest(prefs);
                                }}
                                display={'block'}>
                                <option value='toefl'>{t("toefl")}</option>
                                <option value='ielts'>{t("ielts")}</option>
                            </Select>
                        </Center>
                    </Flex>
                </Box>
                <Flex flexDirection="row">
                    <Text m="10px">{t("hide_tutorial")}</Text>
                    <Box flex="1"></Box>
                    <Center>
                        <Switch
                            size='md'
                            isChecked={userPreferences.shouldSilenceFTUE}
                            onChange={(e) => {
                                let prefs = {};
                                prefs['shouldSilenceFTUE'] = userPreferences.shouldSilenceFTUE ? false : true;
                                updateUserPreferencesRequest(prefs)
                                    .catch(console.error);
                            }}
                        />
                    </Center>
                </Flex>
            </Flex>
        </Box>
    );
}