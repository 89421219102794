import React, {useEffect, useState} from "react";
import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    useDisclosure,
    VStack
} from "@chakra-ui/react";
import {useGetTaskHistoryQuery, useGetUserPreferencesQuery} from "../services/cmApi";
import {PaginatedTable} from "./PaginatedTable";
import {ViewResultPanel} from "./ViewResultPanel";
import Figure from "react-bootstrap/Figure";
import logoPng from "../assets/logo_long.png";
import AuthService from "../services/auth.service";
import {HistoryRow} from "./Tables/HistoryRow";
import {getCurrentProduct} from "./utils/common";
import {useSelector} from "react-redux";
import {selectUserPreferences} from "../redux/generalSlice";
import TaskFilterRow from "./TaskFilterRow";
import {useTranslation} from 'react-i18next';


const TASK_OPTIONS = {
    toefl: {
        all: ['all', 'reading_task1', 'reading_task2', 'reading_task3',
            'listening_task1', 'listening_task2', 'listening_task3', 'listening_task4', 'listening_task5',
            'listening_task6', 'speaking_task1', 'speaking_task2', 'speaking_task3', 'speaking_task4', 'speaking_generation',
            'writing_task1', 'writing_task2', 'writing_task3', 'writing_generation'
        ],
        reading: ['all', 'reading_task1', 'reading_task2', 'reading_task3'],
        listening: ['all', 'listening_task1', 'listening_task2', 'listening_task3', 'listening_task4', 'listening_task5', 'listening_task6'],
        speaking: ['all', 'speaking_task1', 'speaking_task2', 'speaking_task3', 'speaking_task4', 'speaking_generation'],
        writing: ['all', 'writing_task1', 'writing_task2', 'writing_task3', 'writing_generation']
    },
    ielts: {
        all: ['all', 'ispeaking_part1', 'ispeaking_part2_3', 'iwriting_task2'],
        speaking: ['all', 'ispeaking_part1', 'ispeaking_part2_3'],
        writing: ['iwriting_task2']
    }
};

const SectionOptions = {
    toefl: ['all', 'reading', 'listening', 'speaking', 'writing'],
    ielts: ['all', 'speaking', 'writing']
};

const SectionNames = {
    toefl: ['all', 'toefl_reading', 'toefl_listening', 'toefl_speaking', 'toefl_writing'],
    ielts: ['all', 'ielts_speaking', 'ielts_writing']
};

export function HistoryRightPanel(props) {
    let taskHistory = useGetTaskHistoryQuery({}, {skip: !AuthService.getCurrentUser()});
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {t, i18n} = useTranslation();
    useGetUserPreferencesQuery({});
    const userPreferences = useSelector(selectUserPreferences);
    const currentProduct = getCurrentProduct(userPreferences);
    const [taskName, setTaskName] = useState("all");
    const [sectionName, setSectionName] = useState('all');
    const section_options = SectionOptions[currentProduct] || [];
    const section_names = SectionNames[currentProduct].map(name => t(name));
    const task_options = TASK_OPTIONS[currentProduct]?.[sectionName] || [];
    const task_names = TASK_OPTIONS[currentProduct]?.[sectionName].map(name => t(name));

    // useEffect(() => {
    //     const defaultSection = currentProduct === 'toefl' ? 'reading' : 'speaking';
    //     setSectionName(defaultSection);
    // }, [currentProduct]);

    const getHistoryList = (task_name) => {
        if (taskHistory.status !== 'fulfilled') {
            return [];
        } else {
            if (task_name === 'all') {
                return taskHistory.data.data.filter(item => task_options.includes(item.gen_type));
            } else {
                return taskHistory.data.data.filter(item => item.gen_type === task_name);
            }
        }
    };

    return <>
        <Flex mx="40px" flexDirection="column" gap="10px">
            <TaskFilterRow
                rowName={t('Section')}
                options={section_options}
                names={section_names}
                selectedOption={sectionName}
                onChange={(value) => {
                    setSectionName(value);
                }}
            ></TaskFilterRow>
            <TaskFilterRow
                rowName={t('task')}
                options={task_options}
                names={task_names}
                selectedOption={taskName}
                onChange={(value) => {
                    setTaskName(value);
                }}
            ></TaskFilterRow>
        </Flex>
        <VStack spacing='20px' {...props}>
            <PaginatedTable data={getHistoryList(taskName)} filterProduct={currentProduct}
                            onRowSelected={onOpen} rowComponent={HistoryRow}></PaginatedTable>
            <Drawer onClose={onClose} isOpen={isOpen} size="full" placement="left">
                <DrawerOverlay/>
                <DrawerContent bgColor="gray.100">
                    <DrawerCloseButton mr="20px" mt="20px" color="white"/>
                    <DrawerHeader bg="blue.800">
                        <Box bg="blue.800">
                            <Figure.Image width={200} height={20} src={logoPng} style={{marginBottom: "0px"}}/>
                        </Box>
                    </DrawerHeader>
                    <DrawerBody bg="white" px={0}>
                        <ViewResultPanel></ViewResultPanel>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </VStack>
    </>
}