import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useDispatch, useSelector} from "react-redux";

import {Box, Button, Center, Flex, Text, Textarea, useDisclosure} from '@chakra-ui/react'
import {clearAnswers, selectIeltsData, updateAnswer, updateQid, updateQuestions} from 'redux/ieltsSlice';
import {useGetQuestionByQidQuery} from '../services/cmApi';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import IeltsSubmitButton from "./IeltsSubmitButton";
import IeltsResponsePanel from './IeltsResponsePanel';


export default function IPBWritingPracticePanel(props) {
    const ieltsData = useSelector(selectIeltsData);
    const dispatch = useDispatch();
    const selectedPart = ieltsData.selectedPart;
    const { t, i18n } = useTranslation();
    let answer = ieltsData.answerText ? ieltsData.answerText : "";
    let wordCount = answer.trim() === "" ? 0 : answer.trim().split(/\s+/).length;
    const query = new URLSearchParams(window.location.search);
    const qid = query.get("qid")
    const questionData = useGetQuestionByQidQuery({ qid: query.get("qid") });

    let questionText = ""
    let history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const { isOpen: isOpenQuestionModal, onOpen: onOpenQuestionModal, onClose: onCloseQuestionModal } = useDisclosure();



    const getMainUI = () => {
        return (
            <>
                <Box ml="15px" mr="15px">

                    <Flex flexDirection={"row"} margin={0}>
                        <Text mt={"10px"} fontWeight={600} fontSize={{ base: "lg", lg: "xl" }} mt="10px" color={'gray.700'} paddingLeft={3}>{t('question_text')}
                        </Text>
                        <Box flex={"1"}></Box>


                        <Button mb={2} colorScheme={'yellow'} rounded="full" onClick={() => {
                            dispatch(clearAnswers());
                            history.push('/ielts/writing');
                        }}>
                            {t("exit")}
                        </Button>


                    </Flex>

                    <Text
                        textAlign={'justify'}
                        whiteSpace='pre-line'
                        color="gray.600"
                        px="20px"
                        fontSize={'md'}>
                        {ieltsData.questions[0]}
                    </Text>
                    <Textarea
                        onChange={(e) => {
                            dispatch(updateAnswer(e.target.value));
                        }} value={ieltsData.answerText}
                        height="200px"
                        margin="10px"
                        spellcheck="false"
                    ></Textarea>

                    <Text align={"right"} color={'grey'} p="0" m="0">words: {wordCount}</Text>
                </Box>
                <Center>
                    <IeltsSubmitButton></IeltsSubmitButton>
                </Center>
            </>
        );
    }

    return (
        <>
            <Flex direction={'column'} gap={5}>
                {
                    questionData.status === 'fulfilled' ? getMainUI() : <Text> loading </Text>
                }
            </Flex>
            <IeltsResponsePanel></IeltsResponsePanel>

        </>
    );


}



