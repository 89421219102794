import React, {useEffect, useState} from "react";
import {
    Box,
    Button, Center,
    Checkbox,
    Container,
    Divider, Flex,
    FormControl,
    FormLabel,
    Heading,
    HStack, Icon,
    Input, Link,
    Stack,
    Text, useDisclosure,
} from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import {useHistory, useLocation} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useGetGaolianPriceQuery, useGetPurchaseInfoQuery, useLoginMutation} from "./services/cmApi";
import Figure from "react-bootstrap/Figure";
import logoPng from "./assets/logo_long.png";
import {LineItemCard} from "./components/LineItemCard";
import {BiRightArrowCircle}  from "react-icons/bi";
import ContactUsModal from "./components/modals/ContactUsModal";
import {useCookies} from "react-cookie";


const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const ShortcutPurchase = (props) => {
    const { t, i18n } = useTranslation();
    const [email, setEmail] = useState("");

    const toast = useToast();
    const query = useQuery();
    const [cookies, setCookie] = useCookies(['user_source'])


    useEffect(() => {
        if (query.get('existing_user_success') === 'true') {
            toast({
                title: '购买成功！',
                status: "info",
                duration: 30000,
                isClosable: true,
            });
        } else if (query.get('new_user_success') === 'true') {
            toast({
                title: '购买成功！',
                description: '请打开验证邮件完成注册',
                status: "info",
                duration: 30000,
                isClosable: true,
            });
        }
        let expires = new Date()
        expires.setTime(expires.getTime() + 3600 * 24 * 1000)
        setCookie('user_source', 'gaolian', { path: '/',  expires})
    }, []);  // <-- empty dependency array to run only once on mount

    let price_info = useGetGaolianPriceQuery({});

    const item = price_info.status === 'fulfilled' ? price_info.data.data : {};
    const { isOpen: isOpenContactUs, onOpen: onOpenContactUs, onClose: onCloseContactUs } = useDisclosure()

    return (

        <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
            <Stack spacing="8">
                <Stack direction='row' spacing='12px' align='center' justify='center' bgColor='gray.500' p='10px' borderRadius='xl'>
                    <Link href='/'>
                        <Box >
                            <Figure.Image className="circlex-logo" width={200} height={50} src={logoPng}
                                          style={{marginBottom: "0px"}}/>
                        </Box>
                    </Link>
                </Stack>
                <LineItemCard hideButton={true} item={item}></LineItemCard>
                <FormControl>
                    <FormLabel htmlFor="email" fontWeight="bold">{t("email")}</FormLabel>
                    <Text fontSize='sm' color='gray.500'>此邮箱将成为你的登录邮箱</Text>
                    <Input mt='10px' id="email" type="email" onChange={(e) => setEmail(e.target.value)} />
                </FormControl>
                <Center mt="20px">
                    <Button
                        colorScheme="yellow"
                        bgColor={item.promoted? "#F96730": "yellow.400"}
                        color={item.promoted? "white": "black"}
                        borderRadius='20px'
                        width="50%"
                        onClick={() => {
                            window.location.href = API_URL + "/api/start_checkout_with_email?email=" + email + "&price_id=" + item.price_id
                        }}
                    >
                        {t('buy_now')}
                    </Button>
                </Center>
                <Center>
                    <Text> 需要帮助?</Text>
                    <Box
                        color="blue.500"
                        width="35px"
                        _hover={{
                            color: "blue.300",
                            cursor: "pointer"
                        }}
                        onClick={onOpenContactUs}
                    >
                        <BiRightArrowCircle color='inherit' size="xs"/>
                    </Box>
                    <ContactUsModal
                        isOpen={isOpenContactUs} onOpen={onOpenContactUs} onClose={onCloseContactUs}
                        title={'contact_us_message'}
                    ></ContactUsModal>
                </Center>
            </Stack>
        </Container>
    )}

export default ShortcutPurchase;