import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    Button,
    Center,
    Flex,
    Heading,
    Image,
    Text,
    useDisclosure,
    SimpleGrid
} from '@chakra-ui/react'
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
    useToast,
} from "@chakra-ui/react";

import {
    selectIeltsData,
    addUserAudio,
    updateCurrentQuestionIdx,
} from 'redux/ieltsSlice';
import AudioRecorder from './AudioRecorder';
import { useGetQuestionByQidQuery, useUploadUserAudioMutation } from '../services/cmApi';
import LLAudioPlayer from './LLAudioPlayer';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { updateQuestions, updateQid } from 'redux/ieltsSlice';
import { clearAnswers } from 'redux/ieltsSlice';
import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import IconBox from "./Icons/IconBox";
import IeltsSubmitButton from "./IeltsSubmitButton";
import IeltsResponsePanel from './IeltsResponsePanel';
import UploadAudioFileButton from "./UploadAudioFileButton";


export default function IPBPracticePanel(props) {
    const ieltsData = useSelector(selectIeltsData);
    const dispatch = useDispatch();
    const selectedPart = ieltsData.selectedPart;
    const { t, i18n } = useTranslation();
    const [isRecording, setIsRecording] = useState(false);
    const currentQuestionIdx = ieltsData.currentQuestionIdx;
    const currentUserAudio = ieltsData.userAudios[`question_${currentQuestionIdx}`];
    const lang = i18n.language
    const query = new URLSearchParams(window.location.search);
    const qid = query.get("qid")
    const questionData = useGetQuestionByQidQuery({ qid: qid });
    const [uploadUserAudioRequest, uploadUserAudioError] = useUploadUserAudioMutation();

    let history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const { isOpen: isOpenQuestionModal, onOpen: onOpenQuestionModal, onClose: onCloseQuestionModal } = useDisclosure();
    const toast = useToast()
    const onAudioSave = (data) => {
        // setRecordingData(data);
        const key = `question_${ieltsData.currentQuestionIdx}`;
        let reqData = {
            "file": data,
        }
        uploadUserAudioRequest(reqData).then(function (response) {
            console.log("response", response);
            if (response.data.filename) {
                const filename = response.data.filename;
                dispatch(addUserAudio({ data: data, key: key, audio_name: filename }));

                //dispatch(updateToelfGid(response.data.data.gid));
                console.log("User audio uploaded successfully!", response.data);
            }
        }).catch(function (error) {
            console.log("User audio upload failed.", error)
            toast({
                title: t("error_audio_save_failed"),
                status: "error",
                duration: 2000,
                isClosable: true,
            });
        });
        //dispatch(addUserAudio({ data: data, key: key }));
    };

    const getNumQuestions = () => {
        if (questionData.status === 'fulfilled' && questionData.data.status == 'success') {
            return questionData.data.data.questions.length;
        } else {
            return 0;
        }
    }
    const Stepper = () => {
        const questionsCount = getNumQuestions();
        return (
            <Flex alignItems="center" display={questionsCount > 1 ? 'flex' : 'none'}>
                {Array.from({length: questionsCount}, (_, idx) => (
                    <Button ml="5px" bg={
                        ieltsData.userAudios[`question_${idx}`]?
                        'green.400': 'grey'} color={'white'} height={'24px'}
                            key={idx + 1}
                            onClick={() => {
                                dispatch(updateCurrentQuestionIdx(idx))
                            }
                            }>
                        {idx + 1}
                    </Button>))}
            </Flex>
        );
    };

    const banner = () => {
        const isPart1 = selectedPart == '1';

        return (
            <Flex
                flexDirection="row"
                m={5}
                padding={2}
            >
                <Flex
                    flexDirection="column"
                    alignItems="start"
                    spacing={3}

                    display={isPart1 || selectedPart == '2' ? 'flex' : 'none'}
                >
                    <Heading size="md" mb={2}>
                        {t("topic")}:  {
                            typeof questionData.data.data.short_summary[lang] === 'object' && questionData.data.data.short_summary[lang] !== null
                                ? questionData.data.data.short_summary[lang].name
                                : questionData.data.data.short_summary[lang] || questionData.data.data.short_summary.cn || questionData.data.data.short_summary.en
                        }
                    </Heading>
                    {/*<Text fontSize="sm" color="gray.600">*/}
                    {/*    Please answer questions 1-{getNumQuestions()}*/}
                    {/*</Text>*/}
                    <Flex alignItems={'center'}>
                        {questionOrderInfo()}
                        <Stepper></Stepper>
                    </Flex>
                </Flex>

                <Box flex="1"></Box>

                <Center>
                    <Button colorScheme={'yellow'} rounded="full" onClick={() => {
                        dispatch(clearAnswers());
                        history.push('/ielts/speaking');
                    }}>
                        {t("exit")}
                    </Button>
                </Center>

            </Flex>
        );
    };
    const questionOrderInfo = () => {
        return (<>
            <Box ml="5px" fontSize="sm" color="gray.600" display={selectedPart == '1' ? 'block' : 'none'}>
                <Text> Part 1 Question {ieltsData.currentQuestionIdx + 1}/{getNumQuestions()}
                </Text>
            </Box>
            <Box ml="5px" textAlign='start' fontSize="sm" color="gray.600" display={selectedPart == 2 && ieltsData.currentQuestionIdx < 1 ? 'block' : 'none'}>
                <Text> Part 2 Question 1/1
                </Text>
            </Box>
            <Box ml="5px" textAlign='start' fontSize="sm" color="gray.600" display={selectedPart == 2 && ieltsData.currentQuestionIdx >= 1 ? 'block' : 'none'}>
                <Text> Part 3 Question {ieltsData.currentQuestionIdx}/{getNumQuestions() - 1}
                </Text>
            </Box>
        </>)
    }

    const getQuestionString = () => {
        if (ieltsData.selectedPart == 1) {
            return t("question_text") + " " + (ieltsData.currentQuestionIdx + 1);
        } else if (ieltsData.currentQuestionIdx == 0) {
            return "Part 2 " + t("question_text");
        } else {
            return "Part 3 " + t("question_text") + " " + (ieltsData.currentQuestionIdx);
        }
    }

    const getMainUI = () => {
        return (
            <>
                {banner()}
                <Center>
                    <Image src={"/interviewer.png"} boxSize={{ base: "200px", md: "300px" }} objectFit='cover' />
                </Center>
                <Flex direction={'column'} gap={5} mt={5}>
                    <Center>
                        <SimpleGrid columns={3} columnGap='25px'>
                            <Center>
                                <Text fontWeight="bold" fontSize="md" color="blue.500">
                                    {getQuestionString()}
                                </Text>
                            </Center>
                            <Center>
                                <LLAudioPlayer src={questionData.data.data.questions[ieltsData.currentQuestionIdx].audio} autoPlay controls></LLAudioPlayer>
                            </Center>
                            <Popover isOpen={isOpen} onClose={() => setIsOpen(false)} closeOnBlur={false} placement="top">
                                <PopoverTrigger>
                                    <Center>
                                        <Button colorScheme="green" size='xs' rounded='full' px='10px' onClick={() => setIsOpen(true)}>{t("transcript")}</Button>
                                    </Center>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverHeader>{getQuestionString()}</PopoverHeader>
                                    <PopoverBody>
                                        <div dangerouslySetInnerHTML={{ __html: questionData.data.data.questions[ieltsData.currentQuestionIdx].question }} />
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                            <Center>
                                <Text fontWeight="bold" fontSize="md" color="blue.500"
                                    display={currentUserAudio && !isRecording ? "inline-block" : "none"}
                                >
                                    {t("your_answer")}
                                </Text>
                            </Center>
                            <Center>
                                <Box display={currentUserAudio && !isRecording ? "inline-block" : "none"}>
                                    <LLAudioPlayer src={currentUserAudio} autoPlay controls ></LLAudioPlayer>
                                </Box>
                            </Center>
                        </SimpleGrid>
                    </Center>
                    <Center>
                        <Flex flexDirection="row">
                            <Button
                                onClick={() => {
                                    dispatch(updateCurrentQuestionIdx(ieltsData.currentQuestionIdx - 1))
                                }}
                                isDisabled={isRecording || ieltsData.currentQuestionIdx === 0}
                                colorScheme="green"
                                rounded="full"
                                px="20px"
                            >
                                <IconBox
                                    color="inherit"
                                    h="15px"
                                    w="15px"
                                >
                                    <BsChevronDoubleLeft />
                                </IconBox>
                                <Center>
                                    <Text ml="5px" fontSize="sm">
                                        {t("previous_question")}
                                    </Text>
                                </Center>
                            </Button>
                            <Box m="5px"></Box>
                            <UploadAudioFileButton
                                    onLoadEnd={(data) => {
                                        //dispatch(updateAudioFile(data));
                                        //dispatch(updateAudioSource("FILE"));
                                        onAudioSave(data);
                                        console.log("onLoadEnd!");
                                    }}
                            ></UploadAudioFileButton>
                            <Box m="5px"></Box>
                            <AudioRecorder onAudioSave={onAudioSave} isRecording={isRecording} setIsRecording={setIsRecording} showRecordingFunctions={true} />
                            <Box m="5px"></Box>
                            <Button
                                onClick={() => {
                                    dispatch(updateCurrentQuestionIdx(ieltsData.currentQuestionIdx + 1))
                                }}
                                isDisabled={isRecording || ieltsData.currentQuestionIdx === (getNumQuestions() - 1)}
                                colorScheme="green"
                                rounded="full"
                                px="20px"
                            >
                                <Text mr="5px" fontSize="sm">
                                    {t("next_question")}
                                </Text>
                                <IconBox
                                    color="inherit"
                                    h="15px"
                                    w="15px"
                                >
                                    <BsChevronDoubleRight />
                                </IconBox>
                            </Button>
                        </Flex>
                    </Center>
                    <Center>
                        <IeltsSubmitButton isRecording={isRecording}></IeltsSubmitButton>
                    </Center>
                </Flex>
            </>
        );
    }

    return (
        <>
            <Flex direction={'column'} gap={5}>
                {
                    questionData.status === 'fulfilled' ? getMainUI() : <Text> loading</Text>
                }
            </Flex>
            <IeltsResponsePanel></IeltsResponsePanel>

        </>
    );


}



