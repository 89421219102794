const getTimeDescription = (t, ts) => {
    const age = Date.now() / 1000 - ts;

    if (age < -86400) {
        const count = Math.floor(-age / 86400);
        return count < 2 ? t('day_from_now_one') : t('day_from_now_other').replace("{0}", count);
    } else if (age < -3600) {
        const count = Math.floor(-age / 3600);
        return count < 2 ? t('hour_from_now_one') : t('hour_from_now_other').replace("{0}", count);
    } else if (age < -60) {
        const count = Math.floor(-age / 60);
        return count < 2 ? t('minute_from_now_one') : t('minute_from_now_other').replace("{0}", count);
    } else if (age < 0){
        return t('immediate');
    } else if (age < 60) {
        return t('just_now');
    } else if (age < 3600) {
        const count = Math.floor(age / 60);
        return count + " " + (count < 2 ? t('minute_ago_one') : t('minute_ago_other'));
    } else if (age < 86400) {
        const count = Math.floor(age / 3600);
        return count + " " + (count < 2 ? t('hour_ago_one') : t('hour_ago_other'));
    } else {
        const count = Math.floor(age / 86400);
        return count + " " + (count < 2 ? t('day_ago_one') : t('day_ago_other'));
    }
};

export default getTimeDescription;