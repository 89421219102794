import React from "react";
import {
    Flex,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import CardHeader from "./Card/CardHeader";
import CardBody from "./Card/CardBody";
import Card from "./Card/Card";
import {ViewFeedBackPanel} from "./ViewFeedBackPanel";

export function AdminViewFeedbackPanel() {
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <Card
            overflowX={{sm: "scroll", xl: "hidden"}}
        >
            <CardHeader p="6px 0px 22px 0px">
                <Flex direction="column">
                    <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
                        Feedback
                    </Text>
                </Flex>
            </CardHeader>
            <CardBody>
                <ViewFeedBackPanel />
            </CardBody>
        </Card>
    );
}