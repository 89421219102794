import React, {useCallback, useEffect, useRef, useState} from 'react';
import { withRouter } from "react-router";
import axios from 'axios';


import {
    Box,
    Button,
    Center,
    Flex,
    HStack,
    Icon,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Progress,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Spacer,
    Text,
    Textarea,
    Tooltip,
    useDisclosure,
    VStack
} from '@chakra-ui/react'
import {useHistory} from "react-router-dom";
import {
    incrementRecordingSeconds,
    loadQidQuestionData,
    selectToeflData,
    updateAnswer,
    updateAudioFile,
    updateIntervalId,
    updateRecordingSeconds
} from "../redux/toeflSlice";
import { useDispatch, useSelector } from "react-redux";
import logoPng from "../assets/logo_long.png";
import TPOListeningJPEG from "../assets/TPOListening.jpeg"
import TPONextPng from "../assets/btnNext.png";
import TPOVolumePng from "../assets/btnVolume.png";
import { useTranslation } from 'react-i18next';
import ConversationTable from '../components/ConversationTable';
import { AiOutlineCoffee } from "react-icons/ai";
import { AuthorizedBackendGetRequest } from "../components/utils/common";
import {debounce} from "lodash";


const taskStates = {
    'speaking': {
        task1: [{ showReading: false, showListening: false, showQuestion: false, allowInput: false, recording: false, meta: "instruction", preparationTime: 15, answeringTime: 45 },
        { showReading: false, showListening: false, showQuestion: true, allowInput: false, recording: false, meta: "Preparation", duration: 15 },
        { showReading: false, showListening: false, showQuestion: true, allowInput: false, recording: true, meta: "Answering", duration: 45 },
        { exit: true }],

        task2: [{ showReading: false, showListening: false, showQuestion: false, allowInput: false, recording: false, meta: "instruction", preparationTime: 30, answeringTime: 60, readingTime: 45 },
        { showReading: true, showListening: false, showQuestion: false, allowInput: false, recording: false, meta: "Reading", duration: 45 },
        { showReading: false, showListening: true, showQuestion: false, allowInput: false, recording: false, meta: "Listening", duration: 999 },
        { showReading: false, showListening: false, showQuestion: true, allowInput: false, recording: false, meta: "Preparation", duration: 30 },
        { showReading: false, showListening: false, showQuestion: true, allowInput: false, recording: true, meta: "Answering", duration: 60 },
        { exit: true }],

        task3: [{ showReading: false, showListening: false, showQuestion: false, allowInput: false, recording: false, meta: "instruction", preparationTime: 30, answeringTime: 60, readingTime: 45 },
        { showReading: true, showListening: false, showQuestion: false, allowInput: false, recording: false, meta: "Reading", duration: 45 },
        { showReading: false, showListening: true, showQuestion: false, allowInput: false, recording: false, meta: "Listening", duration: 999 },
        { showReading: false, showListening: false, showQuestion: true, allowInput: false, recording: false, meta: "Preparation", duration: 30 },
        { showReading: false, showListening: false, showQuestion: true, allowInput: false, recording: true, meta: "Answering", duration: 60 },
        { exit: true }],

        task4: [{ showReading: false, showListening: false, showQuestion: false, allowInput: false, recording: false, meta: "instruction", preparationTime: 20, answeringTime: 60 },
        { showReading: false, showListening: true, showQuestion: false, allowInput: false, recording: false, meta: "Listening", duration: 999 },
        { showReading: false, showListening: false, showQuestion: true, allowInput: false, recording: false, meta: "Preparation", duration: 20 },
        { showReading: false, showListening: false, showQuestion: true, allowInput: false, recording: true, meta: "Answering", duration: 60 },
        { exit: true }]

    },
    'writing': {
        task1: [{ showReading: true, showListening: false, showQuestion: false, allowInput: false, recording: false, meta: "Reading", duration: 180 },
        { showReading: false, showListening: true, showQuestion: false, allowInput: false, recording: false, meta: "Listening", duration: 10 },
        { showReading: true, showListening: false, showQuestion: true, allowInput: true, recording: false, meta: "Answering", duration: 1200 },
        { exit: true }],

        task2: [{ showReading: false, showListening: false, showQuestion: true, allowInput: true, recording: false, meta: "Answering", duration: 1800 }, { exit: true }],

        task3: [{ showReading: false, showListening: false, showQuestion: true, allowInput: true, recording: false, discussion: true, meta: "Answering", duration: 600 }, { exit: true }]
    }
}
// console.log(taskStates['writing']['task3'][0]['conversation'])

export function TPOPracticePage(props) {
    const history = useHistory();
    const [showTooltip, setShowTooltip] = React.useState(false)
    const { t, i18n } = useTranslation();


    // const { section, task_name, tpo_number } = useParams();
    const query = new URLSearchParams(window.location.search);
    const section = query.get("section")
    const task_name = query.get("task_name")
    const qid = query.get("qid")


    useEffect(() => {
        AuthorizedBackendGetRequest(`/api/get_question_by_qid?qid=${qid}`)
            .then(function (response) {
                dispatch(loadQidQuestionData(response.data))
            }).catch(function (error) {
            });
    }, [])

    const toeflData = useSelector(selectToeflData);
    const dispatch = useDispatch();
    const listeningAudioPath = toeflData.listeningAudioPath;
    const [state, setState] = useState(0)


    const url = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioElement, setAudioElement] = useState(null)
    const [audioDuration, setAudioDuration] = useState(null)
    const [volume, setVolume] = useState(1);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure()

    const stateData = taskStates[section][task_name][state]
    const recording = stateData['recording']
    const showReading = stateData['showReading']
    const showListening = stateData['showListening']
    const showQuestion = stateData['showQuestion']
    const allowInput = stateData['allowInput']
    const isDiscussion = stateData['discussion'] == true // new tpo
    const isWriting = task_name === 'writing';
    const isSpeaking = task_name === 'speaking';


    const metaInfo = stateData['meta'];
    const isInstruction = metaInfo === 'instruction';
    const isPreparation = metaInfo === 'Preparation';
    const isAnswering = metaInfo === 'Answering';
    const timeLimit = stateData['duration']
    // const preparationTime = stateData['preparationTime']
    // const answeringTime = stateData['answeringTime']
    const readingTime = stateData['readingTime']
    const [timeLeft, setTimeLeft] = useState(timeLimit);
    const showContinueButton = isInstruction ? false : true
    const showTimer = !isInstruction
    const [preparationTime, setPreparationTime] = useState(stateData['preparationTime']);
    const [answeringTime, setAnsweringTime] = useState(stateData['answeringTime']);
    const [showVolume, setShowVolume] = useState(false);
    const [wordCount, setWordCount] = useState(0);
    const [isPaused, setIsPaused] = useState(false);


    const handleInputChange = (e) => {
        const newAnswer = e.target.value;
        dispatch(updateAnswer(newAnswer));
        const text = newAnswer.trim();
        const wordCount = text === '' ? 0 : text.split(/\s+/).length;
        debouncedSaveAnswer(newAnswer);
        console.log("toeflData",toeflData)
        setWordCount(wordCount);
    }

    const textRef = useRef();

    const handleCut = () => {
        document.execCommand('cut');
    }

    const handleCopy = () => {
        textRef.current.select();
        document.execCommand('copy');
    }

    const handlePaste = async () => {
        const text = await navigator.clipboard.readText();
        const newValue = toeflData.answer + text;
        dispatch(updateAnswer(newValue));
    }

    const handleVolumeChange = (newVolume) => {
        // let newVolume = e.target.value;
        setVolume(newVolume);
        if (audioElement) {
            audioElement.volume = newVolume;
        }
    };
    const handleShowVolume = () => {
        setShowVolume(!showVolume)
    }
    const handleAudioEnd = () => {
        setIsPlaying(false);
        handleContinueClick();
    };


    useEffect(() => {
        if (audioElement) { audioElement.volume = volume; }
    }, [volume])


    useEffect(() => {
        // timer
        if (timeLeft === undefined || timeLeft === null || isPaused) {
            return;
        }
        const countdownId = setInterval(() => {
            setTimeLeft(timeLeft => {
                if (timeLeft === 0) {
                    const newState = state + 1;
                    // if (newState >= taskStates[section][task_name].length) {
                    //     if (isRecording) {
                    //         handleRecord(); // Stop recording before redirecting
                    //     }
                    //     clearInterval(countdownId);
                    //     if (tpo_number) {
                    //         history.push(`/toefl/${section}/practice?task_name=${task_name}&tpo_number=${tpo_number}`)
                    //     } else {
                    //         history.push(`/toefl/${section}/practice?qid=${qid}`)
                    //     }
                    //     return 0;
                    // }
                    // else {
                    setState(newState);
                    // }

                } else {
                    if (recording && !isRecording) {
                        return timeLeft
                    }
                    return timeLeft - 1;

                }
            });
        }, 1000);
        return () => {
            clearInterval(countdownId);
        };
    }, [timeLeft, isRecording, isPaused]);


    // useEffect(() => {
    //     if (showListening && !isPlaying) {
    //         handleAudioPlay(listeningAudioPath, true);
    //         setTimeLeft(audioDuration);
    //     } else if (showListening && isPlaying) {
    //         setTimeLeft(audioDuration);
    //     } else if (!showListening && isPlaying) {
    //         handleAudioPlay(listeningAudioPath, false);
    //     }
    //     else if (metaInfo === "instruction") {
    //         // do the instruction
    //         return
    //     }
    //     else {
    //         let duration = timeLimit;
    //         setTimeLeft(duration);
    //     }
    // }, [showListening, audioElement, audioDuration, isPlaying, state]);

    useEffect(() => {
        recall();
    }, [state]);

    function recall() {
        console.log('State changed to:', state);

        if (taskStates[section][task_name][state]["exit"]) {
            if (isRecording) {
                handleRecord(); // Stop recording before redirecting
            }
            history.push(`/toefl/${section}/practice?qid=${qid}&noreset=1`)
        }

        if (showListening) {
            if (!audioElement) {
                handleAudioPlay(listeningAudioPath ? listeningAudioPath : toeflData.listeningAudio, true);
            }
            return;
        } else if (!showListening && audioElement) {
            handleAudioPlay(listeningAudioPath ? listeningAudioPath : toeflData.listeningAudio, false);
        }
        else if (metaInfo === "instruction") {
            // do the instruction
            return
        }
        else {
            let duration = timeLimit;
            setTimeLeft(duration);
            console.log('set')
        }
    }

    useEffect(() => {
        return () => {
            if (audioElement) {
                audioElement.removeEventListener("ended", handleAudioEnd, false);
                audioElement.pause();
                URL.revokeObjectURL(audioElement.src);
                setAudioElement(null);
                setIsPlaying(false);
            }
        };
    }, [audioElement]);

    useEffect(() => {
        console.log('timelimit', timeLimit, 'state', state)
    }, [state])


    const saveAnswer = (answer) => {
        const key = `toefl_answer_${qid}`;
        localStorage.setItem(key, answer);
        console.log("Saved answer:", answer);
    };

    const debouncedSaveAnswer = useCallback(
        debounce((answer) => saveAnswer(answer), 2000),
        [qid]
    );

    const handleAudioPlay = (listeningAudioPath, shouldPlay) => {

        if (shouldPlay && !audioElement) {
            let request = axios.get(listeningAudioPath, { responseType: 'blob' });
            request.then((response) => {
                const audioBlob = response.data;
                const audioUrl = URL.createObjectURL(audioBlob);
                const audio = new Audio(audioUrl);
                audio.addEventListener('ended', handleAudioEnd);
                audio.addEventListener('loadedmetadata', () => {
                    setAudioDuration(Math.ceil(audio.duration));
                    setTimeLeft(Math.ceil(audio.duration));
                });
                setAudioElement(audio);
                audio.play();
                setIsPlaying(true);
            })
                .catch((error) => {
                    console.error('Error streaming audio:', error);
                });
        } else if (!shouldPlay && audioElement) {
            audioElement.pause();
            audioElement.removeEventListener('ended', handleAudioEnd);
            URL.revokeObjectURL(audioElement.src);
            setAudioElement(null);
            setIsPlaying(false);
        }
    };

    const handleContinueClick = () => {
        console.log('continue');
        const newState = state + 1;
        //newState >= taskStates[section][task_name].length
        if (taskStates[section][task_name][state]["exit"]) {
            if (isRecording) {
                handleRecord(); // Stop recording before redirecting
            }
            history.push(`/toefl/${section}/practice?qid=${qid}`)
        }
        else {
            setTimeLeft(taskStates[section][task_name][newState].duration);
            setState(newState)
        }
    };
    const handleRecord = async () => {
        if ("MediaRecorder" in window) {
            if (!isRecording) {
                const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const recorder = new MediaRecorder(mediaStream);
                setMediaRecorder(recorder);

                recorder.start();

                recorder.addEventListener('dataavailable', (e) => {
                    const file = new File([e.data], 'recorded_audio.webm', { type: e.data.type });
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = async () => {
                        dispatch(updateAudioFile(reader.result));
                        recorder.stream.getTracks().forEach((t) => t.stop());
                        setMediaRecorder(null);
                    }
                });

                setIsRecording(true);
                dispatch(updateRecordingSeconds(0));
                const intervalId = setInterval(() => {
                    dispatch(incrementRecordingSeconds());
                }, 1000);
                dispatch(updateIntervalId(intervalId));
            } else {
                if (mediaRecorder) {
                    mediaRecorder.stop();
                }

                setIsRecording(false);
                clearInterval(toeflData.intervalId);
            }
        } else {
            alert("The MediaRecorder API is not supported in your browser.");
        }
    };
    const elapsedTime = showListening ? audioDuration - timeLeft : timeLimit - timeLeft;
    const progress = showListening ? (elapsedTime / audioDuration) * 100 : (elapsedTime / timeLimit) * 100;
    function secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
        return ('0' + h).slice(-2) + ":" + ('0' + m).slice(-2) + ":" + ('0' + s).slice(-2);
    }
    // console.log(progress, elapsedTime,)
    const handlePause = () => {

        if (isOpenModal) {
            onCloseModal();
        }
        if (!isOpenModal) {
            onOpenModal();
        }
        if (audioElement) {
            if (isPlaying) {
                if (audioElement) {
                    audioElement.pause();
                }
                setIsPaused(true);
                setIsPlaying(false);
            } else {
                if (audioElement) {
                    audioElement.play();
                }
                setIsPaused(false);
                setIsPlaying(true);
            }
        }
        else {
            setIsPaused(!isPaused);
        }
    };

    const pauseModal = () => {
        return (

            <Modal isOpen={isOpenModal} onClose={handlePause} isCentered >
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalCloseButton /> */}
                    <ModalBody p={10} marginY="auto">
                        <Center>
                            <Icon h={10} boxSize={5} as={AiOutlineCoffee} />
                        </Center>
                        <Center>
                            <Text mt={'15%'} fontSize={'xl'}>
                                Take a break.
                            </Text>
                        </Center>




                    </ModalBody>
                    <ModalFooter>
                        <Button mt={4} ml={4} onClick={handlePause}>Finish break</Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>

        );
    }
    const handleExit = () => {
        if (isRecording) {
            handleRecord();
        };
        if (audioElement) {
            handleAudioPlay(listeningAudioPath ? listeningAudioPath : toeflData.listeningAudio, false);
        }
        history.push(`/page`);
    }

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    return (
        <>
            <VStack
                spacing={1}
                align='stretch'
            >
                {/* header */}
                <HStack
                    width="full"
                    h={["fit-content"]}
                    // overflow="hidden"
                    color="white"
                    zIndex="99"
                    spacing={["5%", "7%", "10%"]}
                    sx={{
                        backgroundImage: 'linear-gradient(to right, #3f49a3, #832a41)',
                        WebkitBackgroundImage: 'linear-gradient(left, #3f49a3, #832a41)',
                        MozBackgroundImage: 'linear-gradient(left, #3f49a3, #832a41)',
                        OBackgroundImage: 'linear-gradient(left, #3f49a3, #832a41)',
                    }
                    }
                    overflowX={'auto'}
                    display={{sm:"flex",lg:"block"}}
                    flexDirection={{sm:"column"}}
                    paddingLeft={{lg:"4px"}}
                    paddingRight={{lg:"4px"}}
                    
                >
                    <HStack>
                        <Box onClick={() => handleExit()}>
                            <Image height={["40px", "50px", "58px"]} src={logoPng} />
                        </Box>
                        <Spacer />

                        <Box maxW={["50%", "40%", "30%"]}>
                            <Text as="h2" textOverflow="ellipsis" whiteSpace="nowrap"
                                overflow="hidden" fontSize={["lg", "xl", "2xl"]}>{toeflData.tpoNumber ? `TPO${toeflData.tpoNumber} ${t(toeflData.genType)}` : `${toeflData.bundleName} ${t(toeflData.genType)}`}</Text>
                        </Box>
                        <Spacer />

                        <Text display={showTimer && !((isAnswering && isSpeaking) || isPreparation || isInstruction) ? 'block' : 'none'} maxW="20%" fontSize={["sm", "md", "lg"]}>{metaInfo} time: <b>{secondsToHms(timeLeft)}</b></Text>
                    </HStack>

                    <VStack spacing="1" minW="80%" display="flex" flexDirection="row" justifyContent={{ sm: 'center',lg:"right",md:"right"}}>
                        <Flex flexDirection="row" alignItems="center" mr={5} visibility={showVolume ? 'visible' : 'hidden'}>
                            <Text htmlFor="vol" mr={2} mt={4}>Volume:</Text>
                            <Slider
                                aria-label="slider-ex-1"
                                value={volume}
                                min={0}
                                max={1}
                                step={0.01}
                                onChange={handleVolumeChange}
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                                width={["80px", "100px", "120px"]}
                            >
                                <SliderTrack>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <Tooltip
                                    hasArrow
                                    bg='blue.500'
                                    color='white'
                                    placement='top'
                                    isOpen={showTooltip}
                                    label={`${Math.floor(volume * 100)}%`}
                                >
                                    <SliderThumb />
                                </Tooltip>
                            </Slider>
                        </Flex>
                        <Flex gap={4}>
                            <Button
                                onClick={handleContinueClick}
                                variant="unstyled"
                                position="relative"
                                // display="inline-block"
                                w={["40px", "50px", "60px"]}
                                h={["40px", "50px", "60px"]}
                                display={showContinueButton ? "inline-block" : "none"}
                            >
                                <Image
                                    src={TPONextPng}
                                    alt="TPO Next"
                                    width="100%"
                                    height="75%"
                                    objectFit="cover"
                                />
                                {/* <Box
                                position="absolute"
                                top="50%"
                                left="50%"
                                transform="translate(-50%, -50%)"
                            >
                                <Text
                                    color="white"
                                    fontSize={['xs', 'xs', 'sm']}
                                >
                                    {t("continue")}
                                </Text>
                            </Box> */}
                            </Button>
                            <Button
                                onClick={handleShowVolume}
                                variant="unstyled"
                                position="relative"
                                // display="inline-block"
                                w={["40px", "50px", "60px"]}
                                h={["40px", "50px", "60px"]}
                            // display={showContinueButton ? "inline-block" : "none"}
                            >
                                <Image
                                    src={TPOVolumePng}
                                    alt="TPO Volume"
                                    width="100%"
                                    height="75%"
                                    objectFit="cover"
                                />
                            </Button>
                            <Button position="relative" mt={3}
                                // display="inline-block"
                                w={["40px", "50px", "60px"]}
                                h={["20px", "25px", "30px"]}
                                onClick={handlePause} size='sm' color={'teal'} isDisabled={isInstruction || isRecording}>
                                {isPaused ? "Resume" : "Pause"}
                            </Button>
                        </Flex>
                    </VStack>
                </HStack>
                {/* body */}
                <VStack spacing={5} align="stretch">
                    {section == 'writing' && task_name == "task1" &&
                        <Box bg='#cbcfdf' border='1px' w='100%' p={4}><Text><Text as='b'>Directions: </Text>You have 20 minutes to plan and write your response. Your response will be judged on the basis of the quality of your writing and on how well you response presents the points in the leture and their relationship to the reading passage. Typically, an effective response will be 150 to 225 words.</Text></Box>}
                    {section == 'writing' && task_name == "task2" &&
                        <Box bg='#cbcfdf' border='1px' w='100%' p={4}><Text><Text as='b'>Directions: </Text>Read the question below. You have 30 minutes to plan, write, and revise your essay. Typically, an effective response will contain a mininum of 300 words.</Text></Box>}
                    {showQuestion && !(section == "writing" && task_name == "task2") &&
                        <Box flex="1" p={5} shadow="md" borderWidth="1px" borderRadius="md">
                            <Text fontSize="lg" fontWeight="semibold">Question</Text>
                            <Text mt={2}>{toeflData.question}</Text>
                        </Box>
                    }
                    {showListening && (
                        <div>
                            <Box width="100%">
                                <Progress value={progress} size="sm" colorScheme="blue" />
                            </Box>
                            <Center display="flex" flexDirection="row">
                                <VStack>
                                    <Spacer />
                                    <Image src={TPOListeningJPEG} />
                                    <Spacer />
                                    <HStack justifyContent="center">
                                        <Button onClick={onOpen}>{t("see_listening_text")}</Button>
                                        {isIOS && !showListening && <Button onClick={recall}>Play Audio</Button>}
                                    </HStack>

                                </VStack>

                            </Center>

                            <Modal isOpen={isOpen} onClose={onClose}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>{t("listening_text")}</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Text mt={2}>{toeflData.listeningText}</Text>
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                        </div>
                    )}
                    <Flex>
                        {showQuestion && section == "writing" && task_name == "task2" &&
                            <Box flex="1" p={5} shadow="md" borderWidth="1px" borderRadius="md">

                                <Text fontSize="lg" fontWeight="semibold">Question</Text>
                                <Text mt={2}>{toeflData.question}</Text>

                            </Box>
                        }
                        {isDiscussion &&
                            <Box flex="1" p={5} shadow="md" borderWidth="1px" borderRadius="md" overflowX={'auto'} minWidth={'50%'}>
                                <Text fontSize="lg" fontWeight="semibold">Discussion Content</Text>
                                <ConversationTable dataType={'tpo'} />
                            </Box>}

                        {showReading &&
                            <Box flex="1" p={5} shadow="md" borderWidth="1px" borderRadius="md">
                                <Text fontSize="lg" fontWeight="semibold">Reading Text</Text>
                                <Text mt={2}>{toeflData.readingText}</Text>
                            </Box>
                        }
                        {allowInput &&
                            <Box flex="1" borderWidth="1px">
                                <VStack alignItems="start">
                                    <Flex>
                                        <Box>
                                            <Button borderWidth="1px" onClick={handleCut}>Cut</Button>
                                        </Box>
                                        <Box>
                                            <Button borderWidth="1px" onClick={handleCopy}>Copy</Button>
                                        </Box>
                                        <Box>
                                            <Button borderWidth="1px" onClick={handlePaste}>Paste</Button>
                                        </Box>
                                        <Box>
                                            <Text mt={2}>Word Count: {wordCount}</Text>
                                        </Box>
                                    </Flex>
                                    <Textarea onPaste={handlePaste} ref={textRef} value={toeflData.answer} onChange={handleInputChange}
                                        placeholder="Enter your answer here..." size="sm" height="1000px" spellcheck="false"
                                    />
                                </VStack>
                            </Box>
                        }
                    </Flex>

                    <Center>
                        {((isAnswering || isPreparation || isInstruction) && section !== 'writing') &&
                            <Flex mt={'50px'} flexDirection={'column'} alignItems={'center'} gap={5} width={'500px'}>
                                <Flex py={5} borderRadius={10} bg={'gray.100'} borderWidth='1px' p={5} gap={3} flexDirection={'column'}>
                                    <Flex justifyContent="center" alignItems="center" flexDirection={'column'} gap={3}>
                                        <Text>
                                            Preparation time: {preparationTime} seconds
                                        </Text>
                                        <Text>
                                            Answering time: {answeringTime} seconds
                                        </Text>
                                    </Flex>
                                    {/* the timer section  */}
                                    <Flex justifyContent="center" alignItems="center" width="full" display={!isInstruction ? 'block' : 'none'}>
                                        <Flex width={'100%'} flexDirection={'column'} borderWidth='2px' borderColor={'black'}>
                                            <Flex bg={'black'} width="100%" justifyContent="center" alignItems="center">
                                                <Text fontSize={'lg'} color="white">
                                                    {metaInfo}
                                                </Text>
                                            </Flex>
                                            <Flex bg={'white'} width="100%" justifyContent="center" alignItems="center">
                                                <Text color="black">
                                                    {secondsToHms(timeLeft)}
                                                </Text>
                                            </Flex>

                                        </Flex>
                                    </Flex>
                                </Flex>

                                <Flex width="100%" p={2} display={showTimer ? "block" : "none"}>
                                    <Box width={'100%'} mt={3} borderWidth='5px' borderColor={'gray.400'}>
                                        <Progress value={progress} bg="black" size="md" colorScheme={'teal'} />
                                    </Box>
                                </Flex>
                                <Flex>
                                    <Button display={!showContinueButton ? "inline-block" : "none"} onClick={handleContinueClick} colorScheme={'blue'}>
                                        Click on to start
                                    </Button>
                                </Flex>
                            </Flex>
                        }
                    </Center>

                    {recording && <Button fontSize={'sm'}
                        isLoading={isRecording}
                        loadingText={t("recording_in_progress")}
                        spinnerPlacement='start'
                        onClick={handleRecord}
                    >{t("start_recording")}</Button>}
                </VStack>
            </VStack >
            {pauseModal()}
        </>
    );
};

export default withRouter(TPOPracticePage);
