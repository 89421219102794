// Chakra imports
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Link,
  Switch, Table, Tbody,
  Text, Th, Thead, Tr,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, {useEffect, useState} from "react";
import {HistoryRightPanel} from "../../components/HistoryRightPanel";
import {QuickLinksPanel} from "../../components/QuickLinksPanel";
import {ViewResultPanel} from "../../components/ViewResultPanel";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectGid, selectGrading, updateGid} from "../../redux/shareSlice";

function HistoryView() {
  const { colorMode } = useColorMode();

  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const iconColor = useColorModeValue("blue.500", "white");
  const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "navy.800");
  const borderProfileColor = useColorModeValue("white", "transparent");
  const emailColor = useColorModeValue("gray.400", "gray.300");
  const { gid } = useParams();

  const gidRedux = useSelector(selectGid);
  const dispatch = useDispatch();

  if (gid && gid !== gidRedux) {
    dispatch(updateGid(gid))
  }


  return (
      <Flex flexDirection='column' pt="80px">
        <Grid
            templateColumns={{sm: "1fr", lg: "4fr 1fr"}}
            templateRows={{lg: "repeat(2, auto)"}}
            gap='20px'>
          <Card overflowX={{sm: "scroll", xl: "hidden"}} p={{base: "0px", xs: "22px"}} py="22px">
            {
              gid ? <ViewResultPanel gid={gid}></ViewResultPanel> : <HistoryRightPanel></HistoryRightPanel>
            }
          </Card>
          <QuickLinksPanel></QuickLinksPanel>

        </Grid>
      </Flex>
  );
}

export default HistoryView;
