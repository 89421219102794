import React from 'react';
import {
    Button,
    Center,
    Flex,
    Text,
    Box,
    CircularProgress,
    CircularProgressLabel,
} from '@chakra-ui/react';
import Card from './Card/Card';
import CardBody from './Card/CardBody';
import { selectToeflData } from 'redux/toeflSlice';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useGetGradingQuery } from 'services/cmApi';

export default function EssayScore(props) {

    const [result, setResult] = useState([]);

    const grading_result = useGetGradingQuery({ gid: props.gid });

    useEffect(() => {
        if (grading_result?.data?.data) {
            try {
                // Split the JSON string into separate JSON objects
                const jsonStrings = grading_result.data.data.result.split('\n').filter(Boolean);
                const parsedData = jsonStrings.map(jsonStr => JSON.parse(jsonStr));

                setResult(parsedData);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }
    }, [grading_result]);


    return (
        <Box maxW="full" bgColor="white" padding="3rem" roundedBottom="none" borderTop="2xl" display="flex" flexDirection="column" alignItems="center" gap="12px">
            <Card borderTop="4px" borderTopColor="green.400" width="25rem">
                <Flex direction="column" justify="center" alignItems="center">
                    <Text textColor="white" fontSize="5rem" fontWeight="bold" color="skyblue">
                        {result[0]?.total_score}
                    </Text>
                    <Text textColor="blue">Overall Brand Score</Text>
                </Flex>
            </Card>

            <Flex
                direction="row"
                rowGap="12px"
                justify="center"
                marginTop="20px"
                maxW="full"
            >
                <Center display="flex" flexDirection="row" columnGap="20px">
                    <Card borderTop="4px" borderTopColor="blue.300" maxW="17rem" height="30rem">
                        <Flex direction="row" justify="space-between" alignItems="center">
                            <Text fontSize="18px" fontWeight="bold">
                                Delivery Description
                            </Text>
                            <CircularProgress value={result[0]?.delivery} color="blue.400" size="80px">
                                <CircularProgressLabel
                                    color="blue.500"
                                    fontWeight="bold"
                                    fontSize="3xl"
                                >
                                    {result[0]?.delivery}
                                </CircularProgressLabel>
                            </CircularProgress>
                        </Flex>
                        <Box >
                            <Text>{result[0]?.delivery_description}</Text>
                        </Box>
                    </Card>
                    <Card borderTop="4px" borderTopColor="violet.300" maxW="17rem" height="30rem">
                        <Flex direction="row" justify="space-between" alignItems="center">
                            <Text fontSize="18px" fontWeight="bold">
                                Language Use
                            </Text>
                            <CircularProgress value={result[0]?.language_use} color="black" size="80px">
                                <CircularProgressLabel color="black" fontWeight="bold" fontSize="3xl">
                                    {result[0]?.language_use}
                                </CircularProgressLabel>
                            </CircularProgress>
                        </Flex>
                        <Box>
                            <Text>
                                {result[0]?.language_use_description}
                            </Text>
                        </Box>
                    </Card>

                    {/* Organization Card */}
                    <Card borderTop="4px" borderTopColor="green.400" maxW="17rem" height="30rem">
                        <Flex direction="row" justify="space-between" alignItems="center">
                            <Text fontSize="18px" fontWeight="bold">
                                Topic Development
                            </Text>
                            <CircularProgress value={result[0]?.topic_development} color="green.400" size="80px">
                                <CircularProgressLabel color="green.500" fontWeight="bold" fontSize="3xl">
                                    {result[0]?.topic_development}
                                </CircularProgressLabel>
                            </CircularProgress>
                        </Flex>
                        <Box >
                            <Text>{result[0]?.topic_development_description}</Text>
                        </Box>
                    </Card>

                    {/* Language Use Card */}
                    {/* <Card borderTop="4px" borderTopColor="yellow.500" width="full">
                        <Flex direction="row" justify="space-between" alignItems="center">
                            <Text fontSize="18px" fontWeight="bold">
                                Language Use
                            </Text>
                            <CircularProgress value={60} color="yellow.400" size="80px">
                                <CircularProgressLabel color="yellow.500" fontWeight="bold" fontSize="3xl">
                                    {message1?.language_use}
                                </CircularProgressLabel>
                            </CircularProgress>
                        </Flex>
                        <Box maxW="full">
                            <Text>
                                Your usage of complex sentence forms is good, but there are some
                                punctuation errors and grammatical errors in your work. For instance,
                                the phrase 'Not only did a person have momentum on the way of solving a
                                daily basic work but, he also is able to save time to learn new things
                                again' could be correctly rephrased as 'Not only does a person gain
                                momentum in solving daily tasks, but they also save time by not having
                                to learn new things.'
                            </Text>
                        </Box>
                    </Card> */}

                </Center>
            </Flex>
        </Box>
    );
}