import React, {useEffect, useState} from "react";

import {Box, Checkbox, CheckboxGroup, Radio, RadioGroup, Text, VStack} from '@chakra-ui/react'
import {ToeflIbtContext} from "./ToeflIbtContext";

export const ToeflReadingQuestionAnswerSetting = (props) => {
    var {subQuestionOptions, answerExplain, questionText, readTexts, subIdx = 0, ...other} = props;
    const {
        answerContent,
        setAnswerContent
    } = React.useContext(ToeflIbtContext);
    const [spanContent, setSpanContent] = useState('');

    useEffect(() => {
        setSpanContent(extractSpanContent(readTexts))
    }, [readTexts]);

    const renderOptions = () => {
        const handleOptionChange = (value) => {
            setAnswerContent(prevAnswerContent => ({
                ...prevAnswerContent,
                reading: {
                    ...prevAnswerContent.reading,
                    [subIdx]: value
                }
            }));
            // setSelectedValues(value);
        };

        if (answerExplain?.length > 1) {
            return (
                <CheckboxGroup value={answerContent?.reading?.[subIdx] || []} onChange={handleOptionChange}
                               isDisabled={subIdx === 0}>
                    <VStack align="start" spacing={2}>
                        {subQuestionOptions && Object.entries(subQuestionOptions).map(([key, option]) => (
                            <Checkbox key={subIdx + '_' + key} value={key}>
                                {option}
                            </Checkbox>
                        ))}
                    </VStack>
                </CheckboxGroup>
            );
        } else {
            return (
                <RadioGroup value={answerContent?.reading?.[subIdx] || ''} onChange={handleOptionChange}
                            isDisabled={subIdx === 0}>
                    <VStack align="start" spacing={2}>
                        {subQuestionOptions && Object.entries(subQuestionOptions).map(([key, option]) => (
                            <Radio key={subIdx + '_' + key} value={key}>{option}</Radio>
                        ))}
                    </VStack>
                </RadioGroup>
            );
        }
    };

    function extractSpanContent(text) {
        const regex = /<span[^>]*>(.*?)<\/span>/g;
        let matches = [];
        let match;
        while ((match = regex.exec(text)) !== null) {
            matches.push(match[1]);
        }
        matches = matches.join(' ');
        // console.log("matches", matches)
        return matches || '';
    }


    return (
        <Box w={'100%'} p={4}>
            <>
                {questionText && questionText[0]?.includes('【▇】') ? (
                    questionText?.map((item, idx) => {
                        if (item.includes('【▇】')) {
                            const textSplit = item.split('【▇】');
                            return (
                                // <Text
                                //     paddingTop={"8px"}
                                //     paddingBottom={"8px"}
                                // >
                                <span key={idx}>
                                    {textSplit.map((item, splitIdx) => {
                                        if (splitIdx === 0) {
                                            return <span key={splitIdx}>{item}</span>
                                        }
                                        return <>
                                                <span
                                                    style={{
                                                        display: "inline-block",
                                                        width: "20px",
                                                        height: "20px",
                                                        backgroundColor: "#066A6E",
                                                        verticalAlign: "middle",
                                                        borderRadius: "2px",
                                                        margin: "-8px 0 0",
                                                    }}>
                                                    </span>
                                            <span>{item}</span>
                                        </>

                                    })}
                                    </span>
                                // </Text>
                            );
                        } else if (item.includes('?')) {
                            return (
                                <span key={idx}>{item} </span>
                            );
                        } else if (item.startsWith("A.") || item.startsWith("B.") || item.startsWith("C.") ||
                            item.startsWith("D.") || item.startsWith("E.") || item.startsWith("F.")) {
                            return null; // 返回 null 而不是 undefined
                        } else {
                            return (
                                <Text
                                    key={idx}
                                    fontWeight="bold"
                                    p={8}
                                >
                                    {item}
                                </Text>
                            );
                        }

                    })
                ) : (
                    questionText?.map((item, idx) => {
                        if (idx === 0) {
                            return (
                                <Text
                                    key={idx}
                                    fontWeight="bold"
                                    paddingTop="8px"
                                    paddingBottom="8px"
                                    dangerouslySetInnerHTML={{
                                        __html: spanContent === ''
                                            ? item
                                            : item.replace(
                                                new RegExp(spanContent, 'g'),
                                                `<span style="background-color: teal; color: white; padding: 4px; border-radius: 4px; margin-left: 2px; margin-right: 2px;">${spanContent}</span>`
                                            )
                                    }}
                                />

                            );
                        }
                    })
                )}
            </>
            {(answerExplain && answerExplain.length > 1) && (
                <Box
                    bg="teal.100"
                    borderRadius="xl"
                    padding="2"
                    pl="4"
                    boxShadow="md"
                    textAlign="left"
                    fontSize={"md"}
                >
                    Select {answerExplain.length} answers.
                </Box>
            )}
            {renderOptions()}
        </Box>


    )
}