import * as React from 'react'
import { useTranslation } from 'react-i18next';

import {useHistory} from "react-router-dom";
import AuthService from "./services/auth.service";
import {useNonUserReportMutation, useReportMutation} from "./services/cmApi";
import {useEffect} from "react";

export default function Root(props) {
    const currentUser = AuthService.getCurrentUser();
    let history = useHistory();
    const [reportRequest, reportRequestError] = useReportMutation();
    const [nonUserReportRequest, nonUserReportRequestError] = useNonUserReportMutation();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (currentUser) {
            reportRequest({
                "type": "visit_home",
                "language": i18n.language
            }).then(function (response) {});
        } else {
            nonUserReportRequest({
                "type": "visit_home",
                "language": i18n.language
            }).then(function (response) {});
        }
    }, []);


    if (currentUser) {
        history.push('/toefl/reading')
        return null;
    } else {
        history.push('/login')
        return null;
    }

};
