import { useState } from 'react';
import React from 'react';
import {
    Box,
    Button, Center,
    Flex,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Text,
    useBoolean,
} from '@chakra-ui/react';
import { BsFillPlayCircleFill, BsFillPauseCircleFill } from 'react-icons/bs';
import IconBox from "./Icons/IconBox";

function LLAudioPlayer({ src }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = React.useRef(null);
    const [duration, setDuration] = React.useState(0);
    const [currentTime, setCurrentTime] = React.useState(0);

    const handlePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleLoadedMetadata = () => {
        if (audioRef.current) {
            setDuration(audioRef.current.duration);
        }
    };

    const handleTimeUpdate = () => {
        if (audioRef.current) {
            setCurrentTime(audioRef.current.currentTime);
        }
    };

    const handleChangeSlider = (value) => {
        if (audioRef.current) {
            audioRef.current.currentTime = value;
            setCurrentTime(value);
        }
    };

    const handleAudioEnd = () => {
        setIsPlaying(false);
    }

    return (
        <Flex p={2} direction={'row'} align="center" spacing={4} >
            <audio
                ref={audioRef}
                src={src}
                onLoadedMetadata={handleLoadedMetadata}
                onTimeUpdate={handleTimeUpdate}
                onEnded={handleAudioEnd}
            />
            <Box
                color="blue.500"
                width="35px"
                onClick={handlePlayPause}
                _hover={{
                    color: "blue.300",
                    cursor: "pointer"
                }}
            >
                {/*<BsFillPlayCircleFill*/}
                {/*    color="inherit"*/}
                {/*    size='lg'*/}
                {/*/>*/}
                {isPlaying ?
                    <BsFillPauseCircleFill size="lg" color='inherit'/> :
                    <BsFillPlayCircleFill size="lg" color='inherit'/>}
            </Box>
        </Flex>
    );
}

export default LLAudioPlayer;
