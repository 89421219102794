import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query'

import toeflReducer from '../redux/toeflSlice';
import ieltsReducer from 'redux/ieltsSlice';
import adminReducer from '../redux/adminSlice';
import shareReducer from '../redux/shareSlice';
import generalReducer from '../redux/generalSlice';
import {cmApi} from "../services/cmApi";


export const store = configureStore({
    reducer: {
        toefl: toeflReducer,
        ielts: ieltsReducer,
        admin: adminReducer,
        share: shareReducer,
        general: generalReducer,
        [cmApi.reducerPath]: cmApi.reducer
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredPaths: [
                    'toefl.toeflData.abortController',
                    'ielts.ieltsData.abortController'
                ],
            },
        }).concat(cmApi.middleware),
});

setupListeners(store.dispatch);
