import React, { useEffect } from 'react';
import { Route, useHistory, Redirect } from 'react-router-dom';
import AuthService from "../services/auth.service";

function isLoggedIn(currentUser) {
  if (currentUser === null) {
    return false;
  }

  // Check if the expiration_time key exists in the userInfo
  if (!('expiration_time' in currentUser)) {
    console.error('expiration_time not found in user info');
    return false;
  }

  const currentTime = new Date().getTime();
  if (currentTime > currentUser.expiration_time) {
    console.error('User info has expired');
    return false;
  }

  return true;
}

function AuthenticatedRoute({ component: Component, ...rest }) {
  let history = useHistory();
  const currentUser = AuthService.getCurrentUser();

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn(currentUser) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{
            pathname: '/login',
            state: { from: props.location }
          }} />
        )
      }
    />
  );
}

export default AuthenticatedRoute;
