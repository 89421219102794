import React, {useEffect, useState} from 'react';
// import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Flex,
    Link,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Tab,
    TabList,
    Tabs,
    Text,
    useToast
} from '@chakra-ui/react';
import {FaAngleLeft, FaAngleRight, FaEye, FaEyeSlash, FaVolumeHigh} from "react-icons/fa6";
import {useHistory} from "react-router-dom";
import {getRouteInfo, ToeflIbtContext} from "./ToeflIbtContext";
import FinishWarning from "./ToeflIbtFinishWarning"
import axios from 'axios';
import authHeader from "../../services/auth-header";

const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;

function ToeflIbtMenu({isCountdownPaused, setIsCountdownPaused}) {
    const history = useHistory();
    const {
        questionContent,
        setQuestionContent,
        volume,
        setVolume,
        answerContent,
        setAnswerContent
    } = React.useContext(ToeflIbtContext);
    const [partIdx, setPartIdx] = useState(1);
    const [subIdx, setSubIdx] = useState(0);
    const totalNum = questionContent?.total_question_num || 0;
    // qid为all时则是模考，练习时则为单个part
    const {parentPath, tpo_num, section, qid, childPath, otherParams, answerJson} = getRouteInfo(location.pathname);
    const [totalTime, setTotalTime] = useState(540);
    const [countdown, setCountdown] = useState(0); // 初始化倒计时为60秒
    const [showCountdown, setShowCountdown] = useState(true); // 控制倒计时显示的状态
    const [isOpen, setIsOpen] = useState(false); // 添加状态管理
    const [displayReadingTabs, setDisplayReadingTabs] = useState(false); // 添加状态管理
    const toast = useToast();

    const onClose = () => setIsOpen(false); // 关闭模态框的函数
    const onContinue = () => {
        history.push(`${parentPath}/answer`);
        onClose();
    };


    useEffect(() => {
        if (childPath === "answer") {
            if (answerJson) {
                setAnswerContent(prevAnswerContent => ({
                    ...prevAnswerContent,
                    [section]: answerJson
                }));
            } else {
                let headers = authHeader();
                if (!answerContent[section] || Object.keys(answerContent[section]).length === 0) {
                    return;
                }
                axios.post(API_URL + '/api/save_answer', {
                        qid: qid !== "all" ? qid : questionContent?.tasks[0]?.qid,
                        answer_json: handleAnswerContent(), // 假设 answerContent 是一个对象，section 是键
                        practice_type: qid === "all" ? "mock_test" : "practice",
                        question_id: tpo_num,
                        question_set: tpo_num > 75 ? "questionBank" : "tpo",
                        section: section,
                        test: 'toefl'
                    }, {headers: headers}
                ).then((response) => {
                    toast({
                        title: "Answer saved successfully",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                    });
                }).catch((error) => {
                    toast({
                        title: "Failed to save answer",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                    });
                });
            }


        }
    }, [childPath]);

    function handleAnswerContent() {
        const answerContentV = {}
        questionContent?.tasks.map(task => {
            const qid = task?.qid
            const taskAnswerContent = {}
            task?.questions.map((question, index) => {
                const subQuestionIdx = question?.sub_question_idx
                taskAnswerContent[index + 1] = answerContent[section][subQuestionIdx] || '';
            })
            answerContentV[qid] = taskAnswerContent
        })
        return answerContentV
    }


    const refreshTotalTime = () => {
        let totalTimeV = totalTime;
        if (qid === "all") {
            totalTimeV = 36 * 60 // 36分钟
        } else if (section === "listening") {
            totalTimeV = 7 * 60
        } else if (section === "reading") {
            totalTimeV = 18 * 60
        }
        setTotalTime(totalTimeV)
    };

    useEffect(() => {
        fetchQuestionContent();
    }, [parentPath]);

    useEffect(() => {
        if (!otherParams) {
            return
        } else if (otherParams.length === 1) {
            setPartIdx(Number(otherParams[0]))
            setSubIdx(0)
        } else if (otherParams.length === 2) {
            setPartIdx(Number(otherParams[0]))
            setSubIdx(Number(otherParams[1]))
        }
        checkDisplayReadingTabs(partIdx, subIdx);
    }, [otherParams]);

    useEffect(() => {
        setCountdown(totalTime);
    }, [parentPath, tpo_num, totalTime]);


    useEffect(() => {
        let timer;
        if (!isCountdownPaused) {
            timer = setInterval(() => {
                setCountdown(prev => {
                    if (prev > 0) {
                        return prev - 1;
                    } else if (qid === "all") {
                        onContinue();
                    } else {
                        return 0;
                    }
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [isCountdownPaused]);

    function getNextUrl() {
        if (section === "listening") {
            setIsCountdownPaused(true)
        }
        if (childPath === "directions") {
            if (section === "listening") {

                return `${parentPath}/audio/1`
            } else if (section === "reading") {
                return `${parentPath}/text/1`
            }
        } else if (childPath === "audio" || (childPath === "text" && subIdx === 0)) {
            const nextSubIdx = questionContent?.tasks[partIdx - 1].questions[0].sub_question_idx
            return `${parentPath}/question/${partIdx}/${nextSubIdx}`
        } else if (childPath === "question" || childPath === "text") { // 如果当前是question，则判断是否是最后一题
            const nextPartIdx = partIdx + 1
            const nextSubIdx = subIdx + 1
            if (nextSubIdx > totalNum) {
                if (qid === "all" && countdown > 0) {
                    setIsOpen(true)
                    return
                } else {
                    return `${parentPath}/answer`
                }
            }
            const questions = questionContent?.tasks[partIdx - 1].questions
            let curPartEndIdx = questions[questions.length - 1].sub_question_idx
            if (Number(subIdx) === Number(curPartEndIdx)) {
                if (section === "listening") {
                    return `${parentPath}/audio/${nextPartIdx}`
                } else if (section === "reading") {
                    return `${parentPath}/text/${nextPartIdx}`
                }
            } else if (isRePlay(partIdx, nextSubIdx)) {
                console.log("url", `${parentPath}/re_play/${partIdx}/${nextSubIdx}`)
                return `${parentPath}/re_play/${partIdx}/${nextSubIdx}`
            } else {
                return `${parentPath}/question/${partIdx}/${nextSubIdx}`
            }
        } else if (childPath === "re_play") {
            return location.pathname.replace("re_play", "question");
        } else if (childPath === "answer") {
            return `/toefl/${section}`
        }
    }

    function isRePlay(partIdx, nextSubIdx) {
        const taskObj = questionContent?.tasks[partIdx - 1];
        if (taskObj && taskObj?.questions) {
            for (const questionObj of taskObj?.questions) {
                if (questionObj.sub_question_idx.toString() === nextSubIdx.toString()) {
                    if (questionObj.sub_question_audio && questionObj.sub_question_audio.replay_url) {
                        return true;
                    }

                }
            }
        }
        return false
    }

    function checkDisplayReadingTabs(partIdx, SubIdx) {
        const taskObj = questionContent?.tasks[partIdx - 1];
        if (taskObj && taskObj?.questions) {
            for (const questionObj of taskObj?.questions) {
                if (questionObj.sub_question_idx.toString() === SubIdx?.toString()) {
                    const isDisplay = (questionObj.sub_question_texts &&
                        questionObj.sub_question_texts[0].includes("Directions")
                    )
                    setDisplayReadingTabs(isDisplay)
                }
            }
        }
    }


    const formatTime = (seconds) => {
        seconds = seconds < 0 ? 0 : seconds
        const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
        const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
        const secs = String(seconds % 60).padStart(2, '0');
        return `${hours}:${minutes}:${secs}`;
    };


    const fetchQuestionContent = async () => {
        try {
            let param;
            if (qid === "all") {
                param = {
                    test: "toefl",
                    section: section,
                    tpo_number: tpo_num
                };
            } else {
                param = {qid: qid};
            }
            axios.post(API_URL + '/api/query_mock_test_question_bank', param)
                .then(response => {
                    if (response.status === 200) {
                        // console.log("response.data.data", response.data.data)
                        setQuestionContent(response.data.data)
                    }
                }).catch(error => {
                console.error('There was an error!', error);
            });
        } catch (error) {
            console.error('Error occurred while obtaining the content of the problem:', error);
        }
    };

    const handleClick = () => {
        history.push(`/toefl/${section}`)
    };

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <Flex direction={"column"}>
            <Flex as="nav" align="center" justify="space-between" wrap="wrap" bg="teal.700" color="white">
                <Flex align="center" ml={5} mr={5}>
                    {/*<Text fontSize="4xl" fontWeight="bold">*/}
                    {/*    lingoleap*/}
                    {/*</Text>*/}
                    <div onClick={handleClick}>
                        <Text fontSize="4xl" fontWeight="bold">
                            lingoleap
                        </Text>
                    </div>
                </Flex>

                <Flex>
                    <Popover>
                        <PopoverTrigger>
                            <Button
                                colorScheme="teal"
                                variant="outline"
                                mr={3}
                                color="white"
                                borderColor="white"
                                _hover={{borderColor: "white"}}
                                rightIcon={<FaVolumeHigh fontSize={20}/>}
                            >
                                Volume
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent bg="white" borderColor="gray.200">
                            <PopoverBody>
                                <Box p={1}>
                                    <Flex align="flex-end" justify="space-between" mb={2}>
                                        {[...Array(15)].map((_, i) => (
                                            <Box
                                                key={i}
                                                w={2}
                                                h={`${(i + 8) * 3}px`}
                                                bg={i < volume * 15 ? "teal.500" : "gray.300"}
                                                mr={1}
                                                borderRadius="6px"
                                            />
                                        ))}
                                    </Flex>
                                    <Slider
                                        aria-label="volume-slider"
                                        value={volume * 100}
                                        min={0}
                                        max={100}
                                        onChange={(val) => {
                                            setVolume(val / 100);
                                        }}
                                    >
                                        <SliderTrack bg="gray.300">
                                            <SliderFilledTrack bg="teal.500"/>
                                        </SliderTrack>
                                        <SliderThumb boxSize={4} bg="teal.500"/>
                                    </Slider>
                                </Box>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                    <Button colorScheme="teal"
                            variant="outline"
                            mr={3}
                            color="white"
                            borderColor="white"
                            _hover={{borderColor: "white"}}
                            leftIcon={<FaAngleLeft fontSize={20}/>}
                            onClick={() => {
                                history.goBack();
                            }}>
                        Back
                    </Button>
                    <Button
                        bg="white"
                        variant="outline"
                        mr={3}
                        color="teal"
                        rightIcon={<FaAngleRight fontSize={20}/>}
                        onClick={() => {
                            if (childPath === "directions") {
                                refreshTotalTime();
                            }
                            const nextUrl = getNextUrl();
                            history.push(nextUrl);
                        }}
                    >
                        {childPath === "directions" ? "Continue" : "Next"}
                    </Button>

                </Flex>
            </Flex>
            {(childPath !== "answer") && (
                <Flex direction={"row"} justify="space-between" bg="gray.100">
                    <Flex direction={"row"} align="center" fontSize={"xl"}>
                        {(childPath !== "directions" && childPath !== "answer") && (
                            <Text p={2} ml={8}> {capitalizeFirstLetter(section)} </Text>
                        )}
                        {(["question", "text"].includes(childPath) && subIdx !== 0) && (
                            <Flex direction={"row"}>
                                <Text p={2}> | </Text>
                                <Text p={2}> Question {subIdx} of {totalNum} </Text>
                            </Flex>
                        )}
                        {(childPath !== "directions") && (childPath === "audio" || subIdx === 0) && (
                            <Flex direction={"row"}>
                                <Text p={2}> | </Text>
                                <Text p={2}> Passage {partIdx} of {questionContent?.tasks.length} </Text>
                            </Flex>
                        )}
                    </Flex>
                    {displayReadingTabs && (
                        <Tabs>
                            <TabList>
                                <Tab onClick={() => {
                                    const url = location.pathname.replace("text", "question");
                                    history.replace(url);
                                }}>View Question</Tab>
                                <Tab onClick={() => {
                                    const url = location.pathname.replace("question", "text");
                                    history.replace(url);
                                }}>View Passage</Tab>
                            </TabList>
                        </Tabs>
                    )}
                    <Flex direction={"row"} align="center" fontSize={"xl"}>
                        {(childPath !== "directions" && childPath !== "answer") && (
                            <Flex align="center" ml={8}>
                                {showCountdown && <Text fontSize="md">{formatTime(countdown)}</Text>}
                                <Button onClick={() => setShowCountdown(prev => !prev)} ml={2}
                                        color="teal" leftIcon={showCountdown ? <FaEyeSlash/> : <FaEye/>}
                                        as={Link} variant="Link"
                                        css={{
                                            color: 'teal',
                                            ':hover': {
                                                color: 'teal', // 保持悬停时的颜色不变
                                                backgroundColor: 'transparent', // 保持背景透明
                                                textDecoration: 'none' // 移除下划线
                                            }
                                        }}
                                >
                                    {showCountdown ? "Hide Time" : "Show Time"}
                                </Button>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            )}


            <FinishWarning isOpen={isOpen} onClose={onClose} onReturn={onClose} onContinue={onContinue}></FinishWarning>
        </Flex>
    );
}

export default ToeflIbtMenu;