const PanelContainer = {
  baseStyle: {
    p: { base:"2px 2px", md: "15px 15px"},
    minHeight: "calc(100vh - 123px)",
  },
};

export const PanelContainerComponent = {
  components: {
    PanelContainer,
  },
};
