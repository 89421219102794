import React, { ReactNode, useRef, useState } from 'react';
import {
    Box, Button, FormErrorMessage,
    FormControl, Text, Textarea, Heading,
    Center,
    VStack, Stack
} from "@chakra-ui/react";

import { useTranslation } from 'react-i18next';
import { useToast } from '@chakra-ui/react'
import { useSubmitFeedbackMutation } from '../services/cmApi';
import {FaStar} from "react-icons/fa";

const ResponseFeedBack = (props) => {
    const { gid, children, ...rest } = props;

    const toast = useToast()
    const [allowFeedback, setAllowFeedback] = useState(false);
    const [allowStar, setAllowStar] = useState(true);

    const [text, setText] = useState("");
    const [rate, setRate] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const { t, i18n } = useTranslation();

    const [submitStatus, SetSubmitStatus] = useState(false);

    const [submitFeedback, { isLoading, isError, error }] = useSubmitFeedbackMutation();

    const [hover, setHover] = useState(null);
    const showStarRating = () => {
        return (<Stack direction="row" spacing="5px">
            {[...Array(5)].map((star, i) => {
                const ratingValue = i + 1;
                return (
                    <Box
                        key={i}
                        color={ratingValue <= (rate) ? "#ffc107" : "#e4e5e9"}
                    >
                        <FaStar size={25} />
                    </Box>
                );
            })}
        </Stack>)

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        SetSubmitStatus(true);
        if (rate != 5 && !text.trim()) {
            setErrorMessage(t('error_feedback_content_empty'));
            return; // exit 
        } else {
            setErrorMessage(null);
        }
        setAllowFeedback(false)

        const details = JSON.stringify({ content: text });

        const formData = new FormData();
        formData.append('details', details);
        formData.append('gpt_response_gid', gid);
        formData.append('rate', rate);
        await submitFeedback(formData).unwrap().then(response => {
            if (response.message === 'success') {
                toast({
                    title: t("feedback_successful"),
                    description: t("feedback_thank_you"),
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: t("feedback_failed"),
                    description: t("feedback_error"),
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }).catch(err => {
            console.log(err)
        });
    };


    const gptFeedBack = () => {
        return (
            <>
                <Center>
                    <Box as="form" onSubmit={handleSubmit} mt={5} width="full">
                        <VStack spacing={4} alignItems="center">
                            {allowFeedback && (
                                <Box>
                                    <FormControl id="content_text" isInvalid={!!errorMessage}>
                                        <Textarea
                                            value={text}
                                            width="100%"
                                            placeholder={t("concrete_suggestions")}
                                            onChange={(e) => setText(e.target.value)}
                                            isRequired={(rate != 5)}
                                            spellcheck="false"
                                        />
                                        <FormErrorMessage>
                                            {errorMessage}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Box>
                            )}
                            {allowFeedback && (
                                <Button mt={4} colorScheme="blue" type="submit">
                                    {t("submit")}
                                </Button>
                            )}
                        </VStack>
                    </Box>
                </Center>
            </>
        );
    };

    const handleStarSubmit = async () => {
        setAllowStar(false)
        const formData = new FormData();
        formData.append('details', '{"content": "click_star"}');
        formData.append('gpt_response_gid', gid);
        formData.append('rate', rate);
        if (rate < 5) {
            setAllowFeedback(true)
        }
        await submitFeedback(formData).unwrap().then(response => {
            if (response.message === 'success') {
                toast({
                    title: t("star_rating_success"),
                    description: t("star_rating_description"),
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: t("feedback_failed"),
                    description: t("feedback_error"),
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }).catch(err => {
            console.log(err)
        });
    };

    return <>
        <VStack>
            {allowStar ?
                <Stack direction="row" spacing="5px">
                    {[...Array(5)].map((star, i) => {
                        const ratingValue = i + 1;
                        return (
                            <Box
                                as="button"
                                key={i}
                                color={ratingValue <= (hover || rate) ? "#ffc107" : "#D2CEC4"}
                                onMouseEnter={() => {
                                    console.log("bb")
                                    setHover(ratingValue); setRate(ratingValue);
                                }}
                                onMouseLeave={() => {
                                    console.log("aa")
                                    setHover(null); setRate(ratingValue);
                                }}
                                onClick={(e) => { e.preventDefault(); setRate(ratingValue); handleStarSubmit(); }}  // add onClick handler
                            >
                                <FaStar cursor="pointer" size={25} />
                            </Box>
                        );
                    })}</Stack > : showStarRating()}
            {submitStatus ?
                <Center>
                    <Text size={'sm'} textAlign="center" mt={5}>{t("feedback_recorded")}</Text>
                </Center>
                : gptFeedBack()}
        </VStack>
    </>

}

export default ResponseFeedBack;