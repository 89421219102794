import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Flex, Button, useToast, Text, VStack } from '@chakra-ui/react';

import { useUpdateQuestionOrderMutation } from 'services/cmApi';
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
const QuestionList = ({ questions, button, setSelectQid, selectQid, action, draggable }) => {
    const sortedQuestions = [...questions].sort((a, b) => {
        const orderIndexA = a.content && a.content.order_index !== undefined ? parseInt(a.content.order_index) : null;
        const orderIndexB = b.content && b.content.order_index !== undefined ? parseInt(b.content.order_index) : null;
        if (orderIndexA === null && orderIndexB === null) {
            return 0;
        }
        if (orderIndexA === null) {
            return 1;
        }
        if (orderIndexB === null) {
            return -1;
        }
        return orderIndexA - orderIndexB;
    });
    const [updateQuestionOrder, { isSuccess, isError, error }] = useUpdateQuestionOrderMutation();

    const [items, setItems] = useState(sortedQuestions);
    const toast = useToast();


    const updateQuestionOrderHandler = async (reorderedItems) => {
        try {
            const payload = reorderedItems.map((item, index) => ({
                qid: item.qid,
                order_index: index
            }));
            await updateQuestionOrder({ payload }).unwrap();
        } catch (err) {
        }
    };
    useEffect(() => {
        if (isSuccess) {
            toast({
                title: "Order updated.",
                description: "The question order has been successfully updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        }
        if (isError) {
            console.error('Failed to update order:', error);
            toast({
                title: "An error occurred.",
                description: "Unable to update question order.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }, [isSuccess, isError, error]);
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );
        setItems(reorderedItems);
        updateQuestionOrderHandler(reorderedItems);
    };

    const renderQuestion = (item, index) => (
        <Flex
            mb={2} p={2} borderWidth="1px" borderRadius="md" backgroundColor="white" shadow="sm"
            justifyContent="space-between" alignItems="center" w="full"
        >
            <VStack align="start" flex="1">
                <Box>
                    <Text fontWeight="bold">Order Index:</Text>
                    <Text>{item.order_index}</Text>
                </Box>
                <Box>
                    <Text fontWeight="bold">English Summary:</Text>
                    <Text isTruncated>{item.short_summary.en}</Text>
                </Box>
                <Box>
                    <Text fontWeight="bold">Chinese Summary:</Text>
                    <Text isTruncated>{item.short_summary.cn}</Text>
                </Box>
                <Box>
                    <Text fontWeight="bold">QID:</Text>
                    <Text>{item.qid}</Text>
                </Box>
                <Box>
                    <Text fontWeight="bold">Status:</Text>
                    <Text>{item.status}</Text>
                </Box>
            </VStack>
            {button &&
                <Button
                    colorScheme="blue"
                    onClick={() => setSelectQid(item.qid)}
                    ml={4}
                >
                    Load
                </Button>
            }
        </Flex>
    );

    return draggable ? (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <VStack {...provided.droppableProps} ref={provided.innerRef} spacing={2} w="full">
                        {items.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.qid.toString()} index={index}>
                                {(provided) => (
                                    <Box
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        w="full"
                                    >
                                        {renderQuestion(item, index)}
                                    </Box>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </VStack>
                )}
            </Droppable>
        </DragDropContext>
    ) : (
        <VStack spacing={2} w="full">
            {items.map((item, index) => renderQuestion(item, index))}
        </VStack>
    );
};

export default QuestionList;
