import {Box, Image, Progress,} from '@chakra-ui/react';
import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {ToeflIbtContext} from "./ToeflIbtContext";

function ToeflListeningAudio() {
    let history = useHistory();
    const {partIdx} = useParams();
    const {questionContent} = React.useContext(ToeflIbtContext);
    const [audioPath, setAudioPath] = React.useState(null);
    const [imgPath, setImgPath] = React.useState(null);
    const [progress, setProgress] = useState(0); // 添加进度状态
    const audioRef = useRef(null); // 创建 ref
    const {volume} = React.useContext(ToeflIbtContext); // 获取音量

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume; // 设置音量
        }
    }, [volume]);

    useEffect(() => {
        getAudioAndImage();
    }, [questionContent, partIdx]);

    function getAudioAndImage() {
        const task = questionContent?.tasks[partIdx - 1]
        setAudioPath(task?.listening_audio)
        setImgPath(task?.listening_img)
    }

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume; // 设置音量
        }
    }, [volume]);

    const handleTimeUpdate = () => {
        if (audioRef.current) {
            const currentTime = audioRef.current.currentTime;
            const duration = audioRef.current.duration;
            const percent = (currentTime / duration) * 100;
            setProgress(percent); // 更新进度
        }
    };

    const handleAudioEnded = () => {
        const nextUrl = getNextUrl();
        console.log("nextUrl", nextUrl)
        history.push(nextUrl);
    };

    function getNextUrl() {
        const nextSubIdx = questionContent?.tasks[partIdx - 1].questions[0].sub_question_idx
        const parentPath = location.pathname.split("audio")[0]
        return `${parentPath}question/${partIdx}/${nextSubIdx}`
    }


    return (
        <Box maxWidth="860px" margin="auto" padding="4">
            <Box>
                <Image
                    src={imgPath}
                    alt="Listening question image"
                    width="100%"
                    objectFit="cover"
                />
            </Box>
            <Box mt={4}>
                <audio
                    src={audioPath}
                    autoPlay={true} // 自动播放音频
                    ref={audioRef} // 使用 ref 来控制音频
                    onTimeUpdate={handleTimeUpdate} // 监听时间更新
                    onEnded={handleAudioEnded}
                    style={{display: 'none'}} // 隐藏音频控件
                />
                <Box display="flex" justifyContent="center" mt={12}> {/* 添加容器并居中 */}
                    <Progress
                        value={progress} // 使用进度状态
                        size="lg" // 设置进度条大小
                        colorScheme="teal" // 设置进度条颜色为绿色
                        border="1px solid black" // 边框颜色
                        width="80%" // 设置宽度
                        borderRadius={"xl"}
                    />
                </Box>
            </Box>

        </Box>
    );
}

export default ToeflListeningAudio;
