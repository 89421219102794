// Chakra imports
import {
    Flex, Grid, SimpleGrid,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import React from "react";
import { QuickLinksPanel } from "../../components/QuickLinksPanel";
import TPOPracticePanel from "components/TPOPracticePanel";

export default function TPOPracticeView(props) {
    return (
        <Flex flexDirection='column' pt="80px">
            <Grid
                templateColumns={{ sm: "1fr", lg: "4fr 1fr" }}
                templateRows={{ lg: "repeat(2, auto)" }}
                gap='20px'>
                <Card overflowX={{ sm: "scroll", xl: "hidden" }} width="100%" p={{ base: "0px", xs: "22px" }} py="22px"
                >
                    <TPOPracticePanel isCorpusGeneration={location.pathname.includes('corpus_generation')}></TPOPracticePanel>
                </Card>
                <QuickLinksPanel></QuickLinksPanel>
            </Grid>
        </Flex>
    );
}

