import React, {useEffect, useState} from 'react';
import {
    Box,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverTrigger,
    Text,
} from '@chakra-ui/react';
import {useGetUserPreferencesQuery} from "../services/cmApi";
import {useTranslation} from "react-i18next";

export default function QuestionCardNewUserPopover() {
    const {t, i18n} = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    let preferences = useGetUserPreferencesQuery({});

    useEffect(() => {
        if (preferences.status === 'fulfilled' && !preferences.data.shouldSilenceFTUE) {
            setIsOpen(true)
        }
    }, [preferences.status]);

    return (
        <Popover isOpen={isOpen} onClose={() => setIsOpen(false)} closeOnBlur={false} placement="bottom">
            <PopoverTrigger>
                <Box></Box>
            </PopoverTrigger>
            <PopoverContent color='black' bg='yellow.400' borderColor='black'>
                <PopoverArrow bg='yellow.400' borderColor='black' borderTopWidth={1} borderLeftWidth={1}/>
                <PopoverCloseButton/>
                <PopoverBody>
                    <Text my='20px'>
                        {t('first_time_user_card_instructions')}
                    </Text>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}
