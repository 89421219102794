import {useTranslation} from "react-i18next";
import {Badge, Box, Center, chakra, Flex, Text, useBreakpointValue} from "@chakra-ui/react";
import {TimeIcon} from "@chakra-ui/icons";
import {ShareResultButton} from "../ShareResultButton";
import React from "react";
import getTimeDescription from "../utils/getTimeDescription";

export function AdminCreditHistoryRow(props) {
    const { t, i18n } = useTranslation();
    const isMobile = useBreakpointValue({ base: true, md: false });

    let task = props.data
    return (
        <Flex
            direction={'column'}
            textAlign={'left'}
            justifyContent={'space-between'}
            p={3}
            width="full"
        >
            <Flex direction={'column'} width="full">
                <Flex width="full">
                    <Center>
                        <chakra.p fontWeight={'bold'} fontSize="md" p={0} m={0}>
                            {t(task.gen_type) + " "+ t(task.type)}
                        </chakra.p>
                    </Center>
                    <Center>
                        <Badge
                            variant='outline' colorScheme='cyan' ml="10px" py="0"
                            display={task.tpo ? "block" : "none"}
                        >
                            {task.gen_type == 'writing_task3' ? t('question_space') : 'TPO '}{task.tpo}
                        </Badge>
                    </Center>
                    <Center>
                        <Badge
                            variant='outline' colorScheme='green' ml="10px" py="0"
                            display={task.estimated_score != null ? "block" : "none"}
                        >
                            {t("score")} {task.estimated_score}
                        </Badge>
                    </Center>
                    <Center>
                        <Badge
                            variant='outline' colorScheme='green' ml="10px" py="0"
                            display={task.example_level ? "block" : "none"}
                        >
                            Example {task.example_level}
                        </Badge>
                    </Center>
                    <Box flex={"1"}></Box>

                    {
                        task.type !== 'generation' || task.status === "SUCCESS" ? "" :
                            <TimeIcon w={4} h={4} mr={2} color="yellow.500" />
                    }
                    <chakra.p
                        fontWeight={'medium'}
                        color={'gray.500'}
                        p={0} m={0}
                    >
                        {getTimeDescription(t, task.created_at)}
                    </chakra.p>
                </Flex>
            </Flex>
            <Flex direction={"row"}>
                <Text> delta: {t(task.delta)}</Text>
                <Box flex="1"></Box>
                <Text> balance: {t(task.balance)}</Text>
            </Flex>
            <Flex direction={"row"}>
                <Text mb={0} mt="5px"
                      fontSize={{ base: "sm", md: "15px" }} color="gray.600" noOfLines={3}
                      display={task.reason != null ? "block" : "none"}
                >
                    Reason: {task.reason}
                </Text>
                <Text mb={0} mt="5px"
                      fontSize={{ base: "sm", md: "15px" }} color="gray.600" noOfLines={3}
                      display={task.gid != null ? "block" : "none"}
                >
                    {task.summary}
                </Text>
                <Box flex="1"></Box>
                <Center display={task.gid != null ? "block" : "none"}>
                    <Flex direction={isMobile ? "column" : "row"}>
                        <ShareResultButton gid={task.gid} visible={true}></ShareResultButton>
                    </Flex>
                </Center>
            </Flex>
        </Flex>

    );
}