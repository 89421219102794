import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
    Flex,
    Select,
    Stack,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import {HSeparator} from "components/Separator/Separator";
import {SidebarExtras} from "components/Sidebar/SidebarExtras";
import React from "react";
import {Scrollbars} from "react-custom-scrollbars";
import {NavLink, useHistory} from "react-router-dom";
import {updateSection} from "../../redux/toeflSlice";
import {clearAnswers as clearAnswersIelts, updateIeltsSection} from "../../redux/ieltsSlice";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import AuthService from "../../services/auth.service";
import {selectUserPreferences} from "../../redux/generalSlice";
import {useGetUserPreferencesQuery, useUpdateUserPreferencesMutation} from "../../services/cmApi";
import {getCurrentProduct} from "../utils/common";
import AnimatedBubble from "../AnimatedBubble";
import {selectIeltsData,} from 'redux/ieltsSlice';
import {selectToeflData,} from 'redux/toeflSlice';


function NavLinks(props) {
    const activeRoute = (layout, path) => {
        if (layout) {
            return location.pathname.startsWith(layout + path) ? "active" : "";
        } else {
            return location.pathname === path ? "active" : "";
        }
    };
    // Chakra Color Mode
    let activeBg = useColorModeValue("white", "navy.700");
    let inactiveBg = useColorModeValue("white", "navy.700");
    let activeColor = useColorModeValue("gray.700", "white");
    let inactiveColor = useColorModeValue("gray.400", "gray.400");
    let sidebarActiveShadow = "0px 7px 11px rgba(0, 0, 0, 0.04)";
    let variantChange = "0.2s linear";
    const {t, i18n} = useTranslation();
    const [state, setState] = React.useState({});
    const dispatch = useDispatch();

    useGetUserPreferencesQuery({});
    const userPreferences = useSelector(selectUserPreferences);

    let product = getCurrentProduct(userPreferences)

    return props.routes.map((prop, key) => {
        if (prop.redirect) {
            return null;
        }
        if (prop.visibility === 'never') {
            return null;
        }
        if (prop.visibility === 'admin_only' && (!AuthService.getCurrentUser() || AuthService.getCurrentUser()['role'] != 'admin')) {
            return null;
        }
        if (prop.visibility === 'only_toefl' && product !== 'toefl') {
            return null;
        }
        if (prop.visibility === 'only_ielts' && product !== 'ielts') {
            return null;
        }
        if (prop.category) {
            var st = {};
            st[prop["state"]] = !state[prop.state];
            return (
                <>
                    <Text
                        color={activeColor}
                        fontWeight="bold"
                        mb={{
                            xl: "6px",
                        }}
                        mx="auto"
                        ps={{
                            sm: "10px",
                            xl: "16px",
                        }}
                        py="12px"
                    >
                        {t(prop.name)}
                    </Text>
                    {/*{createLinks(prop.views)}*/}
                    <NavLinks routes={prop.views} onOpenRegisterAlert={props.onOpenRegisterAlert}></NavLinks>
                </>
            );
        }
        let navLink
        if (prop.layout) {
            navLink = AuthService.getCurrentUser() ? prop.layout + prop.path : location.pathname + location.search;
        } else {
            navLink = AuthService.getCurrentUser() ? prop.path : location.pathname + location.search;
        }
        return (
            <NavLink to={navLink} key={key} onClick={() => {
                if (!AuthService.getCurrentUser()) {
                    props.onOpenRegisterAlert();
                    return;
                }
                if (prop.layout === '/toefl' && prop.path === '/speaking') {
                    dispatch(updateSection('speaking'));
                } else if (prop.layout === '/toefl' && prop.path === '/writing') {
                    dispatch(updateSection('writing'));
                } else if (prop.layout === '/toefl' && prop.path === '/listening') {
                    dispatch(updateSection('listening'));
                } else if (prop.layout === '/toefl' && prop.path === '/reading') {
                    dispatch(updateSection('reading'));
                } else if (prop.layout === '/ielts' && prop.path === '/speaking') {
                    dispatch(clearAnswersIelts());
                    dispatch(updateIeltsSection('speaking'));
                } else if (prop.layout === '/ielts' && prop.path === '/writing') {
                    dispatch(clearAnswersIelts());
                    dispatch(updateIeltsSection('writing'));
                }
            }}>
                {activeRoute(prop.layout, prop.path) === "active" ? (
                    <Button
                        boxSize="initial"
                        justifyContent="flex-start"
                        alignItems="center"
                        boxShadow={sidebarActiveShadow}
                        bg={activeBg}
                        transition={variantChange}
                        mb={{
                            xl: "6px",
                        }}
                        mx={{
                            xl: "auto",
                        }}
                        ps={{
                            sm: "10px",
                            xl: "16px",
                        }}
                        py="12px"
                        borderRadius="15px"
                        _hover="none"
                        w="100%"
                        _active={{
                            bg: "inherit",
                            transform: "none",
                            borderColor: "transparent",
                        }}
                        _focus={{
                            boxShadow: "0px 7px 11px rgba(0, 0, 0, 0.04)",
                        }}
                    >
                        <Flex>
                            {typeof prop.icon === "string" ? (
                                <Icon>{prop.icon}</Icon>
                            ) : (
                                <IconBox
                                    bg="blue.500"
                                    color="white"
                                    h="30px"
                                    w="30px"
                                    me="12px"
                                    transition={variantChange}
                                >
                                    {prop.icon}
                                </IconBox>
                            )}
                            {prop.alertText &&
                                <AnimatedBubble text={t(prop.alertText)} height={5}/>
                            }
                            <Text color={activeColor} my="auto" fontSize="sm">
                                {t(prop.name)}
                            </Text>

                        </Flex>
                    </Button>
                ) : (
                    <Button
                        boxSize="initial"
                        justifyContent="flex-start"
                        alignItems="center"
                        bg="transparent"
                        mb={{
                            xl: "6px",
                        }}
                        mx={{
                            xl: "auto",
                        }}
                        py="12px"
                        ps={{
                            sm: "10px",
                            xl: "16px",
                        }}
                        borderRadius="15px"
                        _hover="none"
                        w="100%"
                        _active={{
                            bg: "inherit",
                            transform: "none",
                            borderColor: "transparent",
                        }}
                        _focus={{
                            boxShadow: "none",
                        }}
                    >
                        <Flex>
                            {typeof prop.icon === "string" ? (
                                <Icon>{prop.icon}</Icon>
                            ) : (
                                <IconBox
                                    bg={inactiveBg}
                                    color="blue.500"
                                    h="30px"
                                    w="30px"
                                    me="12px"
                                    transition={variantChange}
                                >
                                    {prop.icon}
                                </IconBox>
                            )}
                            {prop.alertText &&
                                <AnimatedBubble text={t(prop.alertText)} height={5}/>
                            }
                            <Text color={inactiveColor} my="auto" fontSize="sm">
                                {t(prop.name)}
                            </Text>

                        </Flex>
                    </Button>
                )}
            </NavLink>
        );
    });
}

function SideMenu(props) {
    useGetUserPreferencesQuery({});
    const userPreferences = useSelector(selectUserPreferences);
    const [updateUserPreferencesRequest, isUpdateUserPreferencesError] = useUpdateUserPreferencesMutation();

    const {t, i18n} = useTranslation();
    let history = useHistory();
    const currentUser = AuthService.getCurrentUser();

    let product = userPreferences.product || 'toefl';
    if (location.pathname.includes('ielts')) {
        product = 'ielts'
    }
    if (location.pathname.includes('toefl')) {
        product = 'toefl'
    }
    const ieltsData = useSelector(selectIeltsData);
    const toeflData = useSelector(selectToeflData);


    const getProductSelect = () => {
        return (
            <Select color="white" width="full" mb="30px"
                    value={product} onChange={(e) => {
                if (e.target.value !== product) {
                    let prefs = {};
                    prefs['product'] = e.target.value;
                    // updateUserPreferencesRequest(prefs);
                    let section = 'speaking';
                    if (e.target.value === 'toefl') {
                        section = toeflData.section;
                    } else if (e.target.value === 'ielts') {
                        section = ieltsData.section;
                    }
                    history.push(`/${e.target.value}/${section}`);
                }
            }}>
                <option value='toefl'>{t("test_type") + t("toefl")}</option>
                <option value='ielts'>{t("test_type") + t("ielts")}</option>
            </Select>
        )
    }
    return (
        <Stack direction="column" mb="40px">
            {getProductSelect()}
            <Box><NavLinks routes={props.routes} onOpenRegisterAlert={props.onOpenRegisterAlert}></NavLinks></Box>
        </Stack>
    );
}

function Sidebar(props) {

    console.log(props , "sidebar")
    const mainPanel = React.useRef();
    let variantChange = "0.2s linear";

    const {sidebarVariant} = props;
    const {logo, routes} = props;

    //  BRAND
    //  Chakra Color Mode
    let sidebarBg = useColorModeValue("white", "navy.800");
    let sidebarRadius = "20px";
    let sidebarMargins = "0px";
    var brand = (
        <Box pt={"25px"} mb="12px">
            {logo}
            <HSeparator my="26px"/>
        </Box>
    );

    // SIDEBAR
    return (
        <Box ref={mainPanel}>
            <Box display={{sm: "none", xl: "block"}} position="fixed">
                <Box
                    bg={sidebarBg}
                    transition={variantChange}
                    w="260px"
                    maxW="260px"
                    ms={{
                        sm: "16px",
                    }}
                    my={{
                        sm: "16px",
                    }}
                    h="calc(100vh - 32px)"
                    ps="20px"
                    pe="20px"
                    m={sidebarMargins}
                    filter="drop-shadow(0px 5px 14px rgba(0, 0, 0, 0.05))"
                    borderRadius={sidebarRadius}
                >
                    <Scrollbars
                        autoHide
                        width="100%"
                    >
                        <Box>{brand}</Box>
                        <SideMenu routes={routes} onOpenRegisterAlert={props.onOpenRegisterAlert}></SideMenu>
                        <SidebarExtras sidebarVariant={sidebarVariant}/>
                    </Scrollbars>
                </Box>
            </Box>
        </Box>
    );
}

// FUNCTIONS

export function SidebarResponsive(props) {
    const {logo, routes, colorMode, hamburgerColor, ...rest} = props;
    const mainPanel = React.useRef();
    let sidebarBackgroundColor = useColorModeValue("white", "navy.800");

    //  BRAND

    var brand = (
        <Box pt={"35px"} mb="8px">
            {logo}
            <HSeparator my="26px"/>
        </Box>
    );

    // Color variables
    return (
        <Flex
            display={{sm: "flex", xl: "none"}}
            ref={mainPanel}
            alignItems="center"
        >
            <Drawer
                isOpen={props.isSideBarOpen}
                onClose={props.onSideBarClose}
                placement={document.documentElement.dir === "rtl" ? "right" : "left"}
                finalFocusRef={props.hamburgerButtonRef}
            >
                <DrawerOverlay/>
                <DrawerContent
                    w="250px"
                    maxW="250px"
                    ms={{
                        sm: "16px",
                    }}
                    my={{
                        sm: "16px",
                    }}
                    borderRadius="16px"
                    bg={sidebarBackgroundColor}
                >
                    <DrawerCloseButton
                        _focus={{boxShadow: "none"}}
                        _hover={{boxShadow: "none"}}
                    />
                    <DrawerBody maxW="250px" px="1rem">
                        <Box maxW="100%" h="100vh">
                            <Box>{brand}</Box>
                            <SideMenu routes={routes}></SideMenu>
                            <SidebarExtras/>
                        </Box>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Flex>
    );
}

export default Sidebar;
