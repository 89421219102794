import React, { ReactNode, useState } from 'react';

import {
    Text,
    Box,
    Flex,
    keyframes,
} from '@chakra-ui/react';

export default function AnimatedBubble(props) {
    var {text, ...other} = props;

    const pulse = keyframes`
        0% { transform: scale(1); }
        50% { transform: scale(1.1); }
        100% { transform: scale(1); }
      `;
    return (
        <Box position="relative"  {...other}>
            <Flex
                position="absolute"
                bottom="20px"
                bg="yellow.300"
                borderRadius="lg"
                p="1"
                zIndex="2"
                color="#55555"
                minWidth="50px"
                height='20px'
                alignItems="center"
                justifyContent="center"
                whiteSpace="nowrap"
                animation={`${pulse} 1s infinite`}
                _before={{
                    content: '""',
                    position: "absolute",
                    bottom: "-2px",
                    left: "20%",
                    transform: "translateX(-50%) rotate(45deg)",
                    bg: "yellow.300",
                    width: "12px",
                    height: "12px",
                    zIndex: "-1",
                }}
            >
                <Text fontSize="xs">{text}</Text>
            </Flex>
        </Box>
    );
}
