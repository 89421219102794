import React, {useEffect, useRef, useState} from 'react';
import {Box, Checkbox, CheckboxGroup, Radio, RadioGroup, Text, VStack} from '@chakra-ui/react';
import {useParams} from "react-router-dom";
import {ToeflIbtContext} from "./ToeflIbtContext";

function ListeningQuestion({setIsCountdownPaused}) {
    const {partIdx, subIdx} = useParams();
    const {
        questionContent,
        answerContent,
        setAnswerContent,
        volume
    } = React.useContext(ToeflIbtContext);
    const [question, setQuestion] = useState({});
    const [display, setDisplay] = useState(false);
    const [questionAudio, setQuestionAudio] = useState('');
    const audioRef = useRef(null);

    useEffect(() => {
        setQuestion({})
        setDisplay(false)
        setQuestionAudio('')
        getQuestionInfo();
    }, [questionContent, partIdx, subIdx]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume; // 设置音量
        }
    }, [volume]);

    function getQuestionInfo() {
        const taskObj = questionContent?.tasks[partIdx - 1];
        if (taskObj && taskObj?.questions) {
            for (const questionObj of taskObj?.questions) {
                if (questionObj.sub_question_idx.toString() === subIdx) {
                    setQuestion(questionObj);
                    if (questionObj.sub_question_audio && questionObj.sub_question_audio.audio_url) {
                        setQuestionAudio(questionObj.sub_question_audio.audio_url);
                    }
                    return;
                }
            }
        }
    }


    const renderOptions = () => {
        const handleOptionChange = (value) => {
            setAnswerContent(prevAnswerContent => ({
                ...prevAnswerContent,
                listening: {
                    ...prevAnswerContent.listening,
                    [subIdx]: value
                }
            }));
        };

        if (question.sub_question_answer.length > 1) {
            return (
                <CheckboxGroup value={answerContent?.listening?.[subIdx] || []} onChange={handleOptionChange}>
                    <VStack align="start" spacing={2}>
                        {question.sub_question_options && Object.entries(question.sub_question_options).map(([key, option]) => (
                            <Checkbox key={key} value={key}>
                                {option}
                            </Checkbox>
                        ))}
                    </VStack>
                </CheckboxGroup>
            );
        } else {
            return (
                <RadioGroup value={answerContent?.listening?.[subIdx] || ''} onChange={handleOptionChange}>
                    <VStack align="start" spacing={2}>
                        {question.sub_question_options && Object.entries(question.sub_question_options).map(([key, option]) => (
                            <Radio key={key} value={key}>{option}</Radio>
                        ))}
                    </VStack>
                </RadioGroup>
            );
        }
    };


    return (
        <Box maxWidth="860px" margin="auto" padding="4">
            <VStack spacing={4} align="stretch">
                <audio
                    src={questionAudio}
                    autoPlay={true} // 自动播放音频
                    ref={audioRef}
                    onEnded={() => {
                        setTimeout(() => {
                            setDisplay(true);
                            setIsCountdownPaused(false)
                        }, 500);
                    }}
                    style={{display: 'none'}} // 隐藏音频控件
                />
                <Text fontSize="lg" fontWeight="bold" mb={2}>{question?.sub_question_texts}</Text>
                {(question?.sub_question_answer && question.sub_question_answer.length > 1) && (
                    <Box
                        bg="teal.100"
                        borderRadius="xl"
                        padding="2"
                        pl="4"
                        boxShadow="md"
                        textAlign="left"
                        fontSize={"md"}
                    >
                        Select {question.sub_question_answer.length} answers.
                    </Box>

                    // <Text fontSize="sm"
                    //       color="teal.500"> Select {question.sub_question_answer.length} answers</Text>
                )}
                {display && (renderOptions())}
            </VStack>
        </Box>
    );
}

export default ListeningQuestion;
