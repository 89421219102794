import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Tab,
    TabList,
    Tabs,
    Text,
    useColorModeValue,
    useDisclosure,
    useToast,
    Select,
    Checkbox,
    Stack,
    HStack
} from "@chakra-ui/react";

import CardHeader from "./Card/CardHeader";
import CardBody from "./Card/CardBody";
import Card from "./Card/Card";
import { useDispatch, useSelector } from "react-redux";
import {
    clearQuestionInputs,
    loadQuestionInputs,
    selectBundleId,
    selectBundleStatus,
    selectQuestionInputs,
    selectTest,
    updateQuestionInputs,
    updateTest,
    deleteQuestionInputs,
    setQuestionInputs,
} from "../redux/adminSlice";
import ReactAudioPlayer from "react-audio-player";
import { Textarea } from "@chakra-ui/textarea";
import { useTranslation } from 'react-i18next';

import {
    useGetPracticeBundlesQuery,
    useGetQuestionsByQuestionSetQuery,
    useUpdateQuestionBundleStatusMutation,
    useUploadPracticeBundleMutation
} from "../services/cmApi";
import authHeader from "../services/auth-header";
import axios from "axios";
import AdminAddTags from "./AdminAddTags";
import QuestionList from "QuestionList";
import { extractSummary } from "./utils/common";
function TextInputRow(props) {
    const { displayName, keyName, filterProduct, onlyOneRow, children, ...rest } = props;
    const questionInputs = useSelector(selectQuestionInputs);
    const dispatch = useDispatch();

    const getValue = () => {
        var result = questionInputs[keyName];
        return (typeof result !== "undefined") ? result : "";
    }

    return <Flex mt="20px" flexDirection={onlyOneRow ? "row" : "column"}>
        <Flex>
            <Center>
                <Text fontWeight="bold" minWidth="120px">
                    {displayName}
                </Text>
            </Center>
        </Flex>
        <Box m="10px"></Box>
        {onlyOneRow ?
            <Center width={"full"}>
                <Input type="text" value={getValue()} backgroundColor="white"
                    onChange={(evt) => {
                        dispatch(updateQuestionInputs({
                            key: keyName,
                            value: evt.target.value
                        }));
                    }} />
            </Center> :
            <Center>
                <Textarea
                    onChange={(evt) => {
                        dispatch(updateQuestionInputs({
                            key: keyName,
                            value: evt.target.value
                        }));
                    }} value={getValue()}
                    height="200px"
                    spellcheck="false"
                ></Textarea>
            </Center>
        }
    </Flex>;
}

function AudioInputRow(props) {
    const { displayName, keyName, filterProduct, children, ...rest } = props;
    const questionInputs = useSelector(selectQuestionInputs);
    const dispatch = useDispatch();

    const handleUpload = (e) => {
        const reader = new FileReader();
        let file = e.target.files[0]
        if (!file) {
            return;
        }
        reader.readAsDataURL(file);

        reader.onloadend = async () => {
            dispatch(updateQuestionInputs({
                key: keyName,
                value: reader.result
            }));
        };
    };

    const getAudioSrc = (data) => {
        if (data && data.url) {
            return data.url;
        } else {
            return data;
        }
    }

    return <Flex mt="20px">
        <Center>
            <Text fontWeight="bold">
                {displayName}
            </Text>
        </Center>

        <input id={keyName + 'file'} type="file" accept=".m4a,audio/*" onChange={handleUpload} rounded={'full'} style={{ display: 'none' }}
        />
        <Box mx="10px"></Box>
        <Center>
            <Button
                fontSize={'sm'}
                rounded={'full'}
                bg={'blue.400'}
                color={'white'}
                _hover={{
                    bg: 'blue.500',
                }}
                _focus={{
                    bg: 'blue.500',
                }}
                minWidth={120}
                onClick={() => {
                    const fileInput = document.getElementById(keyName + 'file');
                    fileInput.click();
                }
                }
            >上传录音</Button>
        </Center>
        <Box mx="10px"></Box>
        <Center>
            <ReactAudioPlayer
                src={getAudioSrc(questionInputs[keyName])}
                controls={getAudioSrc(questionInputs[keyName])}
            />
        </Center>
    </Flex>;
}

export function IeltsQuestionInputPanel(props) {
    const { t, i18n } = useTranslation();
    const { displayName, questionInputs, part, ...rest } = props;
    const dispatch = useDispatch();
    let rows = Object.keys(questionInputs).filter(key => key.startsWith(`${part}_question_`)).map(key => ({ keyName: key, displayName: key })); // [key, value]
    const addRow = () => {
        let keyName = `${part}_question_${rows.length}`
        dispatch(updateQuestionInputs({
            key: keyName,
            value: ""
        }))
    };

    const deleteRow = (part, index) => {
        dispatch(deleteQuestionInputs(`${part}_question_${index}`));
    };

    const showDeleteButton = (index) => {
        if (index != rows.length - 1) {
            return false
        }
        if (part == 'ispeaking_part1' && index > 0) {
            return true
        } else if (part == 'ispeaking_part2&3' && index > 1) {
            return true
        } else if (part == 'iwriting_task2') {
            return false
        }
    }
    const showAddButton = (index) => {
        if (part == 'iwriting_task2' && index !== 0) {
            return false
        }
        return true
    }

    return (
        <>
            {/* {part} */}
            <Flex direction="column">
                <TextInputRow displayName={`${t(part)} Topic en`} keyName={`${part}_topic_en`} onlyOneRow />
                <TextInputRow displayName={`${t(part)} Topic cn`} keyName={`${part}_topic_cn`} onlyOneRow />
                {rows.map((row, index) => (
                    <Flex key={row.keyName} direction="column" mb="20px">
                        <TextInputRow {...row} />
                        <Button onClick={() => deleteRow(part, index)} colorScheme="red" display={showDeleteButton(index) ? "block" : "none"}>删除问题</Button>
                    </Flex>
                ))}
                <Button onClick={addRow} colorScheme="blue" display={showAddButton(rows.length) ? "block" : "none"}>添加问题</Button>
            </Flex>
        </>
    );
}

const mapTabIndexIelts = (part) => {
    if (part == "ispeaking_part1") {
        return 0
    } else if (part == "ispeaking_part2&3") {
        return 1
    } else if (part == "iwriting_task2") {
        return 2
    }

}
const indexToPartIelts = (tabIndex, partOnly = false) => {
    if (tabIndex == 0) {
        if (partOnly) {
            return "part1"
        }
        return "ispeaking_part1"
    } else if (tabIndex == 1) {
        if (partOnly) {
            return "part2&3"
        }
        return "ispeaking_part2_3";
    } else if (tabIndex == 2) {
        if (partOnly) {
            return "part2"
        }
        return "iwriting_task2";
    }
    return ""
};

export function AdminQuestionBankToolsPanel(props) {
    const { t, i18n } = useTranslation();
    const textColor = useColorModeValue("gray.700", "white");
    const toast = useToast()
    const [tabIndex, setTabIndex] = useState("");
    const [isBundle, setIsBundle] = useState(1);
    const [questionSetNames, setQuestionSetNames] = useState([])
    const [questionSet, setQuestionSet] = useState("")
    const [section, setSection] = useState("speaking")
    const [task, setTask] = useState("")
    const [UpdateQuestionSet, setUpdateQuestionSet] = useState("")
    const [tags, setTags] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectQid, setSelectQid] = useState("")
    const [isEditSingleQuestion, setIsEditSingleQuestion] = useState(1)
    const [updateBundleRequest, isUpdateBundleRequestError] = useUploadPracticeBundleMutation();
    const [updateQuestionBundleStatusRequest, isUpdateQuestionBundleStatusRequestError] = useUpdateQuestionBundleStatusMutation();
    const questionInputs = useSelector(selectQuestionInputs);
    const bundleId = useSelector(selectBundleId);
    const bundleStatus = useSelector(selectBundleStatus);
    const test = useSelector(selectTest);
    console.log("bundleStatus", bundleStatus)
    let question_bundles = useGetPracticeBundlesQuery({});
    // let question_set_questions = useGetQuestionsByQuestionSetQuery({ question_set: questionSet });
    let {
        data: question_set_questions,
        status: question_set_questions_status,
        refetch,
    } = useGetQuestionsByQuestionSetQuery({ question_set: questionSet, section_task: indexToPartIelts(tabIndex) });
    const [isUpdating, setIsUpdating] = useState(false);

    const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
    const fetchQuestionSetNames = async () => {
        try {
            const response = await axios(API_URL + `/api/get_question_set_names`);
            const data = response.data.data.map(item => item.question_set)
            setQuestionSetNames(["ALL"].concat(data));
            setQuestionSet("ALL");
            setUpdateQuestionSet("ALL");

        } catch (err) {
            console.error(err);
        }
    };

    const fetchTags = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/get_tags`);
            if (response.data.status === 'success') {
                setTags(response.data.tags);
            } else {
                toast({
                    title: "Error fetching tags",
                    description: response.data.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error("Error fetching tags:", error);
        }
    };
    const handleTagSelection = async (tagId, tabIndex, sync = true) => {
        const isTagSelected = selectedTags.includes(tagId);
        let updatedTags;

        if (isTagSelected) {
            updatedTags = selectedTags.filter(id => id !== tagId);
        } else {
            updatedTags = [...selectedTags, tagId];
        }

        dispatch(updateQuestionInputs({
            key: `${indexToPartIelts(tabIndex)}_tags`, // Assuming 'mapTabIndexIelts' returns the correct part
            value: updatedTags
        }));

        if (sync) {
            try {
                const apiEndpoint = isTagSelected ? '/api/admin_remove_tag_from_question' : '/api/admin_add_tag_to_question';
                await axios.post(API_URL + apiEndpoint, {
                    qid: selectQid,
                    tag_id: tagId,
                }, { headers: authHeader() });

                // Update local state after successful API response
                setSelectedTags(updatedTags);

                toast({
                    title: `Tag ${isTagSelected ? "Removed" : "Added"}`,
                    description: `Tag has been successfully ${isTagSelected ? "removed from" : "added to"} the question.`,
                    status: "success",
                    duration: 5000,
                    isClosable: true
                });

                fetchQuestionData(selectQid);
            } catch (error) {
                console.error(`Error ${isTagSelected ? "removing" : "adding"} tag to question:`, error);
            }
        } else {
            // Update local state immediately without API call
            setSelectedTags(updatedTags);
        }
    };

    const handleRemoveTag = async (tagId) => {
        try {
            const response = await axios.post(`${API_URL}/api/admin_remove_tags`, { tag_id: tagId }, { headers: authHeader() });
            toast({
                title: "Tag Removed",
                description: response.data.message,
                status: "success",
                duration: 5000,
                isClosable: true
            });
            fetchTags();
        } catch (error) {
            toast({
                title: "Error",
                description: error.response?.data?.message || "Failed to remove tag",
                status: "error",
                duration: 5000,
                isClosable: true
            });
        }
    };

    const refreshTags = () => {
        fetchTags();
    };
    const fetchQuestionData = async (qid) => {
        try {
            const response = await axios.get(`${API_URL}/api/admin_get_question_by_qid?qid=${qid}`, { headers: authHeader() });
            if (response.data.status === 'success') {
                dispatch(clearQuestionInputs());
                dispatch(loadQuestionInputs(response.data.data));
                setSelectQid(qid);
                // setIsTagDisplay(response.data.data.questions[0].tags[0] ? response.data.data.questions[0].tags[0].display : null);
                setSelectedTags(response.data.data.questions[0].tags.map((tag) => tag.id));
                setTabIndex(mapTabIndexIelts(response.data.data.questions[0].section + "_" + response.data.data.questions[0].task));
            }
        } catch (error) {
            console.error("Error fetching question data:", error);
        }
    };
    const handleSubmitSingleQuestion = async () => {
        setIsUpdating(true);

        const payload = {
            test: test,
            task: tabIndex == 0 ? "ispeaking_part1" : (tabIndex == 1 ? "ispeaking_part2_3" : "iwriting_task2"),
            inputs: questionInputs,
            question_set: UpdateQuestionSet,
            headers: authHeader()
        };
        try {
            const response = await axios.post(`${API_URL}/api/admin_add_question`, payload, { headers: authHeader() });
        } catch (error) {
            console.error("Error submitting single question:", error);
        }
        setIsUpdating(false);

    };
    const handleSubmitUpdateSingleQuestion = async () => {
        setIsUpdating(true);
        const payload = {
            test: test,
            task: tabIndex === 0 ? "ispeaking_part1" : (tabIndex === 1 ? "ispeaking_part2_3" : "iwriting_task2"),
            inputs: questionInputs,
            qid: selectQid,
            question_set: UpdateQuestionSet
        };

        try {
            const response = await axios.post(`${API_URL}/api/admin_update_question`, payload, { headers: authHeader() });
            toast({
                title: "Update Successful",
                description: "The question has been updated successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "bottom"
            });

        } catch (error) {
            console.error("Error updating single question:", error);
            toast({
                title: "Update Failed",
                description: "Failed to update the question. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom"
            });
        }
        setIsUpdating(false);
    };
    const handleOpenModal2 = async () => {
        setIsLoading(true);
        await refetch();
        setIsLoading(false);
        onOpen2();
    };


    useEffect(() => {
        fetchQuestionSetNames();
        fetchTags();
    }, [])

    const [questions, setQuestions] = useState([]);

    const transformQuestions = (questionsData) => {
        return questionsData.map((question) => {
            let content = {
                "order_index": question.order_index,
                "short_summary_en": extractSummary(question.short_summary, 'en'),
                "short_summary_cn": extractSummary(question.short_summary, 'cn'),
            }
            return {
                id: question.qid,
                content: content
            }
        }
        )
    };
    useEffect(() => {
        if (question_set_questions_status === "fulfilled") {
            const transformedQuestions = transformQuestions(question_set_questions.data);
            setQuestions(transformedQuestions);
        }
    }, [question_set_questions]);

    return (
        <>
            <Card>
                <CardHeader>
                    <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
                        Add Tags
                    </Text>
                </CardHeader>
                <CardBody>
                    <AdminAddTags onTagAdded={refreshTags} />
                    <Box>
                        <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
                            Manage Tags
                        </Text>
                        {tags.map(tag => (
                            <Flex key={tag.id} align="center" justify="space-between" mb={2}>
                                <Text mr={4}>{tag.en} / {tag.cn}  ------ type: {tag.type}</Text>
                                <Button size="sm" colorScheme="red" onClick={() => handleRemoveTag(tag.id)}>
                                    Remove
                                </Button>
                            </Flex>
                        ))}
                    </Box>
                </CardBody>
            </Card>

            <Text>选择录入类型</Text>
            <Select
                value={isBundle}
                onChange={(e) => {
                    setIsBundle(e.target.value)
                    dispatch(clearQuestionInputs())
                    dispatch(updateTest(e.target.value == 0 ? "ielts" : "toefl")) // single question only support ielts for now
                    setTabIndex(0)
                    setSelectQid("")
                    setIsEditSingleQuestion(1)
                }}>
                <option value={1}>套题</option>
                <option value={0}>单题</option>
            </Select>

            {isBundle == 1 &&
                <Card
                    overflowX={{ sm: "scroll", xl: "hidden" }}
                >
                    <CardHeader p="6px 0px 22px 0px">
                        <Flex direction="column">
                            <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
                                录入套题
                            </Text>
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        <Flex flexDirection="column">
                            <Flex>
                                <Button colorScheme='blue' onClick={onOpen}>导入</Button>
                                <Box mx='10px'></Box>
                                <Button colorScheme='blue' onClick={() => {
                                    dispatch(clearQuestionInputs())
                                }}>清空</Button>
                            </Flex>
                            <Box>
                                <TextInputRow displayName='套题名字' keyName='bundleName' onlyOneRow={true}></TextInputRow>
                                <Text>选择考试类型</Text>
                                <Select value={test}
                                    onChange={(e) => {
                                        dispatch(updateTest(e.target.value))
                                        setTabIndex(0)
                                    }}>
                                    <option value='toefl'>toefl</option>
                                    <option value='ielts'>ielts</option>
                                </Select>
                                <Modal isOpen={isOpen} onClose={onClose}>
                                    <ModalOverlay />
                                    <ModalContent>
                                        <ModalHeader>选择套题</ModalHeader>
                                        <ModalCloseButton />
                                        <ModalBody >
                                            {question_bundles.status === 'fulfilled' && (
                                                <ul>
                                                    {question_bundles.data.data.map((item) => (
                                                        <li key={item.bundle_id}>
                                                            {item.test} | {item.bundle_id}: {item.bundle_name}
                                                            <Button
                                                                ml="10px"
                                                                bg={'blue.400'} color={'white'}
                                                                onClick={async () => {
                                                                    let headers = authHeader();
                                                                    const response = await axios.get(API_URL + `/api/admin_get_question_bundle?bundle_id=` + item.bundle_id, { headers: headers });
                                                                    if (response.data.status === 'success') {
                                                                        dispatch(loadQuestionInputs(response.data.data))
                                                                    }
                                                                }}
                                                            >
                                                                Load
                                                            </Button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </ModalBody>
                                    </ModalContent>
                                </Modal>

                            </Box>
                            {(test == "toefl" || test == null) &&
                                <Box>
                                    <Box borderWidth='2px' rounded="20px" mt="20px" >
                                        <Flex mt="30px">
                                            <Text fontSize='xl' fontWeight='bold' ml="20px">套题: {bundleId}</Text>
                                        </Flex>
                                        <Center mt="30px">
                                            <Tabs width="100%" index={tabIndex} onChange={(index) => {
                                                setTabIndex(index);
                                            }}>
                                                <TabList>
                                                    <Tab px={{ base: "5px", md: "16px" }}>
                                                        <Text fontSize='xl' fontWeight='bold'>口语任务1</Text>
                                                    </Tab>
                                                    <Tab px={{ base: "5px", md: "16px" }}>
                                                        <Text fontSize='xl' fontWeight='bold'>口语任务2</Text>
                                                    </Tab>
                                                    <Tab px={{ base: "5px", md: "16px" }}>
                                                        <Text fontSize='xl' fontWeight='bold'>口语任务3</Text>
                                                    </Tab>
                                                    <Tab px={{ base: "5px", md: "16px" }}>
                                                        <Text fontSize='xl' fontWeight='bold'>口语任务4</Text>
                                                    </Tab>
                                                    <Tab px={{ base: "5px", md: "16px" }}>
                                                        <Text fontSize='xl' fontWeight='bold'>写作任务1</Text>
                                                    </Tab>
                                                    <Tab px={{ base: "5px", md: "16px" }}>
                                                        <Text fontSize='xl' fontWeight='bold'>写作任务3</Text>
                                                    </Tab>
                                                </TabList>
                                            </Tabs>
                                        </Center>
                                        <Box p="20px" mt="10px" >
                                            <Box display={tabIndex == 0 ? 'block' : 'none'}>
                                                <TextInputRow displayName='口语任务1问题' keyName='speaking_task1_topic'></TextInputRow>
                                            </Box>
                                            <Box display={tabIndex == 1 ? 'block' : 'none'}>
                                                <TextInputRow displayName='口语任务2阅读' keyName='speaking_task2_reading'></TextInputRow>
                                                <TextInputRow displayName='口语任务2听力文本' keyName='speaking_task2_listening'></TextInputRow>
                                                <AudioInputRow displayName='口语任务2听力文件' keyName='speaking_task2_listening_audio'></AudioInputRow>
                                                <TextInputRow displayName='口语任务2问题' keyName='speaking_task2_topic'></TextInputRow>
                                            </Box>
                                            <Box display={tabIndex == 2 ? 'block' : 'none'}>
                                                <TextInputRow displayName='口语任务3阅读' keyName='speaking_task3_reading'></TextInputRow>
                                                <TextInputRow displayName='口语任务3听力文本' keyName='speaking_task3_listening'></TextInputRow>
                                                <AudioInputRow displayName='口语任务3听力文件' keyName='speaking_task3_listening_audio'></AudioInputRow>
                                                <TextInputRow displayName='口语任务3问题' keyName='speaking_task3_topic'></TextInputRow>
                                            </Box>
                                            <Box display={tabIndex == 3 ? 'block' : 'none'}>
                                                <TextInputRow displayName='口语任务4听力文本' keyName='speaking_task4_listening'></TextInputRow>
                                                <AudioInputRow displayName='口语任务4听力文件' keyName='speaking_task4_listening_audio'></AudioInputRow>
                                                <TextInputRow displayName='口语任务4问题' keyName='speaking_task4_topic'></TextInputRow>
                                            </Box>
                                            <Box display={tabIndex == 4 ? 'block' : 'none'}>
                                                <TextInputRow displayName='写作任务1阅读' keyName='writing_task1_reading'></TextInputRow>
                                                <TextInputRow displayName='写作任务1听力文本' keyName='writing_task1_listening'></TextInputRow>
                                                <AudioInputRow displayName='写作任务1听力文件' keyName='writing_task1_listening_audio'></AudioInputRow>
                                                <TextInputRow displayName='写作任务1问题' keyName='writing_task1_topic'></TextInputRow>
                                            </Box>
                                            <Box display={tabIndex == 5 ? 'block' : 'none'}>
                                                <TextInputRow displayName='写作任务3问题' keyName='writing_task3_topic'></TextInputRow>
                                                <TextInputRow displayName='学术讨论1名字' keyName='discussion1_name' onlyOneRow={true}></TextInputRow>
                                                <TextInputRow displayName='学术讨论1内容' keyName='discussion1_content'></TextInputRow>
                                                <TextInputRow displayName='学术讨论2名字' keyName='discussion2_name' onlyOneRow={true}></TextInputRow>
                                                <TextInputRow displayName='学术讨论2内容' keyName='discussion2_content'></TextInputRow>
                                                <TextInputRow displayName='学术讨论3名字' keyName='discussion3_name' onlyOneRow={true}></TextInputRow>
                                                <TextInputRow displayName='学术讨论3内容' keyName='discussion3_content'></TextInputRow>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            }

                            {
                                test == "ielts" &&
                                <Box borderWidth='2px' rounded="20px" mt="20px" >
                                    <Flex mt="30px">
                                        <Text fontSize='xl' fontWeight='bold' ml="20px">套题: {bundleId}</Text>
                                    </Flex>
                                    <Center mt="30px">
                                        <Tabs width="100%" index={tabIndex} onChange={(index) => {
                                            setTabIndex(index);
                                        }}>
                                            <TabList>
                                                <Tab px={{ base: "5px", md: "16px" }}>
                                                    <Text fontSize='xl' fontWeight='bold'>ispeaking_part1</Text>
                                                </Tab>
                                                <Tab px={{ base: "5px", md: "16px" }}>
                                                    <Text fontSize='xl' fontWeight='bold'>ispeaking_part2&3</Text>
                                                </Tab>
                                                <Tab px={{ base: "5px", md: "16px" }}>
                                                    <Text fontSize='xl' fontWeight='bold'>iwriting_task2</Text>
                                                </Tab>
                                            </TabList>
                                        </Tabs>

                                    </Center>
                                    <Box p="20px" mt="10px" >
                                        <Box display={tabIndex == 0 ? 'block' : 'none'}>
                                            <IeltsQuestionInputPanel part="ispeaking_part1" questionInputs={questionInputs} dispatch={dispatch} />
                                        </Box>
                                        <Box display={tabIndex == 1 ? 'block' : 'none'}>
                                            <IeltsQuestionInputPanel part="ispeaking_part2_3" questionInputs={questionInputs} dispatch={dispatch} />
                                        </Box>
                                        <Box display={tabIndex == 2 ? 'block' : 'none'}>
                                            <IeltsQuestionInputPanel part="iwriting_task2" questionInputs={questionInputs} dispatch={dispatch} />
                                        </Box>
                                    </Box>
                                </Box>

                            }




                            <Box display={isUpdateBundleRequestError.status !== 'pending' ? 'block' : 'none'}>
                                <Flex mt="20px" flexDirection="row" gap={5}>
                                    <Center>
                                        <Text>套题状态： {bundleStatus == 'PUBLISHED' ? "已发布" : "未发布"}</Text>
                                    </Center>
                                    <Center>
                                        <Button
                                            fontSize={'sm'}
                                            rounded={'full'}
                                            bg={'blue.400'}
                                            color={'white'}
                                            _hover={{
                                                bg: 'blue.500',
                                            }}
                                            _focus={{
                                                bg: 'blue.500',
                                            }}
                                            minWidth={120}
                                            onClick={() => {
                                                const targetStatus = bundleStatus == 'ACTIVE' ? `PUBLISHED` : "ACTIVE";
                                                updateQuestionBundleStatusRequest({
                                                    bundle_id: bundleId,
                                                    status: targetStatus
                                                }).then(function (response) {
                                                    if (!response['data'] || response['data']['status'] !== 'success') {
                                                        let title = JSON.stringify(response);
                                                        if (response['error'] && response['error']['data'] && response['error']['data']['type'] === 'KeyError') {
                                                            title = response['error']['data']['message']
                                                        }
                                                        toast({
                                                            title: title,
                                                            status: "error",
                                                            duration: 10000,
                                                            isClosable: true,
                                                        });
                                                    } else {
                                                        toast({
                                                            title: `成功更新套题状态 ${response.data.data.bundle_id} ${targetStatus}`,
                                                            status: "success",
                                                            duration: 10000,
                                                            isClosable: true,
                                                        });
                                                        dispatch(clearQuestionInputs())
                                                    }
                                                }).catch(function (error) {
                                                    toast({
                                                        title: JSON.stringify(error),
                                                        status: "error",
                                                        duration: 10000,
                                                        isClosable: true,
                                                    });
                                                });
                                            }
                                            }
                                        >{bundleStatus == 'ACTIVE' ? `发布` : "取消发布"} </Button>
                                    </Center>
                                </Flex>
                            </Box>
                            <Flex mt="20px" gap={5}>
                                <Center display={isUpdateBundleRequestError.status !== 'pending' ? 'block' : 'none'}>
                                    <Button
                                        fontSize={'sm'}
                                        rounded={'full'}
                                        bg={'blue.400'}
                                        color={'white'}
                                        _hover={{
                                            bg: 'blue.500',
                                        }}
                                        _focus={{
                                            bg: 'blue.500',
                                        }}
                                        minWidth={120}
                                        onClick={() => {
                                            updateBundleRequest({
                                                bundle_id: bundleId,
                                                test: test,
                                                inputs: questionInputs
                                            }).then(function (response) {
                                                if (!response['data'] || response['data']['status'] !== 'success') {
                                                    let title = JSON.stringify(response);
                                                    if (response['error'] && response['error']['data'] && response['error']['data']['type'] === 'KeyError') {
                                                        title = response['error']['data']['message']
                                                    }
                                                    toast({
                                                        title: title,
                                                        status: "error",
                                                        duration: 10000,
                                                        isClosable: true,
                                                    });
                                                } else {
                                                    toast({
                                                        title: `成功上传套题 ${response.data.bundle_id}`,
                                                        status: "success",
                                                        duration: 10000,
                                                        isClosable: true,
                                                    });
                                                    dispatch(clearQuestionInputs())
                                                }
                                            }).catch(function (error) {
                                                toast({
                                                    title: JSON.stringify(error),
                                                    status: "error",
                                                    duration: 10000,
                                                    isClosable: true,
                                                });
                                            });
                                        }
                                        }
                                    >{bundleId ? `更新套题 ${bundleId}` : "创建套题"} </Button>
                                </Center>
                                <Center display={isUpdateBundleRequestError.status !== 'pending' && bundleId ? 'block' : 'none'}>
                                    <Button
                                        fontSize={'sm'}
                                        rounded={'full'}
                                        bg={'red.400'}
                                        color={'white'}
                                        _hover={{
                                            bg: 'blue.500',
                                        }}
                                        _focus={{
                                            bg: 'blue.500',
                                        }}
                                        minWidth={120}
                                        onClick={() => {
                                            updateQuestionBundleStatusRequest({ bundle_id: bundleId, status: 'DELETED' }).then(function (response) {
                                                if (!response['data'] || response['data']['status'] !== 'success') {
                                                    let title = JSON.stringify(response);
                                                    if (response['error'] && response['error']['data'] && response['error']['data']['type'] === 'KeyError') {
                                                        title = response['error']['data']['message']
                                                    }
                                                    toast({
                                                        title: title,
                                                        status: "error",
                                                        duration: 10000,
                                                        isClosable: true,
                                                    });
                                                } else {
                                                    toast({
                                                        title: `成功删除套题${bundleId}！`,
                                                        status: "info",
                                                        duration: 30000,
                                                        isClosable: true,
                                                    });
                                                    dispatch(clearQuestionInputs())
                                                }

                                            }).catch(function (error) {
                                                toast({
                                                    title: JSON.stringify(error),
                                                    status: "error",
                                                    duration: 10000,
                                                    isClosable: true,
                                                });
                                            });

                                        }
                                        }
                                    >{`删除套题 ${bundleId}`} </Button>
                                </Center>

                                <Center>
                                    <Box display={isUpdateBundleRequestError.status === 'pending' ? 'block' : 'none'}>
                                        <Spinner
                                            thickness='4px'
                                            speed='0.65s'
                                            emptyColor='gray.200'
                                            color='blue.500'
                                            size='lg'
                                            mt='20px'
                                        />
                                    </Box>
                                </Center>
                            </Flex>
                        </Flex>
                    </CardBody>
                </Card>
            }



            {isBundle == 0 &&
                <Card
                    overflowX={{ sm: "scroll", xl: "hidden" }}
                >
                    <CardHeader p="6px 0px 22px 0px">
                        <Flex direction="column">
                            <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">修改单题/增加单题</Text>
                        </Flex>
                    </CardHeader>
                    <Select
                        value={isEditSingleQuestion}
                        onChange={(e) => {
                            setIsEditSingleQuestion(e.target.value)
                            setIsBundle(0)
                            dispatch(clearQuestionInputs())
                            dispatch(updateTest("ielts")) // single question only support ielts for now
                            setTabIndex(0)
                            setSelectQid("")
                            fetchTags()
                            setSelectedTags([])
                        }}>
                        <option value={1}>修改</option>
                        <option value={0}>新增</option>
                    </Select>

                    {isEditSingleQuestion == 1 &&
                        <>
                            <CardBody>
                                <Flex flexDirection="column">
                                    <Text>选择题组</Text>
                                    {questionSet}
                                    <HStack>
                                        <Select
                                            value={questionSet}
                                            onChange={(e) => {
                                                setTabIndex(0)
                                                setQuestionSet(e.target.value)
                                                setUpdateQuestionSet(e.target.value)
                                                dispatch(clearQuestionInputs())
                                                setSelectQid("")
                                                setSelectedTags([])
                                            }}>
                                            {questionSetNames.map(item => {
                                                return <option value={item}>{item}</option>
                                            })}
                                        </Select>
                                        <Select
                                            value={tabIndex}
                                            onChange={(e) => {
                                                setTabIndex(e.target.value)
                                                dispatch(clearQuestionInputs())
                                                setSelectQid("")
                                                setSelectedTags([])
                                            }}>
                                            <option value={0}>ispeaking_part1</option>
                                            <option value={1}>ispeaking_part2_3</option>
                                            <option value={2}>iwriting_task2</option>

                                        </Select>

                                    </HStack>

                                    <Flex>
                                        <Button colorScheme='blue' onClick={handleOpenModal2}>
                                            导入单题
                                        </Button>
                                        <Box mx='10px'></Box>
                                        <Modal isOpen={isOpen2} onClose={onClose2} size="full">
                                            <ModalOverlay />
                                            <ModalContent>
                                                <ModalHeader>选择题</ModalHeader>
                                                <ModalCloseButton />
                                                <ModalBody>

                                                    {isLoading ? (
                                                        <div>Loading...</div>
                                                    ) : question_set_questions_status === "fulfilled" && (
                                                        <QuestionList questions={questions} button={true} action={fetchQuestionData} draggable={questionSet === "ALL" ? false : true} />)}
                                                </ModalBody>
                                            </ModalContent>
                                        </Modal>
                                    </Flex>
                                </Flex>
                                {selectQid != "" &&
                                    <Box p="20px" mt="10px">
                                        <Box p={4} borderWidth="1px" borderRadius="lg" overflow="hidden">
                                            <Text mb={4} fontWeight="bold">Select Tags for Question</Text>
                                            <Stack spacing={3}>
                                                {tags.map(tag => (
                                                    <Checkbox
                                                        key={tag.id}
                                                        isChecked={selectedTags.includes(tag.id)}
                                                        onChange={() => handleTagSelection(tag.id)}
                                                        colorScheme="blue"
                                                        iconColor="blue.500"
                                                        borderColor="gray.300"
                                                    >
                                                        <Text as="span" ml={2}>
                                                            {tag.type}: {tag.en} / {tag.cn}
                                                        </Text>
                                                    </Checkbox>
                                                ))}
                                            </Stack>

                                        </Box>
                                        <Box p="20px" mt="10px">
                                            <Box p={4} borderWidth="1px" borderRadius="lg" overflow="hidden">
                                                <Text>选择修改题组</Text>
                                                <Select
                                                    value={UpdateQuestionSet}
                                                    onChange={(e) => {
                                                        setUpdateQuestionSet(e.target.value)
                                                    }}>
                                                    {questionSetNames.map(item => {
                                                        return <option value={item}>{item}</option>
                                                    })}
                                                </Select>
                                            </Box>
                                        </Box>
                                        <Box display={tabIndex == 0 ? 'block' : 'none'}>
                                            <IeltsQuestionInputPanel part="ispeaking_part1" questionInputs={questionInputs} dispatch={dispatch} />
                                        </Box>
                                        <Box display={tabIndex == 1 ? 'block' : 'none'}>
                                            <IeltsQuestionInputPanel part="ispeaking_part2_3" questionInputs={questionInputs} dispatch={dispatch} />
                                        </Box>
                                        <Box display={tabIndex == 2 ? 'block' : 'none'}>
                                            <IeltsQuestionInputPanel part="iwriting_task2" questionInputs={questionInputs} dispatch={dispatch} />
                                        </Box>
                                        <Button
                                            colorScheme="blue"
                                            onClick={handleSubmitUpdateSingleQuestion}
                                            isLoading={isUpdating}
                                            loadingText="Submitting"
                                            mt="20px"
                                        >
                                            Updating Question
                                        </Button>

                                    </Box>

                                }
                            </CardBody>
                        </>
                    }
                    {isEditSingleQuestion == 0 &&
                        <>
                            <Text>新增雅思</Text>
                            <Box p="20px" mt="10px">
                                <Box p={4} borderWidth="1px" borderRadius="lg" overflow="hidden"><Text>题型</Text>
                                    <Select
                                        value={tabIndex}
                                        onChange={(e) => {
                                            setIsBundle(0)
                                            dispatch(clearQuestionInputs())
                                            setTabIndex(e.target.value)
                                            setSelectQid("")
                                            fetchTags()
                                            setSelectedTags([])
                                            dispatch(updateTest("ielts")) // single question only support ielts for now

                                        }}>
                                        <option value={0}>ispeaking_part1</option>
                                        <option value={1}>ispeaking_part2_3</option>
                                        <option value={2}>iwriting_task2</option>
                                    </Select></Box>

                            </Box>
                            <Box p="20px" mt="10px">
                                <Box p={4} borderWidth="1px" borderRadius="lg" overflow="hidden">
                                    <Text>选择题组</Text>
                                    {questionSet}
                                    <Select
                                        value={UpdateQuestionSet}
                                        onChange={(e) => {
                                            setUpdateQuestionSet(e.target.value)
                                        }}>
                                        {questionSetNames.map(item => {
                                            return <option value={item}>{item}</option>
                                        })}
                                    </Select>
                                </Box>
                            </Box>

                            <Box p="20px" mt="10px">
                                <Box p={4} borderWidth="1px" borderRadius="lg" overflow="hidden">
                                    <Text mb={4} fontWeight="bold">Select Tags for Question</Text>
                                    <Stack spacing={3}>
                                        {tags.map(tag => (
                                            <Checkbox
                                                key={tag.id}
                                                isChecked={selectedTags.includes(tag.id)}
                                                onChange={() => handleTagSelection(tag.id, tabIndex, false)}
                                            >
                                                {tag.type}: {tag.en} / {tag.cn}
                                            </Checkbox>
                                        ))}
                                    </Stack>
                                </Box>
                            </Box>
                            <Box p="20px" mt="10px" >
                                <Box display={tabIndex == 0 ? 'block' : 'none'}>
                                    <IeltsQuestionInputPanel part="ispeaking_part1" questionInputs={questionInputs} dispatch={dispatch} />
                                </Box>
                                <Box display={tabIndex == 1 ? 'block' : 'none'}>
                                    <IeltsQuestionInputPanel part="ispeaking_part2_3" questionInputs={questionInputs} dispatch={dispatch} />
                                </Box>
                                <Box display={tabIndex == 2 ? 'block' : 'none'}>
                                    <IeltsQuestionInputPanel part="iwriting_task2" questionInputs={questionInputs} dispatch={dispatch} />
                                </Box>
                            </Box>
                            <Button
                                colorScheme="blue"
                                onClick={handleSubmitSingleQuestion}
                                isLoading={isUpdating}
                                loadingText="Submitting"
                                mt="20px"
                            >
                                Submit Question
                            </Button>

                        </>
                    }
                </Card >
            }
        </>
    );
}