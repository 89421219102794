import EssayScore from "./essayScore";
import { Box, Text } from "@chakra-ui/react";
import EssayComments from "./essayComments";
import { useSelector } from "react-redux";
import { selectToeflData } from "../redux/toeflSlice";
import Mindmap from "./mindmap";

export default function EssayGeneration(props) {
    const toeflData = useSelector(selectToeflData);



    // Check if content is "Duplicate"
    if (toeflData.content === "Duplicate") {
        return (
            <Box height="7rem" backgroundColor="gray.100" display="flex" textAlign="center" alignItems="center" borderRadius="2xl">
                <Text>
                    We're working on a duplicate request you recently submitted.
                    The evaluation result will be available under the history tab.
                    Thank you for your patience!
                </Text>
            </Box>
        );
    }

    // Render components when content is not "Duplicate"
    return (
        <Box display="flex" flexDirection="row" width="auto" gap="12rem" >
            <Box display="flex" flexDirection="column">
                <EssayScore gid={props.gid} />
                <EssayComments gid={props.gid} />
                <Mindmap gid={props.gid} />
            </Box>
        </Box>
    );
}
