// chakra imports
import {Avatar, Flex, Link, Text, useColorModeValue} from "@chakra-ui/react";
import { ClockIcon } from "components/Icons/Icons";
import React from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

export function ItemContent(props) {
  const navbarIcon = useColorModeValue("gray.500", "gray.200");
  const notificationColor = useColorModeValue("gray.700", "white");
  const spacing = " ";
  const { t, i18n } = useTranslation();
  let history = useHistory();

  return (
    <>
      {/*<Avatar*/}
      {/*  name={props.aName}*/}
      {/*  src={props.aSrc}*/}
      {/*  borderRadius="12px"*/}
      {/*  me="16px"*/}
      {/*/>*/}
      <Flex flexDirection="column">
        <Text fontSize="14px" mb="5px" color={notificationColor}>
          {/*<Text fontWeight="bold" fontSize="14px" as="span">*/}
          {/*  {props.boldInfo}*/}
          {/*  {spacing}*/}
          {/*</Text>*/}
          {/*{props.info}*/}
          <Link onClick={()=>{
            // history.push(i18n.language === "cn" ? "/campus_certification_guidance" : "/purchase?_lltrck=nav_btn");
            history.push("/purchase?_lltrck=nav_btn");
          }}>{t('banner_message')}</Link>
        </Text>
        {/*<Flex alignItems="center">*/}
        {/*  <ClockIcon color={navbarIcon} w="13px" h="13px" me="3px" />*/}
        {/*  <Text fontSize="xs" lineHeight="100%" color={navbarIcon}>*/}
        {/*    {props.time}*/}
        {/*  </Text>*/}
        {/*</Flex>*/}
      </Flex>
    </>
  );
}
