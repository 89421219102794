// Chakra Imports
import {
  Box,
  Button, Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex, HStack, Link, Select,
  Switch,
  Text, useBreakpointValue,
  useColorMode,
  useColorModeValue
} from "@chakra-ui/react";
import { HSeparator } from "components/Separator/Separator";
import React, { useState } from "react";
import GitHubButton from "react-github-btn";
import { FaFacebook, FaTwitter } from "react-icons/fa";
import {useTranslation} from "react-i18next";
import {useUpdateUserPreferencesMutation} from "../../services/cmApi";

export default function Configurator(props) {
  const {
    sidebarVariant,
    setSidebarVariant,
    secondary,
    isOpen,
    onClose,
    fixed,
    ...rest
  } = props;
  const [switched, setSwitched] = useState(props.isChecked);

  const { colorMode, toggleColorMode } = useColorMode();

  let bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "white"
  );
  let colorButton = useColorModeValue("white", "gray.700");
  const secondaryButtonBg = useColorModeValue("white", "transparent");
  const secondaryButtonBorder = useColorModeValue("gray.700", "white");
  const secondaryButtonColor = useColorModeValue("gray.700", "white");
  const bgDrawer = useColorModeValue("white", "navy.800");
  const settingsRef = React.useRef();

  const { t, i18n } = useTranslation();
  const [updateUserPreferencesRequest, isUpdateUserPreferencesError] = useUpdateUserPreferencesMutation();


  return (
    <>
      <Drawer
        isOpen={props.isOpen}
        onClose={props.onClose}
        placement={document.documentElement.dir === "rtl" ? "left" : "right"}
        finalFocusRef={settingsRef}
        blockScrollOnMount={false}
      >
        <DrawerContent bg={bgDrawer}>
          <DrawerHeader pt="24px" px="24px">
            <DrawerCloseButton />
            <Text fontSize="xl" fontWeight="bold" mt="16px" mb="16px">
              {t("settings")}
            </Text>
            <HSeparator />
          </DrawerHeader>
          <DrawerBody w="340px" ps="24px" pe="40px">
            <Flex flexDirection="column">
              {/*<Flex justifyContent="space-between " mb="16px">*/}
              {/*  <Text fontSize="md" fontWeight="600" mb="4px">*/}
              {/*    Navbar Fixed*/}
              {/*  </Text>*/}
              {/*  <Switch*/}
              {/*    colorScheme="blue"*/}
              {/*    isChecked={switched}*/}
              {/*    onChange={() => {*/}
              {/*      if (switched === true) {*/}
              {/*        props.onSwitch(false);*/}
              {/*        setSwitched(false);*/}
              {/*      } else {*/}
              {/*        props.onSwitch(true);*/}
              {/*        setSwitched(true);*/}
              {/*      }*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</Flex>*/}
              {/*<Flex*/}
              {/*  justifyContent="space-between"*/}
              {/*  alignItems="center"*/}
              {/*  mb="24px"*/}
              {/*>*/}
              {/*  <Text fontSize="md" fontWeight="600" mb="4px">*/}
              {/*    Dark/Light*/}
              {/*  </Text>*/}
              {/*  <Button*/}
              {/*    onClick={toggleColorMode}*/}
              {/*    color={colorMode === "light" ? "Dark" : "Light"}*/}
              {/*  >*/}
              {/*    Toggle {colorMode === "light" ? "Dark" : "Light"}*/}
              {/*  </Button>*/}
              {/*</Flex>*/}
              {/*<HSeparator />*/}
              <Flex flexDirection="column">
                <Center>
                  <Text m="10px" width="full">{t("language") + ":"}</Text>
                </Center>
                <Select
                    value={i18n.language}
                    onChange={(e) => {
                      let prefs = {};
                      prefs['lang'] = e.target.value;
                      updateUserPreferencesRequest(prefs);
                      localStorage.setItem("lang", e.target.value);
                      console.log("set localStorage lang=", e.target.value);
                      i18n.changeLanguage(e.target.value);
                    }}
                    display={'block'}>
                  <option value='en'>English</option>
                  <option value='cn'>中文</option>
                </Select>
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
