import React, {useEffect, useState} from "react";
import {Box, Button, Center, Divider, Flex, Grid, Heading, Link, SimpleGrid, Text, VStack} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {useGetBalanceQuery, useGetPurchaseInfoQuery, useGetUserPreferencesQuery} from "../services/cmApi";
import {LineItemCard} from "./LineItemCard";
import WaitDontGoAlert from "./Dialogs/WaitDontGoAlert"
import {useHistory} from "react-router-dom";


const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;

// const Purchase = (price_id) => {
//     window.location.href = API_URL + "/api/start_checkout?user_id=" + AuthService.getCurrentUser()['user_id'] + "&price_id=" + price_id
// }
//
// const LineItemCard = (props) => {
//     const {t, i18n} = useTranslation();
//
//     const item = props.item;
//     return (
//         <Flex flexDirection="column" minWidth="80%">
//             <Card bgColor={item.promoted? '#F8F4DF': "white"} borderWidth='5px' borderColor={item.promoted? "#EDBC77": "#EBEBEB"} p="0">
//                 <CardBody>
//                     <VStack width="100%" spacing="10px">
//                         <Box m="15px"></Box>
//                         <Heading size='xl' color={item.promoted? "#744D08": "#454545"}>{t(item.display_name)}</Heading>
//                         <Text fontSize='6xl' fontWeight='bold' color={item.promoted? "#F96730": "#454545"} fontFamily="system-ui">
//                             {t('currency_symbol')}{item.amount}
//                         </Text>
//                         <Text fontSize='2xl' fontWeight='bold' color={item.promoted? "#B49B60": "#AAAAAA"} m="0px">
//                             <s>{t('currency_symbol')}{item.original_amount}</s>
//                         </Text>
//                         <Text color="#9B9489" fontSize='md'>{item.description1}</Text>
//                         <Box
//                             bgColor={item.promoted? "#F96730": "#EBEBEB"}
//                             borderBottomRadius="2xl"
//                             width="full" p="15px">
//                             <Center>
//                                 <Text color={item.promoted? "white": "#454545"} fontWeight='bold' fontSize='md'>
//                                     {item.description2}
//                                 </Text>
//                             </Center>
//                         </Box>
//                     </VStack>
//                 </CardBody>
//             </Card>
//             <Center mt="20px">
//                 <Button
//                     colorScheme="yellow"
//                     bgColor={item.promoted? "#F96730": "yellow.400"}
//                     color={item.promoted? "white": "black"}
//                     borderRadius='20px'
//                     width="50%"
//                     onClick={() => {
//                         Purchase(item.price_id)
//                     }}
//                 >
//                     {t('buy_now')}
//                 </Button>
//             </Center>
//         </Flex>
//
//     )
// }

const ProductDisplay = () => {
    const {t, i18n} = useTranslation();
    const {data: preferences, isLoading, isError, isSuccess} = useGetUserPreferencesQuery({});
    let purchase_info = useGetPurchaseInfoQuery({lang: i18n.language}, {skip: !isSuccess});
    let history = useHistory();
    const [nextUrl, setNextUrl] = useState('');
    const [isWaitDontGoAlertOpen, setWaitDontGoAlertOpen] = useState(false);
    let balance = useGetBalanceQuery({});

    useEffect(() => {
        const unblock = history.block((location) => {
            const pathUrl = location.pathname + location.search
            setNextUrl(pathUrl)
            const userBalance = (balance?.data && balance.data['balance']);
            if (!isWaitDontGoAlertOpen && userBalance <= 0) {
                setWaitDontGoAlertOpen(true);
            } else {
                return true
            }
            return false;
        })
        return () => {
            unblock();
        }
    }, [isWaitDontGoAlertOpen, balance])

    function onClose() {
        history.push(nextUrl)
    }

    function onConfirm() {
        history.push("/toefl/speaking")
    }

    return (
        <Box maxW='1600px' width="100%">
            <Flex flexDirection="column" w={"100%"}>
                <Box m="20px"></Box>
                <Center width="full">
                    <SimpleGrid
                        spacing={4}
                        templateColumns={{sm: "1fr", md: "1fr 1fr 1fr"}}
                        width="full"
                    >
                        {purchase_info.status === 'fulfilled' ? purchase_info.data.expiring_line_items.map(item =>
                            <Center><LineItemCard item={item}></LineItemCard></Center>) : <></>}
                    </SimpleGrid>
                </Center>
                <Box my="20px"></Box>
                <Center width="full">
                    <SimpleGrid
                        spacing={4}
                        templateColumns={{sm: "1fr", md: "1fr 1fr 1fr"}}
                        width="full"
                    >
                        {purchase_info.status === 'fulfilled' ? purchase_info.data.non_recurring_line_items.map(item =>
                            <Center><LineItemCard item={item}></LineItemCard></Center>) : <></>}
                    </SimpleGrid>
                </Center>
                <Box mt="20px"></Box>
                <Box display={i18n.language == "en" ? "none" : "block"}>
                    <Divider borderColor={'black'}/>
                    <Center mt={'20px'}><Heading mt={'0px'} bgColor={"#f8c400"}
                                                 size={'md'}>{t('recommended_readings')}</Heading></Center>
                    <Center>
                        <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                            <Flex flexDirection="column" bgColor={'#fcf6d9'} m="20px" p="20px" maxWidth="400px"
                                  height="100%">
                                <Heading size='sm'>算一算你需要多少积分？（积分规则）</Heading>
                                <Text mt="20px" flex="1">结合自身实际备考情况，科学测算积分消耗</Text>
                                <Box flex="1"></Box>
                                <Button borderRadius='20px' colorScheme='yellow'><Link
                                    href="https://blog.lingoleap.ai/zh/read/points/"
                                    target="_blank">点击阅读</Link></Button>
                            </Flex>
                            <Flex flexDirection="column" bgColor={'#fcf6d9'} m="20px" p="20px" maxWidth="400px"
                                  height="100%">
                                <Heading size='sm'>Lingoleap写作/口语提分攻略</Heading>
                                <Text mt="20px" flex="1">手把手教你托福写作/口语如何升分</Text>
                                <Box flex="1"></Box>
                                <Button borderRadius='20px' colorScheme='yellow'><Link
                                    href='https://blog.lingoleap.ai/zh/read/plan/'
                                    target="_blank">点击阅读</Link></Button>
                            </Flex>
                        </Grid>
                    </Center>
                </Box>
            </Flex>
            <WaitDontGoAlert isOpen={isWaitDontGoAlertOpen} onClose={onClose} onConfirm={onConfirm}/>
        </Box>
    )
};

const Message = ({message}) => (
    <section>
        <p>{message}</p>
    </section>
);

export function PurchasePanel(props) {
    const [message, setMessage] = useState("");

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        setMessage(null);

        if (query.get("success")) {
            setMessage("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, []);
    return message ? (
        <Flex>
            <Center spacing="10px">
                <Message message={message}/>
            </Center>
        </Flex>
    ) : (
        <VStack spacing={"10px"}>
            <Flex w={"80%"} width="100%">
                <ProductDisplay/>
            </Flex>
        </VStack>
    );
}