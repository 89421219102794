import {Box, Center, CircularProgress, CircularProgressLabel, Spinner} from "@chakra-ui/react";
import "assets/css/Markdown.css";
import {useEffect, useState} from "react";
import ReactAudioPlayer from "react-audio-player";
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import "../index.css";
import {selectGrading} from "../redux/shareSlice";
import {selectToeflData, updateToelfGid} from "../redux/toeflSlice";
import {useGetGradingQuery, useUpdateSharingStatusMutation} from "../services/cmApi";
import ConversationTable from "./ConversationTable";
import ResponseFeedBack from "./ResponseFeedBack";
import {ShareResultButton} from "./ShareResultButton";
import './styles.css';

const initialContent = `
  <div id="content-1">Testing, testing.</div>
  <div id="content-2"><mark data-id="correction-1">Highlighted part that has a correction.</mark></div>
  <div id="content-3">Another paragraph.</div>
`;

const corrections = [
    {
        id: 'correction-1',
        original: 'Testing, testing.',
        revised: 'Testing, testing',
        reason: 'Removed the period at the end as it is not necessary for a repeated phrase.',
        type: 'punctuation',
    }
];

export function CardObj({color, desc, score, heading}) {
    return (
        <div className="rounded-xl bg-gray-100 border-2 border-solid-[#000000] p-3 min-h-96">
            <div className="flex items-center justify-between">
                <h2 className="text-2xl font-extrabold">
                    {heading}
                </h2>
                <div className="relative flex items-center justify-center w-20 h-20">
                    <CircularProgress value={score} color={color} size="80px">
                        <CircularProgressLabel

                            fill={`${color}.500`}
                            color={`${color}.500`}
                            fontWeight="bold"
                            fontSize="3xl"
                        >
                            <div className={`text-${color}-200`}>{score}</div>
                        </CircularProgressLabel>
                    </CircularProgress>
                </div>
            </div>
            <div className="mt-4">
                <div>{desc}</div>
            </div>
        </div>
    );
}


export function ViewAppResultPanel(props) {
    const {t, i18n} = useTranslation();

    const [linkCopied, setLinkCopied] = useState(false);
    const grading = useSelector(selectGrading);
    const toeflData = useSelector(selectToeflData);
    const gid = props.gid || toeflData.gid;
    const gradingData = props.gradingData || null;
    const dispatch = useDispatch();

    // State for revised essay, keywords, and loading state
    const [revisedEssay, setRevisedEssay] = useState("");
    const [keywords, setKeywords] = useState([]);
    const [additonalExamples, setAdditionalExamples] = useState([]);
    const [mindmap, setMindmap] = useState({});
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(true);  // Added loading state
    const [content, setContent] = useState("");
    const [corrections, setCorrections] = useState([]);
    const [subscoreData, setSubscoreData] = useState([]);
    const [correctionCounts, setCorrectionCounts] = useState([]);
    const [totalScore, setTotalScore] = useState(0);
    const [exampleAudio, setExampleAudio] = useState(null);
    const [selectedCorrection, setSelectedCorrection] = useState(null);
    const [nWords, setNWords] = useState(0);
    const [nSentences, setNSentences] = useState(0);

    // const editor = new Editor({
    //     content: initialContent,
    //     extensions: [
    //         Doc,
    //         Paragraph,
    //         Text,
    //         Highlight
    //     ],
    //     editorProps: {
    //         attributes: {
    //             class: 'tiptap'
    //         }
    //     }
    // });

    // const editorRef = useRef(editor);

    const handleHighlightClick = (correction) => {
        // if (correction) {
        //     alert(`Correction: ${correction.reason}\nOriginal: ${correction.original}\nRevised: ${correction.revised}`);
        // }
        setSelectedCorrection(correction);
    };

    const handleContentClick = (event) => {
        if (event.target.classList.contains('correction')) {
            const correctionIndex = event.target.getAttribute('data-correction-index');
            const correction = corrections[correctionIndex];
            handleHighlightClick(correction);
        }
    };
    const applyCorrections = (content, corrections) => {
        let updatedContent = content;

        corrections.forEach((correction, index) => {
            const regex = new RegExp(correction.original, 'g');
            updatedContent = updatedContent.replace(regex, match => {
                return `<span 
                className="correction" 
                style="text-decoration: underline; color: red; font-weight: bold; cursor: pointer;" 
                data-correction-index="${index}"
            >${match}</span>`;
            });
        });

        return updatedContent;
    };

    // useEffect(() => {
    //     if (editorRef.current) {
    //         editorRef.current.setOptions({
    //             editor: editorRef.current,
    //             onClick: handleHighlightClick,
    //         });
    //     }
    // }, [editor]);
    //
    // if (!editor) {
    //     return null;
    // }

    useEffect(() => {
        dispatch(updateToelfGid(gid));
    }, [gid]);

    const [updateSharingStatusRequest, isUpdateSharingStatusError] = useUpdateSharingStatusMutation();
    let grading_result = gradingData || useGetGradingQuery({gid: gid});
    const isOwner = (grading_result.status === 'fulfilled' && grading_result.data.data['is_owner']);

    const formatCategoryName = (category) => {
        if (typeof category !== 'string') {
            console.error(`Invalid category type: ${typeof category}. Expected string.`);
            return ''; // 返回空字符串作为默认值
        }
        return category
            .replace(/_/g, ' ') // Replace underscores with spaces
            .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
    };
    const getRandomColor = () => {
        const colors = ['#ff0000', '#00ff00', '#0000ff']; // Red, Green, Blue
        const randomIndex = Math.floor(Math.random() * colors.length);
        return colors[randomIndex];
    };

    useEffect(() => {
        console.log('grading_result', grading_result)
        if (grading_result?.data?.data) {
            try {
                // Assuming grading_result.data.data.result is a JSON string of an array
                const jsonStrings = grading_result.data.data.result.split('\n').filter(Boolean);
                const parsedData = jsonStrings.map(jsonStr => {
                    try {
                        return JSON.parse(jsonStr);
                    } catch (parseError) {
                        console.error('Error parsing JSON string:', parseError);
                        return null;
                    }
                }).filter(Boolean);

                // Extract the relevant content from the parsed data
                const content = parsedData.find(item => item.content)?.content || "";
                const revisedAnswer = parsedData.find(item => item.revised_answer)?.revised_answer || "";
                const keywords = parsedData.find(item => item.keywords)?.keywords || [];
                const additionalExamples = parsedData.find(item => item.additional_examples)?.additional_examples || [];
                const mindmap = parsedData.find(item => item.mindmap)?.mindmap || {};
                const corrections = parsedData.find(item => item.corrections)?.corrections || []; // Extract corrections
                const subscoreCategoryData = parsedData.find(item => item.subscore_catetories)?.subscore_catetories || [];
                const totalScore = parsedData.find(item => item.total_score)?.total_score || 0;
                const nWordsV = parsedData.find(item => item.n_words)?.n_words || 0;
                const nSentencesV = parsedData.find(item => item.n_sentences)?.n_sentences || 0;
                const exampleAudioSrc = parsedData.find(item => item.example_audio)?.example_audio || null;
                // Map through subscore categories to format the data
                const categoryDetails = subscoreCategoryData.map(category => {
                    const formattedCategory = formatCategoryName(category);
                    const categoryInfo = parsedData.find(item => item[category] !== undefined);
                    return {
                        category: formattedCategory,
                        score: categoryInfo ? categoryInfo[category] : null,
                        description: categoryInfo ? categoryInfo[`${category}_description`] : null,
                        color: getRandomColor(),
                    };
                });

                // Store corrections and count the types as array of objects
                const correctionCountsArray = [];
                corrections.forEach(correction => {
                    const existingCorrection = correctionCountsArray.find(item => item.type === correction.type);
                    if (existingCorrection) {
                        existingCorrection.count++;
                    } else {
                        correctionCountsArray.push({
                            type: formatCategoryName(correction.type),
                            count: 1,
                            color: getRandomColor()
                        });
                    }
                });

                // Set the necessary states
                setSubscoreData(categoryDetails);
                setRevisedEssay(revisedAnswer);
                setKeywords(keywords);
                setContent(content);
                setAdditionalExamples(additionalExamples);
                setMindmap(mindmap);
                setCorrections(corrections); // Set corrections state
                setCorrectionCounts(correctionCountsArray); // Set the correction counts state as an array of objects
                setResult(parsedData);
                setTotalScore(totalScore);
                setExampleAudio(exampleAudioSrc)
                setNWords(nWordsV)
                setNSentences(nSentencesV)
                console.log(exampleAudioSrc, "exampleAudioSrc")
            } catch (error) {
                console.error('Error processing grading result:', error);
            }
        }
    }, [grading_result, revisedEssay]);


    const updatedContent = applyCorrections(content, corrections);

    console.log(result)
    console.log(revisedEssay, "answer");

    console.log(keywords, "keywords")
    console.log(additonalExamples, "additonal");

    console.log(content, "content")
    console.log(result);

    console.log(subscoreData, "subscore")
    console.log(corrections, "corrections")

    console.log(correctionCounts, "count");
    console.log(totalScore, "totalScore")

    console.log(exampleAudio, "exampleAudio")

    const getField = (fieldName) => {
        if (grading_result.status === 'fulfilled') {
            return grading_result.data.data[fieldName];
        } else {
            return "";
        }
    }
    const getUserAudioSrc = () => {
        if (grading_result.status === 'fulfilled') {
            if ('user_audio_url' in grading_result.data.data) {
                return grading_result.data.data['user_audio_url']
            } else {
                return grading_result.data.data['user_audio'];
            }
        } else {
            return "";
        }
    }
    const getSpeakingQuestionAnswerList = () => {
        if (grading_result.status === 'fulfilled') {
            if ('speaking_question_answer_list' in grading_result.data.data) {
                return grading_result.data.data['speaking_question_answer_list']
            } else {
                return []
            }
        } else {
            return [];
        }
    }

    const getTime = () => {
        var date = new Date(grading['created_at'] * 1000);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var date = date.getDate();
        return year + "-" + month + "-" + date;
    }

    const getType = () => {
        if (grading_result.status === 'fulfilled') {
            const gen_type = grading_result.data.data['gen_type'].split("_");
            let part1 = "";
            let part2 = "";
            if (gen_type[0] == 'speaking') {
                part1 = t("speaking");
            } else {
                part1 = t("writing");
            }

            if (gen_type[1] == "generation") {
                part2 = t("corpus_accumulation");
            } else {
                part2 = gen_type[1].replace("task", t("task"));
            }

            return part1 + part2;
        } else {
            return "";
        }
    }

    const getGenType = () => {
        if (grading_result.status === 'fulfilled') {
            return grading_result.data.data['gen_type'];
        } else {
            return "";
        }
    }

    const isFieldVisible = (fieldName) => {
        return fieldName in grading && grading[fieldName];
    }

    const getBox = (fieldName) => {
        let title = fieldName;
        if (fieldName == 'content') {
            title = t("your_answer")
        } else if (fieldName == 'topic') {
            title = t('question_text')
        } else if (fieldName == 'target_score') {
            title = t('target_score')
        } else if (fieldName == 'outline') {
            title = t('outline')
        } else if (fieldName == 'listening') {
            title = t('listening_text')
        } else if (fieldName == 'reading') {
            title = t('reading_text')
        } else if (fieldName == 'conversations') {
            title = t('writing_discussion')
        }

        return (

            fieldName === 'conversations' ? (
                <ConversationTable dataType={'grading'}/>
            ) : getField(fieldName)
        );
    };


    const getResult = (fieldName) => {
        return <>
            <Box mt={10} p={5} bg='white'
                 boxShadow="0 2px 12px 0 rgb(0 0 0 / 16%)"
                 borderRadius={20}
                 display={isFieldVisible(fieldName) ? "block" : "none"}>
                <Center>
                    <Box m="20px" overflowX="auto">
                        {/* <ReactMarkdown
                            children={getField(fieldName)} remarkPlugins={[gfm]}
                            rehypePlugins={[rehypeRaw]} className="ll-markdown"
                            components={ChakraMarkdownComponents}
                        /> */}

                        {/* {toeflData.content.message1 && <EssayScore />}
                        (
                        <EssayGeneration gid={gid} />
                        ) */}
                        "Hi"
                    </Box>
                </Center>
            </Box>
        </>;
    };

    const capitalizeFirstLetterOfEachWord = (str) => {
        return str.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    };

    useEffect(() => {
        console.log('Keywords:', keywords);
    }, [keywords]);


    return (
        <div className="p-3 bg-gray-200 w-screen mt-[-10px] font-light">
            {grading_result.status === "pending" && <div>
                <div className="flex justify-center items-center">
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="lg"
                    />
                </div>
            </div>}

            <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-x-3 justify-center items-center">
                    <div className="font-bold text-xl">
                        {getType()}
                    </div>
                    <div className="p-2 bg-gray-400 text-center rounded-md">
                        {getTime()}
                    </div>
                    <div className="p-2 bg-yellow-100 text-center rounded-md">
                        {getGenType() == 'writing_task3' ? t('question_space') : 'TPO '}{grading.tpo}
                    </div>
                    <div className="p-2 bg-green-100 text-center rounded-md">
                        {t("Score")}：{grading.estimated_score}
                    </div>
                    {grading.is_admin_privileged && <div className="bg-sky-100 font-bold text-center rounded-md">
                        Admin Priveledged
                    </div>}
                </div>
                <div>
                    <ShareResultButton gid={gid}
                                       visible={grading.is_admin_privileged || (grading_result.status === 'fulfilled' && grading_result.data.data['is_owner'])}></ShareResultButton>
                </div>
            </div>

            <div className="mt-4 flex flex-row gap-x-5">
                {/* this one for questions, audio etc... */}
                <div className="flex flex-col w-3/4">
                    {/* question div */}

                    <div className="space-y-3 bg-white p-4 h-fit rounded-xl">
                        <h2 className="font-bold text-xl">Question</h2>
                        <div>{getBox('topic')}</div>

                        {getBox('listening') && (
                            <div>
                                <h2 className="font-bold text-xl">Listening</h2>
                                {getBox('listening')}
                            </div>
                        )}

                        {getBox('reading') && (
                            <div>
                                <h2 className="font-bold text-xl">Reading</h2>
                                {getBox('reading')}
                            </div>
                        )}

                        {getBox('conversations') && (<div>
                            <h2 className="font-bold text-xl">Writing Discussion</h2>
                            {getBox('conversations')}
                        </div>)}

                        {/*{(isFieldVisible("example_audio_url") || isFieldVisible("example_audio")) && (*/}
                        <h2 className="font-bold text-xl">Your Answer</h2>
                        <div>
                            <ReactAudioPlayer
                                src={getUserAudioSrc()}
                                controls
                            />
                        </div>
                        {/*// )}*/}

                    </div>
                    <div className="flex flex-row gap-x-3 mt-4">
                        {/* transcript */}
                        <div className="bg-white w-1/2 p-4 rounded-xl flex flex-col space-y-4">
                            <div className="flex flex-row gap-x-3 items-center">
                                <h2 className="font-bold text-xl">Transcript</h2>
                                <div className="flex flex-row gap-x-3 items-center ">
                                    <div className="rounded-full p-1 bg-gray-200 text-blue-800">{nWords}</div>
                                    <div>words</div>
                                </div>
                                <div className="flex flex-row gap-x-3 items-center ">
                                    <div className="rounded-full p-1 bg-gray-200 text-blue-800">{nSentences}</div>
                                    <div>sentences</div>
                                </div>
                            </div>
                            <div className="flex flex-row gap-x-3 overflow-scroll">
                                {correctionCounts.map((item, index) => (
                                    <div
                                        key={index}
                                        className="rounded-full p-1 bg-gray-200 flex flex-row items-center
                                        space-x-1 border-2 border-gray-400 text-sm "
                                    >
                                        <div className={`text-${item.color}-500 font-semibold`}>{item.count}</div>
                                        <div>{item.type}</div>
                                    </div>
                                ))}
                            </div>
                            <div className=" h-96 overflow-scroll"
                                 dangerouslySetInnerHTML={{__html: updatedContent}}
                                 onClick={handleContentClick}
                            />

                        </div>
                        {/* comments */}
                        <div className="bg-white w-1/2 p-4 rounded-xl flex flex-col space-y-4">
                            <div className="flex flex-row gap-x-3">
                                <div className="font-bold text-xl">All Comments</div>
                                <div className="rounded-full p-1 bg-gray-200 text-blue-800">{corrections.length}</div>
                            </div>
                            <div className="max-h-96 h-fit overflow-scroll">
                                {corrections?.map((item, index) => (
                                    <div key={index}>
                                        <div
                                            className="bg-gray-100 rounded-xl font-bold border-l-4 border-solid border-green-600 p-3 flex items-center cursor-pointer"
                                            onClick={() => handleHighlightClick(item)}
                                        >
                                            {item?.reason}
                                        </div>
                                        {selectedCorrection === item && (
                                            <div className="bg-gray-50 p-3 mt-2 rounded-xl">
                                                <div className="mb-2" style={{color: 'red'}}>Original: {item.original}</div>
                                                <div>Revised: {item.revised}</div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>

                    <div className="flex flex-row gap-x-3 mt-4">
                        {/* transcript */}
                        <div className="bg-white w-1/2 p-4 rounded-xl space-y-3 flex flex-col">
                            <h2 className="font-bold text-xl">Revised Essay</h2>
                            <div className="h-96 overflow-scroll">{revisedEssay}</div>
                        </div>
                        {/* comments */}
                        <div className="bg-white w-1/2 p-4 rounded-xl flex flex-col">

                            <div className="font-bold text-xl">Mindmap</div>
                            <div>
                                {Object.entries(mindmap).map(([key, value]) => (
                                    <section key={key}>
                                        <h2 style={{fontFamily: 'monospace', marginBottom: '1rem'}}>
                                            {capitalizeFirstLetterOfEachWord(key)}
                                        </h2>
                                        {typeof value === 'string' ? (
                                            <div>{value}</div>
                                        ) : (
                                            <ul>
                                                {Object.entries(value).map(([subKey, subValue]) => (
                                                    <li key={subKey} style={{marginBottom: '2rem'}}>
                                                        <h3 style={{
                                                            fontFamily: 'monospace',
                                                            marginTop: '1rem',
                                                            marginBottom: '0.5rem'
                                                        }}>
                                                            {capitalizeFirstLetterOfEachWord(subKey)}
                                                        </h3>
                                                        {Array.isArray(subValue) ? (
                                                            <ul>
                                                                {subValue.map((item, index) => (
                                                                    <li key={index}>
                                                                        {typeof item === 'object' ? JSON.stringify(item) : item}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            typeof subValue === 'object' && subValue !== null ? (
                                                                <ul>
                                                                    {Object.entries(subValue).map(([nestedKey, nestedValue]) => (
                                                                        <li key={nestedKey}>
                                                                            <h4 style={{
                                                                                fontFamily: 'monospace',
                                                                                marginTop: '0.5rem',
                                                                                marginBottom: '0.2rem'
                                                                            }}>
                                                                                {capitalizeFirstLetterOfEachWord(nestedKey)}
                                                                            </h4>
                                                                            {Array.isArray(nestedValue) ? (
                                                                                <ul>
                                                                                    {nestedValue.map((nestedItem, nestedIndex) => (
                                                                                        <li key={nestedIndex}>{nestedItem}</li>
                                                                                    ))}
                                                                                </ul>
                                                                            ) : (
                                                                                <div>{nestedValue}</div>
                                                                            )}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            ) : (
                                                                <div>{subValue}</div>
                                                            )
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </section>
                                ))}

                                {/*<Tree>*/}
                                {/*    <Tree.Folder name="client">*/}
                                {/*        <Tree.Folder name="Components">*/}
                                {/*            <Tree.File name="Button.jsx" />*/}
                                {/*            <Tree.File name="Button.style.js" />*/}
                                {/*        </Tree.Folder>*/}
                                {/*        <Tree.File name="setup.js" />*/}
                                {/*        <Tree.Folder name="client">*/}
                                {/*            <Tree.Folder name="Components">*/}
                                {/*                <Tree.File name="Button.jsx" />*/}
                                {/*                <Tree.File name="Button.style.js" />*/}
                                {/*            </Tree.Folder>*/}
                                {/*            <Tree.File name="setup.js" />*/}
                                {/*        </Tree.Folder>*/}
                                {/*    </Tree.Folder>*/}
                                {/*    <Tree.File name="index." />*/}
                                {/*    <Tree.File name="style.css" />*/}
                                {/*    <Tree.File name="style.css" />*/}
                                {/*    <Tree.File name="style.css" />*/}
                                {/*</Tree>*/}

                            </div>
                        </div>
                    </div>

                    <div className="bg-white p-4 rounded-xl mt-4 flex flex-col">
                        <div className="font-bold text-xl">Keywords</div>
                        <div variant="striped" colorscheme="blue" size="sm"
                             className="mt-3 w-full h-96 overflow-scroll">
                            <table variant="striped" colorscheme="blue" size="sm" className="w-full">
                                <thead>
                                <tr>
                                    <th className="border-b px-4 py-2">WORD</th>
                                    <th className="border-b px-4 py-2">PHONETIC SYMBOL</th>
                                    <th className="border-b px-4 py-2">PART OF SPEECH</th>
                                    <th className="border-b px-4 py-2">ENGLISH DEFINITION</th>
                                    <th className="border-b px-4 py-2">SAMPLE SENTENCE</th>
                                </tr>
                                </thead>
                                <tbody>
                                {keywords.map((keyword, index) => (
                                    <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}>
                                        <td className="border-b px-4 py-2">{keyword?.word}</td>
                                        <td className="border-b px-4 py-2">{keyword?.phonetic_symbol}</td>
                                        <td className="border-b px-4 py-2">{keyword?.part_of_speech}</td>
                                        <td className="border-b px-4 py-2">{keyword?.definition}</td>
                                        <td className="border-b px-4 py-2">
                                            {typeof keyword?.sample_sentence === 'object'
                                                ? JSON.stringify(keyword?.sample_sentence)
                                                : keyword?.sample_sentence}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="bg-white p-4 rounded-xl mt-4 mb-4 flex flex-col">
                        <div className="font-bold text-xl">Additional Examples</div>
                        <table className="mt-3 w-full">
                            <tbody className="space-y-3 h-96 overflow-scroll">
                            {additonalExamples.map((example, index) => (
                                <tr key={index} className="rounded-xl p-2 w-fit h-fit bg-gray-100">
                                    <td>{`${index + 1}. ${example}`}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {(exampleAudio) && (
                            <div className="mt-4">
                                <h2 className="font-bold text-xl mb-4">Example Audio</h2>
                                <div>
                                    <ReactAudioPlayer
                                        src={exampleAudio}
                                        controls
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-1/4 bg-white rounded-xl flex flex-col p-3 space-y-10 mb-4">
                    <div>
                        <div className="w-100 p-6">
                            <div className="flex flex-col justify-center items-center">
                                <h1 className="text-blue-900 text-8xl font-bold">
                                    {grading.estimated_score}
                                </h1>
                                <div className="mt-3">Overall Brand Score</div>
                            </div>
                        </div>

                    </div>
                    {
                        subscoreData.map((item, index) => (
                            <CardObj
                                key={index}
                                color={item.color}
                                desc={item.description}
                                score={item.score}
                                heading={item.category}
                            />
                        ))
                    }
                </div>
            </div>
            {/* this one for scores */}
            <div className="mt-4 text-center space-y-3 font-bold text-xl">
                <div>Feedback</div>
                <ResponseFeedBack gid={gid}></ResponseFeedBack>
            </div>

        </div>


    );
}

const structure = [
    {
        type: "folder",
        name: "client",
        files: [
            {
                type: "folder",
                name: "ui",
                files: [
                    {type: "file", name: "Toggle.js"},
                    {type: "file", name: "Button.js"},
                    {type: "file", name: "Button.style.js"}
                ]
            },
            {
                type: "folder",
                name: "components",
                files: [
                    {type: "file", name: "Tree.js"},
                    {type: "file", name: "Tree.style.js"}
                ]
            },
            {type: "file", name: "setup.js"},
            {type: "file", name: "setupTests.js"}
        ]
    },
    {
        type: "folder",
        name: "packages",
        files: [
            {
                type: "file",
                name: "main.js"
            }
        ]
    },
    {type: "file", name: "index.js"}
];