import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Tab,
    TabList,
    Tabs,
    Text,
    useColorModeValue,
    useDisclosure,
    useToast,
    Select,
    Checkbox,
    Stack,
    HStack
} from "@chakra-ui/react";

import CardHeader from "./Card/CardHeader";
import CardBody from "./Card/CardBody";
import Card from "./Card/Card";
import { useDispatch, useSelector } from "react-redux";
import {
    clearQuestionInputs,
    loadQuestionInputs,
    selectBundleId,
    selectBundleStatus,
    selectQuestionInputs,
    selectTest,
    updateQuestionInputs,
    updateTest,
    deleteQuestionInputs,
    setQuestionInputs,
} from "../redux/adminSlice";
import ReactAudioPlayer from "react-audio-player";
import { Textarea } from "@chakra-ui/textarea";
import { useTranslation } from 'react-i18next';

import {
    useGetPracticeBundlesQuery,
    useGetQuestionsByQuestionSetQuery,
    useUpdateQuestionBundleStatusMutation,
    useUploadPracticeBundleMutation
} from "../services/cmApi";
import authHeader from "../services/auth-header";
import axios from "axios";
import AdminAddTags from "./AdminAddTags";
import QuestionList from "QuestionList";
import { extractSummary } from "./utils/common";
function TextInputRow(props) {
    const { displayName, keyName, filterProduct, onlyOneRow, children, ...rest } = props;
    const questionInputs = useSelector(selectQuestionInputs);
    const dispatch = useDispatch();

    const getValue = () => {
        var result = questionInputs[keyName];
        return (typeof result !== "undefined") ? result : "";
    }

    return <Flex mt="20px" flexDirection={onlyOneRow ? "row" : "column"}>
        <Flex>
            <Center>
                <Text fontWeight="bold" minWidth="120px">
                    {displayName}
                </Text>
            </Center>
        </Flex>
        <Box m="10px"></Box>
        {onlyOneRow ?
            <Center width={"full"}>
                <Input type="text" value={getValue()} backgroundColor="white"
                    onChange={(evt) => {
                        dispatch(updateQuestionInputs({
                            key: keyName,
                            value: evt.target.value
                        }));
                    }} />
            </Center> :
            <Center>
                <Textarea
                    onChange={(evt) => {
                        dispatch(updateQuestionInputs({
                            key: keyName,
                            value: evt.target.value
                        }));
                    }} value={getValue()}
                    height="200px"
                    spellcheck="false"
                ></Textarea>
            </Center>
        }
    </Flex>;
}

function AudioInputRow(props) {
    const { displayName, keyName, filterProduct, children, ...rest } = props;
    const questionInputs = useSelector(selectQuestionInputs);
    const dispatch = useDispatch();

    const handleUpload = (e) => {
        const reader = new FileReader();
        let file = e.target.files[0]
        if (!file) {
            return;
        }
        reader.readAsDataURL(file);

        reader.onloadend = async () => {
            dispatch(updateQuestionInputs({
                key: keyName,
                value: reader.result
            }));
        };
    };

    const getAudioSrc = (data) => {
        if (data && data.url) {
            return data.url;
        } else {
            return data;
        }
    }

    return <Flex mt="20px">
        <Center>
            <Text fontWeight="bold">
                {displayName}
            </Text>
        </Center>

        <input id={keyName + 'file'} type="file" accept=".m4a,audio/*" onChange={handleUpload} rounded={'full'} style={{ display: 'none' }}
        />
        <Box mx="10px"></Box>
        <Center>
            <Button
                fontSize={'sm'}
                rounded={'full'}
                bg={'blue.400'}
                color={'white'}
                _hover={{
                    bg: 'blue.500',
                }}
                _focus={{
                    bg: 'blue.500',
                }}
                minWidth={120}
                onClick={() => {
                    const fileInput = document.getElementById(keyName + 'file');
                    fileInput.click();
                }
                }
            >上传录音</Button>
        </Center>
        <Box mx="10px"></Box>
        <Center>
            <ReactAudioPlayer
                src={getAudioSrc(questionInputs[keyName])}
                controls={getAudioSrc(questionInputs[keyName])}
            />
        </Center>
    </Flex>;
}

export function AdminToeflManagementPanel(props) {
    const { t, i18n } = useTranslation();
    const textColor = useColorModeValue("gray.700", "white");
    const toast = useToast()
    const [tabIndex, setTabIndex] = useState("");
    const [updateBundleRequest, isUpdateBundleRequestError] = useUploadPracticeBundleMutation();
    const [updateQuestionBundleStatusRequest, isUpdateQuestionBundleStatusRequestError] = useUpdateQuestionBundleStatusMutation();
    const questionInputs = useSelector(selectQuestionInputs);
    const bundleId = useSelector(selectBundleId);
    const bundleStatus = useSelector(selectBundleStatus);
    const test = useSelector(selectTest);
    console.log("bundleStatus", bundleStatus)
    let question_bundles = useGetPracticeBundlesQuery({});
    const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure();


    return (
        <>

            <Card
                overflowX={{ sm: "scroll", xl: "hidden" }}
            >
                <CardHeader p="6px 0px 22px 0px">
                    <Flex direction="column">
                        <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
                            录入套题
                        </Text>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <Flex flexDirection="column">
                        <Flex>
                            <Button colorScheme='blue' onClick={onOpen}>导入</Button>
                            <Box mx='10px'></Box>
                            <Button colorScheme='blue' onClick={() => {
                                dispatch(clearQuestionInputs())
                            }}>清空</Button>
                        </Flex>
                        <Box>
                            <TextInputRow displayName='套题名字' keyName='bundleName' onlyOneRow={true}></TextInputRow>
                            <Modal isOpen={isOpen} onClose={onClose}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>选择套题</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody >
                                        {question_bundles.status === 'fulfilled' && (
                                            <ul>
                                                {question_bundles.data.data.map((item) => (
                                                    <li key={item.bundle_id}>
                                                        {item.test} | {item.bundle_id}: {item.bundle_name}
                                                        <Button
                                                            ml="10px"
                                                            bg={'blue.400'} color={'white'}
                                                            onClick={async () => {
                                                                let headers = authHeader();
                                                                const response = await axios.get(API_URL + `/api/admin_get_question_bundle?bundle_id=` + item.bundle_id, { headers: headers });
                                                                if (response.data.status === 'success') {
                                                                    dispatch(loadQuestionInputs(response.data.data))
                                                                }
                                                            }}
                                                        >
                                                            Load
                                                        </Button>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </ModalBody>
                                </ModalContent>
                            </Modal>

                        </Box>

                        <Box>
                            <Box borderWidth='2px' rounded="20px" mt="20px" >
                                <Flex mt="30px">
                                    <Text fontSize='xl' fontWeight='bold' ml="20px">套题: {bundleId}</Text>
                                </Flex>
                                <Center mt="30px">
                                    <Tabs width="100%" index={tabIndex} onChange={(index) => {
                                        setTabIndex(index);
                                    }}>
                                        <TabList>
                                            <Tab px={{ base: "5px", md: "16px" }}>
                                                <Text fontSize='xl' fontWeight='bold'>口语任务1</Text>
                                            </Tab>
                                            <Tab px={{ base: "5px", md: "16px" }}>
                                                <Text fontSize='xl' fontWeight='bold'>口语任务2</Text>
                                            </Tab>
                                            <Tab px={{ base: "5px", md: "16px" }}>
                                                <Text fontSize='xl' fontWeight='bold'>口语任务3</Text>
                                            </Tab>
                                            <Tab px={{ base: "5px", md: "16px" }}>
                                                <Text fontSize='xl' fontWeight='bold'>口语任务4</Text>
                                            </Tab>
                                            <Tab px={{ base: "5px", md: "16px" }}>
                                                <Text fontSize='xl' fontWeight='bold'>写作任务1</Text>
                                            </Tab>
                                            <Tab px={{ base: "5px", md: "16px" }}>
                                                <Text fontSize='xl' fontWeight='bold'>写作任务3</Text>
                                            </Tab>
                                        </TabList>
                                    </Tabs>
                                </Center>
                                <Box p="20px" mt="10px" >
                                    <Box display={tabIndex == 0 ? 'block' : 'none'}>
                                        <TextInputRow displayName='口语任务1问题' keyName='speaking_task1_topic'></TextInputRow>
                                    </Box>
                                    <Box display={tabIndex == 1 ? 'block' : 'none'}>
                                        <TextInputRow displayName='口语任务2阅读' keyName='speaking_task2_reading'></TextInputRow>
                                        <TextInputRow displayName='口语任务2听力文本' keyName='speaking_task2_listening'></TextInputRow>
                                        <AudioInputRow displayName='口语任务2听力文件' keyName='speaking_task2_listening_audio'></AudioInputRow>
                                        <TextInputRow displayName='口语任务2问题' keyName='speaking_task2_topic'></TextInputRow>
                                    </Box>
                                    <Box display={tabIndex == 2 ? 'block' : 'none'}>
                                        <TextInputRow displayName='口语任务3阅读' keyName='speaking_task3_reading'></TextInputRow>
                                        <TextInputRow displayName='口语任务3听力文本' keyName='speaking_task3_listening'></TextInputRow>
                                        <AudioInputRow displayName='口语任务3听力文件' keyName='speaking_task3_listening_audio'></AudioInputRow>
                                        <TextInputRow displayName='口语任务3问题' keyName='speaking_task3_topic'></TextInputRow>
                                    </Box>
                                    <Box display={tabIndex == 3 ? 'block' : 'none'}>
                                        <TextInputRow displayName='口语任务4听力文本' keyName='speaking_task4_listening'></TextInputRow>
                                        <AudioInputRow displayName='口语任务4听力文件' keyName='speaking_task4_listening_audio'></AudioInputRow>
                                        <TextInputRow displayName='口语任务4问题' keyName='speaking_task4_topic'></TextInputRow>
                                    </Box>
                                    <Box display={tabIndex == 4 ? 'block' : 'none'}>
                                        <TextInputRow displayName='写作任务1阅读' keyName='writing_task1_reading'></TextInputRow>
                                        <TextInputRow displayName='写作任务1听力文本' keyName='writing_task1_listening'></TextInputRow>
                                        <AudioInputRow displayName='写作任务1听力文件' keyName='writing_task1_listening_audio'></AudioInputRow>
                                        <TextInputRow displayName='写作任务1问题' keyName='writing_task1_topic'></TextInputRow>
                                    </Box>
                                    <Box display={tabIndex == 5 ? 'block' : 'none'}>
                                        <TextInputRow displayName='写作任务3问题' keyName='writing_task3_topic'></TextInputRow>
                                        <TextInputRow displayName='学术讨论1名字' keyName='discussion1_name' onlyOneRow={true}></TextInputRow>
                                        <TextInputRow displayName='学术讨论1内容' keyName='discussion1_content'></TextInputRow>
                                        <TextInputRow displayName='学术讨论2名字' keyName='discussion2_name' onlyOneRow={true}></TextInputRow>
                                        <TextInputRow displayName='学术讨论2内容' keyName='discussion2_content'></TextInputRow>
                                        <TextInputRow displayName='学术讨论3名字' keyName='discussion3_name' onlyOneRow={true}></TextInputRow>
                                        <TextInputRow displayName='学术讨论3内容' keyName='discussion3_content'></TextInputRow>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box display={isUpdateBundleRequestError.status !== 'pending' ? 'block' : 'none'}>
                            <Flex mt="20px" flexDirection="row" gap={5}>
                                <Center>
                                    <Text>套题状态： {bundleStatus == 'PUBLISHED' ? "已发布" : "未发布"}</Text>
                                </Center>
                                <Center>
                                    <Button
                                        fontSize={'sm'}
                                        rounded={'full'}
                                        bg={'blue.400'}
                                        color={'white'}
                                        _hover={{
                                            bg: 'blue.500',
                                        }}
                                        _focus={{
                                            bg: 'blue.500',
                                        }}
                                        minWidth={120}
                                        onClick={() => {
                                            const targetStatus = bundleStatus == 'ACTIVE' ? `PUBLISHED` : "ACTIVE";
                                            updateQuestionBundleStatusRequest({
                                                bundle_id: bundleId,
                                                status: targetStatus
                                            }).then(function (response) {
                                                if (!response['data'] || response['data']['status'] !== 'success') {
                                                    let title = JSON.stringify(response);
                                                    if (response['error'] && response['error']['data'] && response['error']['data']['type'] === 'KeyError') {
                                                        title = response['error']['data']['message']
                                                    }
                                                    toast({
                                                        title: title,
                                                        status: "error",
                                                        duration: 10000,
                                                        isClosable: true,
                                                    });
                                                } else {
                                                    toast({
                                                        title: `成功更新套题状态 ${response.data.data.bundle_id} ${targetStatus}`,
                                                        status: "success",
                                                        duration: 10000,
                                                        isClosable: true,
                                                    });
                                                    dispatch(clearQuestionInputs())
                                                }
                                            }).catch(function (error) {
                                                toast({
                                                    title: JSON.stringify(error),
                                                    status: "error",
                                                    duration: 10000,
                                                    isClosable: true,
                                                });
                                            });
                                        }
                                        }
                                    >{bundleStatus == 'ACTIVE' ? `发布` : "取消发布"} </Button>
                                </Center>
                            </Flex>
                        </Box>
                        <Flex mt="20px" gap={5}>
                            <Center display={isUpdateBundleRequestError.status !== 'pending' ? 'block' : 'none'}>
                                <Button
                                    fontSize={'sm'}
                                    rounded={'full'}
                                    bg={'blue.400'}
                                    color={'white'}
                                    _hover={{
                                        bg: 'blue.500',
                                    }}
                                    _focus={{
                                        bg: 'blue.500',
                                    }}
                                    minWidth={120}
                                    onClick={() => {
                                        updateBundleRequest({
                                            bundle_id: bundleId,
                                            test: test,
                                            inputs: questionInputs
                                        }).then(function (response) {
                                            if (!response['data'] || response['data']['status'] !== 'success') {
                                                let title = JSON.stringify(response);
                                                if (response['error'] && response['error']['data'] && response['error']['data']['type'] === 'KeyError') {
                                                    title = response['error']['data']['message']
                                                }
                                                toast({
                                                    title: title,
                                                    status: "error",
                                                    duration: 10000,
                                                    isClosable: true,
                                                });
                                            } else {
                                                toast({
                                                    title: `成功上传套题 ${response.data.bundle_id}`,
                                                    status: "success",
                                                    duration: 10000,
                                                    isClosable: true,
                                                });
                                                dispatch(clearQuestionInputs())
                                            }
                                        }).catch(function (error) {
                                            toast({
                                                title: JSON.stringify(error),
                                                status: "error",
                                                duration: 10000,
                                                isClosable: true,
                                            });
                                        });
                                    }
                                    }
                                >{bundleId ? `更新套题 ${bundleId}` : "创建套题"} </Button>
                            </Center>
                            <Center display={isUpdateBundleRequestError.status !== 'pending' && bundleId ? 'block' : 'none'}>
                                <Button
                                    fontSize={'sm'}
                                    rounded={'full'}
                                    bg={'red.400'}
                                    color={'white'}
                                    _hover={{
                                        bg: 'blue.500',
                                    }}
                                    _focus={{
                                        bg: 'blue.500',
                                    }}
                                    minWidth={120}
                                    onClick={() => {
                                        updateQuestionBundleStatusRequest({ bundle_id: bundleId, status: 'DELETED' }).then(function (response) {
                                            if (!response['data'] || response['data']['status'] !== 'success') {
                                                let title = JSON.stringify(response);
                                                if (response['error'] && response['error']['data'] && response['error']['data']['type'] === 'KeyError') {
                                                    title = response['error']['data']['message']
                                                }
                                                toast({
                                                    title: title,
                                                    status: "error",
                                                    duration: 10000,
                                                    isClosable: true,
                                                });
                                            } else {
                                                toast({
                                                    title: `成功删除套题${bundleId}！`,
                                                    status: "info",
                                                    duration: 30000,
                                                    isClosable: true,
                                                });
                                                dispatch(clearQuestionInputs())
                                            }

                                        }).catch(function (error) {
                                            toast({
                                                title: JSON.stringify(error),
                                                status: "error",
                                                duration: 10000,
                                                isClosable: true,
                                            });
                                        });

                                    }
                                    }
                                >{`删除套题 ${bundleId}`} </Button>
                            </Center>

                            <Center>
                                <Box display={isUpdateBundleRequestError.status === 'pending' ? 'block' : 'none'}>
                                    <Spinner
                                        thickness='4px'
                                        speed='0.65s'
                                        emptyColor='gray.200'
                                        color='blue.500'
                                        size='lg'
                                        mt='20px'
                                    />
                                </Box>
                            </Center>
                        </Flex>
                    </Flex>
                </CardBody>
            </Card>
        </>
    );
}