import React, {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';

import {Box, HStack, Text, VStack} from '@chakra-ui/react'
// import questionList from './questionList.json'
import {ToeflIbtContext} from "./ToeflIbtContext";

function ToeflReadingText() {
    const {partIdx} = useParams();
    const {
        questionContent,
    } = React.useContext(ToeflIbtContext)
    const [readingTexts, setReadingTexts] = useState([]);

    useEffect(() => {
        console.log("questionContent", questionContent)
        setReadingTexts(questionContent?.tasks[partIdx - 1].reading_texts)
    }, [questionContent,partIdx]);

    return (
        <VStack w={'100%'}>
            <Box h={'80%'} bg={'#F9F9F9'} w={'100%'}>
                <VStack alignItems={'left'}>
                    <HStack p={4} h={'80%'} w={'100%'} alignItems="flex-start">
                        <Box w={'50%'} h={'90vh'}>
                            <VStack
                                border="2px"
                                borderColor="gray.200"
                                borderRadius="16"
                                height="100%"
                                width="100%"
                                spacing={0}
                            >
                                <Box
                                    h="5%"
                                    w="100%"
                                    p={4}
                                >
                                    <Text
                                        fontSize={"15.68px"}
                                        fontWeight="700"
                                        fontFamily={"Open Sans"}
                                        color="#3d3d3d"
                                        textAlign="center"
                                    >
                                        {readingTexts && readingTexts[0]}
                                    </Text>
                                </Box>
                                <Box
                                    overflowY="auto"
                                    width="100%"
                                    height='94%'
                                >
                                    <VStack spacing={4} align="stretch" p={3}>
                                        {readingTexts && readingTexts.map((text, id) => {
                                            if (id === 0) {
                                                return
                                            }
                                            return <div key={id}
                                                dangerouslySetInnerHTML={{__html: text}}
                                            />
                                        })}
                                    </VStack>
                                </Box>
                            </VStack>
                        </Box>
                    </HStack>
                </VStack>
            </Box>
        </VStack>
    )
}

export default ToeflReadingText;