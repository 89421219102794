import {
    Box,
    Flex,
    Heading,
    Text,
    UnorderedList,
    ListItem,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useGetGradingQuery } from "services/cmApi";
import { useSelector } from "react-redux";
import { selectToeflData } from "redux/toeflSlice";

export default function Mindmap(props) {
    const toeflData = useSelector(selectToeflData);

    const [result, setResult] = useState([]);

    const grading_result = useGetGradingQuery({ gid: props.gid });

    useEffect(() => {
        if (grading_result?.data?.data) {
            try {
                const jsonStrings = grading_result.data.data.result.split('\n').filter(Boolean);
                const parsedData = jsonStrings.map(jsonStr => JSON.parse(jsonStr));

                setResult(parsedData);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }
    }, [grading_result]);

    console.log(result);

    const revisedEssay = result.find(item => item.revised_answer)?.revised_answer || "";

    const mindmapData = result.find(item => item.mindmap)?.mindmap || {};
    const keywords = result.find(item => item.keywords)?.keywords || [];
    const additionalExamples = result.find(item => item.additional_examples)?.additional_examples || [];

    const capitalizeFirstLetterOfEachWord = (str) => {
        return str.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    };

    return (
        <Box padding="3rem">
            <Flex direction={{ base: "column", md: "row" }} mb={8} gap={4}>
                <Box flex="1" mr={{ md: 4 }}>
                    <Heading size="lg" mt={4} mb={2} fontFamily="monospace" color="blue.700" fontWeight="bold">
                        Revised Essay
                    </Heading>
                    <Text>{revisedEssay}</Text>
                </Box>
                <Box flex="1" ml={{ md: 4 }}>
                    <Heading size="lg" mb={4} mt={4} fontFamily="monospace" fontWeight="bold" color="blue.700">
                        Mindmap
                    </Heading>
                    {Object.entries(mindmapData).map(([key, value]) => (
                        <section key={key}>
                            <Heading as="h2" size="md" mb={2} fontFamily="monospace">
                                {capitalizeFirstLetterOfEachWord(key)}
                            </Heading>
                            {typeof value === 'string' ? (
                                <Text>{value}</Text>
                            ) : (
                                <UnorderedList>
                                    {Object.entries(value).map(([subKey, subValue]) => (
                                        <Box key={subKey} mb={4}>
                                            <Heading as="h3" size="sm" mt={4} mb={2} fontFamily="monospace">
                                                {capitalizeFirstLetterOfEachWord(subKey)}
                                            </Heading>
                                            {Array.isArray(subValue) ? (
                                                <UnorderedList>
                                                    {subValue.map((item, index) => (
                                                        <ListItem key={index}>{item}</ListItem>
                                                    ))}
                                                </UnorderedList>
                                            ) : (
                                                <Text>{subValue}</Text>
                                            )}
                                        </Box>
                                    ))}
                                </UnorderedList>
                            )}
                        </section>
                    ))}
                </Box>
            </Flex>
            <Box mb={8}>
                <Heading size="lg" mt={4} mb={2} fontFamily="monospace" fontWeight="bold" color="blue.700">
                    Keywords
                </Heading>
                <Table variant="striped" colorScheme="blue" size="sm">
                    <Thead>
                        <Tr>
                            <Th>WORD</Th>
                            <Th>PHONETIC SYMBOL</Th>
                            <Th>PART OF SPEECH</Th>
                            <Th>ENGLISH DEFINITION</Th>
                            <Th>SAMPLE SENTENCE</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {keywords.map((keyword, index) => (
                            <Tr key={index}>
                                <Td>{keyword.word}</Td>
                                <Td>{keyword.phonetic_symbol}</Td>
                                <Td>{keyword.part_of_speech}</Td>
                                <Td>{keyword.definition}</Td>
                                <Td>{keyword.sample_sentence}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
            <Box>
                <Heading size="lg" mt={4} mb={2} fontFamily="monospace" fontWeight="bold" color="blue.700">
                    Additional Examples
                </Heading>
                <Table variant="striped" colorScheme="blue" size="sm">
                    <Tbody>
                        {additionalExamples.map((example, index) => (
                            <Tr key={index}>
                                <Td>{example}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </Box>
    );
}
