import React, { useEffect, useState } from 'react';
import {
    Flex,
    Text,
    Box,
    Center,
    useDisclosure,
    Checkbox,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
} from '@chakra-ui/react';
import { selectToeflData, updateSelectedExampleLevel } from "../redux/toeflSlice";
import {
    useGetExampleAnswerQuery,
    useGetUserPreferencesQuery,
    useUpdateUserPreferencesMutation
} from "../services/cmApi";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function ChooseLevelButton(props) {
    const dispatch = useDispatch();
    const getColor = () => {
        if (props.level === 'beginner') {
            return 'gray.200';
        } else if (props.level === 'intermediate') {
            return 'green.200';
        } else {
            return 'pink.200';
        }
    }

    return (
        <Box as='label' m="5px">
            <Box
                cursor='pointer'
                borderWidth='1px'
                borderRadius='md'
                boxShadow='md'
                bgColor={getColor()}
                _checked={{
                    bg: 'teal.600',
                    color: 'white',
                    borderColor: 'teal.600',
                }}
                _hover={{
                    boxShadow: 'outline',
                }}
                borderRadius='30px'
                px={{ base: '15px', md: '20px', lg: '30px' }}
                py={3}
                onClick={() => {
                    dispatch(updateSelectedExampleLevel(props.level));
                    window.setTimeout(props.hidePopover, 250);
                }}
            >
                {props.children}
            </Box>
        </Box>
    )
}


export default function NewUserPopover() {
    const { t, i18n } = useTranslation();

    const { isOpen, onToggle, onClose } = useDisclosure();
    const [updateUserPreferencesRequest, isUpdateUserPreferencesError] = useUpdateUserPreferencesMutation();
    let preferences = useGetUserPreferencesQuery({});
    const toeflData = useSelector(selectToeflData);

    // const query = new URLSearchParams(window.location.search);
    const task_name = toeflData.taskName
    const tpo_number = toeflData.tpoNumber

    const shouldSilenceFTUE = () => {
        if (preferences.status !== "fulfilled") {
            return false;
        }
        const key = 'shouldSilenceFTUE';
        if (key in preferences.data) {
            return preferences.data[key];
        } else {
            return false;
        }
    };

    const shouldOpenFTUE = () => {
        if (!location.pathname.includes('practice')) {
            return false;
        }
        if (task_name !== "task1") {
            return false;
        }
        if (tpo_number !== 1) {
            return false;
        }
        if (preferences.status !== "fulfilled") {
            return false;
        }
        if (!('product' in preferences.data)) {
            return false;
        } else if ('shouldSilenceFTUE' in preferences.data) {
            return !preferences.data['shouldSilenceFTUE'];
        } else {
            return true;
        }
    };

    useEffect(() => {
        if (shouldOpenFTUE() && !isOpen) {
            onToggle();
        }
    }, [location.pathname, preferences]);


    const levels = ['beginner', 'intermediate', 'advanced'];
    // React Hooks can't be called in a loop so I had to write each one explicitly
    useGetExampleAnswerQuery({ section: 'speaking', task: 'task1', level: 'beginner' }, { skip: (toeflData.section != 'speaking' || toeflData.taskName != 'task1' || toeflData.selectedExampleLevel != 'beginner') })
    useGetExampleAnswerQuery({ section: 'speaking', task: 'task1', level: 'intermediate' }, { skip: (toeflData.section != 'speaking' || toeflData.taskName != 'task1' || toeflData.selectedExampleLevel != 'intermediate') })
    useGetExampleAnswerQuery({ section: 'speaking', task: 'task1', level: 'advanced' }, { skip: (toeflData.section != 'speaking' || toeflData.taskName != 'task1' || toeflData.selectedExampleLevel != 'advanced') })
    useGetExampleAnswerQuery({ section: 'writing', task: 'task1', level: 'beginner' }, { skip: (toeflData.section != 'writing' || toeflData.taskName != 'task1' || toeflData.selectedExampleLevel != 'beginner') })
    useGetExampleAnswerQuery({ section: 'writing', task: 'task1', level: 'intermediate' }, { skip: (toeflData.section != 'writing' || toeflData.taskName != 'task1' || toeflData.selectedExampleLevel != 'intermediate') })
    useGetExampleAnswerQuery({ section: 'writing', task: 'task1', level: 'advanced' }, { skip: (toeflData.section != 'writing' || toeflData.taskName != 'task1' || toeflData.selectedExampleLevel != 'advanced') })

    const getName = (key) => {
        if (key == "beginner") {
            return t("beginner");
        } else if (key == "intermediate") {
            return t("intermediate");
        } else if (key == "advanced") {
            return t("advanced");
        }
    }

    return (
        <Modal placement='top' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader bgColor="yellow.400">{t("ai_test_prep_expert")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex flexDirection="column" >
                        <Text>
                            {toeflData.section == 'speaking' ?
                                t("first_time_user_instructions_speaking") : t("first_time_user_instructions_writing")}
                        </Text>
                        <Box height="20px"></Box>
                        <Center>
                            <Flex flexDirection={{ base: 'column', sm: 'row' }}>
                                {levels.map((level) => {
                                    return (
                                        <ChooseLevelButton level={level} hidePopover={onToggle}>
                                            <Center>
                                                {getName(level)}
                                            </Center>

                                        </ChooseLevelButton>
                                    )
                                })}
                            </Flex>
                        </Center>
                        <Flex>
                            <Box flex="1"></Box>
                            <Checkbox
                                isChecked={shouldSilenceFTUE()}
                                onChange={(e) => {
                                    let prefs = {};
                                    prefs['shouldSilenceFTUE'] = e.target.checked;
                                    updateUserPreferencesRequest(prefs)
                                        .then((response) => response.json())
                                        .then((data) => {
                                            console.log(data)
                                        })
                                        .catch(console.error);
                                }}
                                mt="10px"
                            >{t("do_not_show_by_default")}</Checkbox>
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
