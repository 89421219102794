import React, {useEffect, useState} from 'react';
import {
    Flex,
    Text,
    Box,
    Center,
    useDisclosure,
    Checkbox,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
} from '@chakra-ui/react';
import {selectToeflData, updateSelectedExampleLevel} from "../redux/toeflSlice";
import {
    useGetExampleAnswerQuery,
    useGetUserPreferencesQuery,
    useUpdateUserPreferencesMutation
} from "../services/cmApi";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

function ChooseTestButton(props) {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [updateUserPreferencesRequest, isUpdateUserPreferencesError] = useUpdateUserPreferencesMutation();
    let history = useHistory();

    return (
        <Box as='label' m="5px">
            <Box
                cursor='pointer'
                borderWidth='1px'
                borderRadius='md'
                boxShadow='md'
                bgColor='gray.200'
                _checked={{
                    bg: 'teal.600',
                    color: 'white',
                    borderColor: 'teal.600',
                }}
                _hover={{
                    boxShadow: 'outline',
                }}
                borderRadius='30px'
                px={{ base: '15px', md: '20px', lg: '30px' }}
                py={3}
                onClick={() => {
                    let prefs = {};
                    prefs['product'] = props.testName;
                    updateUserPreferencesRequest(prefs);
                    window.setTimeout(props.hidePopover, 250);
                    history.push(`/${props.testName}/speaking`);
                }}
            >
                <Center>
                    {t(props.testName)}
                </Center>
            </Box>
        </Box>
    )
}


export default function TestSelectionModal() {
    const { t, i18n } = useTranslation();

    const { isOpen, onToggle, onClose } = useDisclosure();
    const [updateUserPreferencesRequest, isUpdateUserPreferencesError] = useUpdateUserPreferencesMutation();
    let preferences = useGetUserPreferencesQuery({});
    const toeflData = useSelector(selectToeflData);

    const shouldOpenModal = () => {
        if (toeflData.tabIndex != 0) {
            return false;
        }
        if (toeflData.taskName !== "task1") {
            return false;
        }
        if (preferences.status !== "fulfilled") {
            return false;
        }
        const key = 'product';
        const currentTimestamp = Math.floor(Date.now());
        const timeSinceFulfillment = currentTimestamp - preferences.fulfilledTimeStamp;
        const timeSinceSectionChange = currentTimestamp - toeflData.sectionUpdatedTimestamp;
        if (timeSinceFulfillment < 1000 || timeSinceSectionChange < 1000) {
            if (!(key in preferences.data)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    useEffect(() => {
        window.setTimeout(() => {

        }, 2000);
    }, []);

    if (shouldOpenModal()) {
        if (!isOpen) {
            onToggle();
        }
    }


    return (
        <Modal placement='top' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader bgColor="yellow.400">{t("choose_test_type")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex flexDirection="column" >
                        <Text my="10px">
                            {t('choose_test_type_detail')}
                        </Text>
                        <Center>
                            <Flex flexDirection={{ base: 'column', sm: 'row'}}>
                                <ChooseTestButton testName='toefl' hidePopover={onToggle}></ChooseTestButton>
                                <ChooseTestButton testName='ielts' hidePopover={onToggle}></ChooseTestButton>
                            </Flex>
                        </Center>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
