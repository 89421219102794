import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import {
    FormControl,
    Select,
    Input,
    Flex,
    Box,
    Link,
    FormErrorMessage,
    VStack,
    InputRightElement,
    InputGroup,
    useToast,
    Text,
    Button,
    Heading,
    useBreakpointValue
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons';
import countriesEn from '../countries.json'
import countriesCn from '../countries_cn.json'
import debounce from "lodash/debounce";

const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;

export const ResetPassword = (props) => {
    const { t, i18n } = useTranslation();
    const countries = i18n.language === 'cn' ? countriesCn : countriesEn;
    const [prefix, setPrefix] = useState(() => {
        const defaultCountry = countries.find(c => c.isoCode === 'US');
        return defaultCountry ? defaultCountry.dialCode : '+1';
    })
    const [phoneNumber, setPhoneNumber] = useState('')
    const [phoneErr, setPhoneErr] = useState(false)
    const [email, setEmail] = useState('')
    const [emailErr, setEmailErr] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState(() => {
        const defaultCountry = countries.find(c => c.isoCode === 'US');
        return defaultCountry ? `${defaultCountry.name} (${defaultCountry.dialCode})` : '';
    });
    const [isPhoneNumberFocused, setIsPhoneNumberFocused] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [emailError, setEmailError] = useState('');

    const [country, setCountry] = useState({
        ip: "",
        countryName: "",
        countryCode: "",
        city: "",
        timezone: ""
    });

    const getGeoInfo = () => {
        axios
            .get("https://ipapi.co/json/")
            .then((response) => {
                let data = response.data;
                console.log("get ip data: ", data)
                setCountry({
                    ...country,
                    ip: data.ip,
                    countryName: data.country_name,
                    countryCode: data.country_calling_code,
                    city: data.city,
                    timezone: data.timezone
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getGeoInfo()
    }, [])

    useEffect(() => {
        if (!country.ip) {
            return
        }

        const matchedCountry = countries.find(item => 
            item.name.toLowerCase().includes(country.countryName.toLowerCase())
        );

        if (matchedCountry) {
            setSelectedCountry(`${matchedCountry.name} (${matchedCountry.dialCode})`);
            setPrefix(matchedCountry.dialCode);
        }
    }, [country, countries])

    const isValidPhoneNumber = (number) => {
        const phoneRegex = /^\+?[0-9]{7,15}$/;
        return phoneRegex.test(number);
    }

    const isValidEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const validatePhoneNumber = () => {
        if (!phoneNumber.trim()) {
            setPhoneErr(true);
            setPhoneNumberError(t('resetPassword.phoneRequired'));
            return false;
        } else if (!isValidPhoneNumber(prefix + phoneNumber)) {
            setPhoneErr(true);
            setPhoneNumberError(t('resetPassword.phoneInvalid'));
            return false;
        }
        setPhoneErr(false);
        setPhoneNumberError('');
        return true;
    }

    const validateEmail = () => {
        if (!email.trim()) {
            setEmailErr(true);
            setEmailError(t('resetPassword.emailRequired'));
            return false;
        } else if (!isValidEmail(email)) {
            setEmailErr(true);
            setEmailError(t('resetPassword.emailInvalid'));
            return false;
        }
        setEmailErr(false);
        setEmailError('');
        return true;
    }

    const resendCode = async () => {
        if (props.props.resetFrom === "phone" && !validatePhoneNumber()) {
            return;
        }

        if (props.props.resetFrom === "mail" && !validateEmail()) {
            return;
        }

        let signupInfo = {
            "routeName": 'reset_password',
        }
        if (props.props.resetFrom === "phone") {
            signupInfo["phone"] = prefix + phoneNumber
        } else {
            signupInfo["email"] = email
        }

        try {
            const response = await axios.post(API_URL + '/api/send_totp', signupInfo)
            if (response.status === 200) {
                // Navigate to verify window
                const callback = props.props.callback
                callback({
                    result: "ok",
                    resetFrom: props.props.resetFrom,
                    phone: props.props.resetFrom === "phone" ? prefix + phoneNumber : undefined,
                    email: props.props.resetFrom === "mail" ? email : undefined,
                    isResetPassword: true
                })
            }
        } catch (error) {
            console.log("send error: ", error)
            if (props.props.resetFrom === "phone") {
                setPhoneErr(true)
                setPhoneNumberError(t('resetPassword.sendError'))
            } else {
                setEmailErr(true)
                setEmailError(t('resetPassword.sendError'))
            }
        }
    }

    const inputStyles = {
        color: "#232323",
        fontWeight: 'medium',
        fontSize: '16px',
        _placeholder: { color: '#847E7C', fontWeight: 'medium', fontSize: '16px' },
        border: '2px solid #E4E6E6',
        height: "53px",
        _focus: { 
            borderColor: "#232323",
            color: "#232323"
        },
    };

    const padding = useBreakpointValue({ base: 4, sm: 6, md: 8, lg: 10 });
    const containerWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "80%", lg: "70%" });

    return (
        <Flex direction="column" h="100%" p={padding} justifyContent="center" alignItems="center">
            <VStack spacing={4} width={containerWidth}>
                <Box w="100%" shadow="md" borderWidth="1px" bg="white" borderRadius="8">
                    <VStack alignItems="stretch" spacing={6} p={padding}>
                        <Flex onClick={() => props.props.callback({ type: "login" })} alignItems="center" cursor="pointer">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.8572 6.81378H3.90239L6.52282 4.06273C6.63197 3.95206 6.71903 3.81967 6.77892 3.6733C6.83881 3.52692 6.87034 3.36949 6.87166 3.21019C6.87298 3.05088 6.84406 2.8929 6.7866 2.74545C6.72914 2.59801 6.64428 2.46405 6.53698 2.3514C6.42968 2.23875 6.30209 2.14967 6.16164 2.08934C6.0212 2.02902 5.87072 1.99866 5.71898 2.00005C5.56724 2.00143 5.41728 2.03453 5.27786 2.0974C5.13843 2.16028 5.01233 2.25168 4.90691 2.36627L0.335739 7.16531C0.229315 7.27676 0.144878 7.40916 0.0872666 7.55491C0.0296551 7.70067 0 7.85693 0 8.01474C0 8.17255 0.0296551 8.32881 0.0872666 8.47457C0.144878 8.62033 0.229315 8.75273 0.335739 8.86417L4.90691 13.6632C5.12244 13.8818 5.41111 14.0027 5.71075 14C6.01039 13.9972 6.29702 13.871 6.5089 13.6486C6.72078 13.4262 6.84097 13.1252 6.84357 12.8107C6.84618 12.4961 6.73099 12.193 6.52282 11.9668L3.90239 9.2133H14.8572C15.1603 9.2133 15.451 9.0869 15.6653 8.8619C15.8796 8.6369 16 8.33174 16 8.01354C16 7.69535 15.8796 7.39018 15.6653 7.16519C15.451 6.94019 15.1603 6.81378 14.8572 6.81378Z" fill="#232323"/>
                            </svg>
                            <Text ml={2} color="#232323" fontSize={14} fontWeight="500">
                                {t('resetPassword.backToLogin')}
                            </Text>
                        </Flex>

                        <Box>
                            <Heading as="h2" fontSize="24px" fontWeight="bold" color="#232323" mb={1}>
                                {t('resetPassword.title')}
                            </Heading>
                            <Text color="#717171" fontSize="16px" fontWeight="medium">
                                {props.props.resetFrom === "phone" 
                                    ? t('resetPassword.phoneInstructions')
                                    : t('resetPassword.emailInstructions')}
                            </Text>
                        </Box>

                        {props.props.resetFrom === "phone" ? (
                            <>
                                <FormControl>
                                    <Text as="label" mt="3px" fontWeight="semibold" fontSize="14px" mb={2} display="block">
                                        {t('resetPassword.countryCode')}
                                    </Text>
                                    <Box px="1px">
                                        <Select
                                            placeholder={selectedCountry}
                                            onChange={(e) => {
                                                const selectedIndex = e.target.selectedIndex;
                                                if (selectedIndex > 0 && selectedIndex <= countries.length) {
                                                    const country = countries[selectedIndex - 1];
                                                    setPrefix(country.dialCode);
                                                    setSelectedCountry(`${country.name} (${country.dialCode})`);
                                                }
                                            }}
                                            {...inputStyles}
                                            icon={<ChevronDownIcon />}
                                            borderColor="#E4E6E6"
                                            _focus={{ 
                                                borderColor: "#232323",
                                                color: "#232323"
                                            }}
                                            _hover={ {bg: "#F7F7F7"} }
                                            sx={{
                                                '& option': {
                                                    color: '#847E7C',
                                                    fontWeight: 'medium',
                                                    fontSize: '16px',
                                                },
                                                '&:not(:placeholder-shown)': {
                                                    color: '#232323',
                                                    fontWeight: 'medium',
                                                },
                                            }}
                                        >
                                            {countries.map((item, index) => (
                                                <option value={item.dialCode} key={index}>
                                                    {`${item.name} (${item.dialCode})`}
                                                </option>
                                            ))}
                                        </Select>
                                    </Box>
                                </FormControl>

                                <FormControl isInvalid={phoneErr}>
                                    <Text as="label" mt="3px" fontWeight="semibold" fontSize="14px" mb={2} display="block">
                                        {t('resetPassword.phoneNumber')}
                                    </Text>
                                    <Box px="1px">
                                        <Input
                                            type='tel'
                                            placeholder={t('resetPassword.phoneNumber')}
                                            value={phoneNumber}
                                            onChange={(e) => {
                                                setPhoneErr(false)
                                                setPhoneNumber(e.target.value)
                                                setPhoneNumberError('')
                                            }}
                                            onFocus={() => setIsPhoneNumberFocused(true)}
                                            onBlur={() => {
                                                setIsPhoneNumberFocused(false);
                                                validatePhoneNumber();
                                            }}
                                            {...inputStyles}
                                            autoComplete="new-password"
                                            borderColor={isPhoneNumberFocused ? "#232323" : (phoneErr ? "#C81E1E" : "#E4E6E6")}
                                            _hover={ {bg: "#F7F7F7"} }
                                            bg={phoneErr ? "#FDF2F2" : "white"}
                                            _focus={{
                                                borderColor: phoneErr ? "#C81E1E" : "#232323",
                                                boxShadow: "none"
                                            }}
                                            sx={{
                                                '&[aria-invalid=true]': {
                                                    borderColor: '#C81E1E',
                                                    boxShadow: 'none',
                                                    _focus: {
                                                        borderColor: '#C81E1E',
                                                        boxShadow: 'none',
                                                    }
                                                }
                                            }}
                                        />
                                        {phoneErr && (
                                            <FormErrorMessage fontSize="14px" fontWeight="medium" color="#C81E1E">
                                                {phoneNumberError}
                                            </FormErrorMessage>
                                        )}
                                    </Box>
                                </FormControl>
                            </>
                        ) : (
                            <FormControl isInvalid={emailErr}>
                                <Text as="label" mt="3px" fontWeight="semibold" fontSize="14px" mb={2} display="block">
                                    {t('resetPassword.email')}
                                </Text>
                                <Box px="1px">
                                    <Input
                                        type='email'
                                        placeholder={t('resetPassword.email')}
                                        value={email}
                                        onChange={(e) => {
                                            setEmailErr(false)
                                            setEmail(e.target.value)
                                            setEmailError('')
                                        }}
                                        onBlur={validateEmail}
                                        {...inputStyles}
                                        autoComplete="new-password"
                                        borderColor={emailErr ? "#C81E1E" : "#E4E6E6"}
                                        _hover={ {bg: "#F7F7F7"} }
                                        bg={emailErr ? "#FDF2F2" : "white"}
                                        _focus={{
                                            borderColor: emailErr ? "#C81E1E" : "#232323",
                                            boxShadow: "none"
                                        }}
                                        sx={{
                                            '&[aria-invalid=true]': {
                                                borderColor: '#C81E1E',
                                                boxShadow: 'none',
                                                _focus: {
                                                    borderColor: '#C81E1E',
                                                    boxShadow: 'none',
                                                }
                                            }
                                        }}
                                    />
                                    {emailErr && (
                                        <FormErrorMessage fontSize="14px" fontWeight="medium" color="#C81E1E">
                                            {emailError}
                                        </FormErrorMessage>
                                    )}
                                </Box>
                            </FormControl>
                        )}

                        <Button
                            w={'100%'}
                            borderRadius={'8px'}
                            bg={'black'}
                            color="white"
                            height="53px"
                            _hover={{ bg: "#232323" }}
                            onClick={resendCode}
                        >
                            {t('resetPassword.sendCode')}
                        </Button>

                        <Text
                            fontSize={16}
                            fontWeight={500}
                            color="#717171"
                            fontFamily="THICCCBOI, sans-serif"
                            textAlign="center"
                        >
                            {t('resetPassword.helpText')}{' '}
                            <Text as="span" fontWeight={600} color="#232323">
                                support@lingoleap.ai
                            </Text>
                        </Text>
                    </VStack>
                </Box>
            </VStack>
        </Flex>
    );
}