const PanelContent = {
  baseStyle: {
    ms: "auto",
    me: "auto",
    ps: { base:"2px", md: "15px"},
    pe: { base:"2px", md: "15px"}
  },
};

export const PanelContentComponent = {
  components: {
    PanelContent,
  },
};
