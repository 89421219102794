import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import {Button, Center, Flex, Icon,} from '@chakra-ui/react'
import {useTranslation} from "react-i18next";
import {FiUpload} from "react-icons/fi";


export default function UploadAudioFileButton(props) {
    var {isCorpusGeneration, onLoadEnd, ...other} = props;

    const { t, i18n } = useTranslation();

    const handleUpload = (e) => {
        const reader = new FileReader();
        let file = e.target.files[0]
        if (!file) {
            return;
        }
        reader.readAsDataURL(file);
        if (file.size > 5 * 10 ** 6) {
            alert("Audio file needs to be < 5M. Consider convert the audio to mp3 or other formats.")
        }

        reader.onloadend = async () => {
            onLoadEnd(reader.result);
        };
    };

    return (
        <Button
            fontSize={'sm'}
            rounded={'full'}
            bg={'blue.400'}
            color={'white'}
            _hover={{
                bg: 'blue.500',
            }}
            _focus={{
                bg: 'blue.500',
            }}
            minWidth={120}
            onClick={() => {
                const fileInput = document.getElementById('fileInput');
                fileInput.click();
            }
            }
            {...other}
        >
            <input id='fileInput' type="file" accept=".m4a,audio/*" onChange={handleUpload} rounded={'full'} style={{ display: 'none' }}
            />
            <Center>
                <Flex>
                    <Center>
                        <Icon as={FiUpload} w='17px' h='17px' mr="5px"/>
                    </Center>
                    <Center>
                        {t('upload_recording')}
                    </Center>
                </Flex>
            </Center>
        </Button>
    );
}



