import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import OutOfCreditsAlert from "./Dialogs/OutOfCreditsAlert"

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Checkbox,
    Text,
    useDisclosure,
} from '@chakra-ui/react'
import {
    appendResponseTrunk,
    clearResponse,
    handleErrorResponse,
    selectIeltsData,
    updateIsStreaming,
    updateUserInputReady
} from 'redux/ieltsSlice';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {
    cmApi,
    useGetBalanceQuery,
    useGetUserPreferencesQuery,
    useReportMutation,
    useUpdateUserPreferencesMutation
} from "../services/cmApi";
import AuthService from "../services/auth.service";
import mixpanel from 'mixpanel-browser';


export default function IeltsSubmitButton(props) {
    const {t, i18n} = useTranslation();
    const ieltsData = useSelector(selectIeltsData);
    const dispatch = useDispatch();

    const currentQuestionIdx = ieltsData.currentQuestionIdx;

    const [updateUserPreferencesRequest, isUpdateUserPreferencesError] = useUpdateUserPreferencesMutation();
    let preferences = useGetUserPreferencesQuery({});
    let balance = useGetBalanceQuery({});
    const cancelRef = React.useRef();
    const [silenceChargeAlert, setSilenceChargeAlert] = React.useState(true);
    const [reportRequest, reportRequestError] = useReportMutation();
    const {
        isOpen: isOutOfCreditsAlertOpen,
        onOpen: onOutOfCreditsAlertOpen,
        onClose: onOutOfCreditsAlertClose
    } = useDisclosure()
    const {
        isOpen: isChargeAlertOpen,
        onOpen: onChargeAlertOpen,
        onClose: onChargeAlertClose
    } = useDisclosure()
    const getChargeAmount = () => {
        return 200;
    }
    const requiredCredits = getChargeAmount()

    const processQuestionResponse = () => {
        const qks = Object.keys(ieltsData.userAudios);
        const qs = [];
        const rs = [];
        const audio_names = [];
        for (let i = 0; i < qks.length; i++) {
            let qk = qks[i];
            let audioData = ieltsData.userAudios[qk];
            let idx = parseInt(qk.match(/[0-9]+/)[0], 10);
            qs.push(ieltsData.questions[idx]);
            rs.push(audioData);
            if (ieltsData.userAudioNames[qk]) {
                audio_names.push(ieltsData.userAudioNames[qk]);
            }
        }
        const r = {'questions': qs,}
        console.log("audio_names", audio_names);
        if (audio_names.length > 0) {
            r['audio_names'] = audio_names;
        } else {
            r['audios'] = rs;
        }

        return r;
    };

    const prepareRequestParams = () => {
        if (ieltsData.section == "speaking") {
            const questionsResponses = processQuestionResponse()
            if (ieltsData.selectedPart === 1) {
                const args = {
                    'project': 'ielts',
                    'type': 'ispeaking_part1',
                    'questions': questionsResponses['questions'],
                    'audios': questionsResponses['audios'],
                    'audio_names': questionsResponses['audio_names'],
                    'qid': ieltsData.qid
                }
                return args
            } else if (ieltsData.selectedPart === 2) {
                const args = {
                    'project': 'ielts',
                    'type': 'ispeaking_part2_3',
                    'questions': questionsResponses['questions'],
                    'audios': questionsResponses['audios'],
                    'audio_names': questionsResponses['audio_names'],
                    'qid': ieltsData.qid
                }
                return args
            }
        } else if (ieltsData.section == "writing") {
            console.log("submit writing")
            if (ieltsData.selectedPart === 1) {
                console.log("writing part1 not yet implemented")
                return;
            } else if (ieltsData.selectedPart === 2) {
                const args = {
                    'project': 'ielts',
                    'type': 'iwriting_task2',
                    'topic': ieltsData.questions[0], //only one question
                    'content': ieltsData.answerText,
                    'qid': ieltsData.qid
                }
                return args
            }
        }
    };


    const getChargeAlertKey = () => {
        if (ieltsData.section == "speaking") {
            return "shouldSilence_ispeaking_" + ieltsData.selectedPart;
        } else if (ieltsData.section == "writing") {
            return "shouldSilence_iwriting_" + ieltsData.selectedPart;
        }
        return null;
    }

    const shouldShowChargeAlert = () => {
        if (preferences.status !== "fulfilled") {
            return true;
        }

        if (balance.data['balance'] <= 200) {
            return true;
        }

        const key = getChargeAlertKey();
        if (key in preferences.data) {
            return !preferences.data[key];
        } else {
            return true;
        }
    };

    const handleSubmit = () => {
        console.log("ielts submitting")
        dispatch(updateUserInputReady(true));
        mixpanel.track('Submit Button Click', {
            project: 'ielts',
            section: ieltsData.section,
            selectedPart: ieltsData.selectedPart,
            qid: ieltsData.qid,
        });

        if (shouldShowChargeAlert() && silenceChargeAlert) {
            let prefs = {};
            prefs[getChargeAlertKey()] = true;
            updateUserPreferencesRequest(prefs)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                })
                .catch(console.error);
        }

        const params = prepareRequestParams();
        console.log(params, "this is the parame")

        if (!params) {
            return;
        }
        dispatch(updateIsStreaming(true));

        let startTime = Math.floor(Date.now());

        params['authorization'] = AuthService.getCurrentUser()["token"];
        params['lang'] = i18n.language;
        let url = process.env.REACT_APP_WS_BACKEND_URL ? process.env.REACT_APP_WS_BACKEND_URL : window.location.origin;
        url = url.replace("https://", "wss://").replace("http://", "ws://");
        url = url + "/api/generate_ws";
        const ws = new WebSocket(url);

        ws.onopen = (event) => {
            ws.send(JSON.stringify(params));
        };

        ws.onmessage = function (event) {
            let data = JSON.parse(event.data);
            if (data.t === "data") {
                dispatch(appendResponseTrunk(data));
            } else if (data.t === "metadata") {
                dispatch(clearResponse(data.c));
                reportRequest({
                    "type": "start_streaming",
                    "start_time": startTime / 1000,
                    "duration": (Math.floor(Date.now()) - startTime) / 1000,
                    "gid": data.c.gid
                }).then(function (response) {
                });
            } else if (data.t === "error") {
                dispatch(handleErrorResponse(data));
            }
        };

        ws.onclose = function (event) {
            dispatch(updateIsStreaming(false));
            dispatch({
                type: `${cmApi.reducerPath}/invalidateTags`,
                payload: ['History'],
            });
            reportRequest({
                "type": "finish_streaming",
                "start_time": startTime / 1000,
                "duration": (Math.floor(Date.now()) - startTime) / 1000,
            }).then(function (response) {
            });
        };

        ws.onerror = function (error) {
            console.log("WebSocket error: ", error);
            reportRequest({
                "type": "error_streaming",
                "start_time": startTime / 1000,
                "duration": (Math.floor(Date.now()) - startTime) / 1000,
                "error": error
            }).then(function (response) {
            });
        };

        // if (ieltsData.section == "speaking") {
        //     history.push('/ielts/speaking')
        // } else if (ieltsData.section == "writing") {
        //     history.push('/ielts/writing')
        // }
    }
    const isButtonVisible = () => {
        if (ieltsData.section === "speaking") {
            return Object.keys(ieltsData.userAudios).length == ieltsData.questions.length && !props.isRecording;
        } else if (ieltsData.section === "writing") {
            return true;
        }
    }

    return (<Button
        onClick={() => {
            if (balance.data['balance'] < requiredCredits) {  // 积分不足
                onOutOfCreditsAlertOpen();
            } else if (shouldShowChargeAlert()) {
                onChargeAlertOpen();
            } else {
                handleSubmit();
            }
        }}
        colorScheme="yellow"
        rounded="full"
        minWidth="180px"
        // display={!props.forceVisible && (ieltsData.currentQuestionIdx !== (getNumQuestions() - 1) || props.isRecording || !currentUserAudio) ? 'none' : 'block'}
        display={isButtonVisible() ? "block" : "none"}
    >
        {t("grading")}
        <AlertDialog
            isOpen={isChargeAlertOpen}
            leastDestructiveRef={cancelRef}
            onClose={onChargeAlertClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {t("kind_notice")}
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <Text>
                            {t("this_will_cost")} {getChargeAmount()}{t("credit")}
                        </Text>
                        <Text fontSize={'sm'} style={{color: "#2e8ae6", fontWeight: "bold"}}>
                            <a href='/purchase'>{t("purchase_credits")}</a>
                        </Text>
                        <Checkbox
                            isChecked={silenceChargeAlert}
                            onChange={(e) => setSilenceChargeAlert(e.target.checked)}
                        >{t("dont_remind_me_again")}</Checkbox>

                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onChargeAlertClose}>
                            {t("cancel")}
                        </Button>
                        <Button colorScheme='green' onClick={() => {
                            onChargeAlertClose();
                            handleSubmit();
                        }} ml={3}>
                            {t("continue")}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        <OutOfCreditsAlert isOpen={isOutOfCreditsAlertOpen} onClose={onOutOfCreditsAlertClose}/>
    </Button>);
}
