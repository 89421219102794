import React, {useEffect} from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    CloseButton,
    Flex,
    Image,
    Text
} from '@chakra-ui/react';
import OutOfCreditSvg from "../../assets/svg/OutOfCredit.svg";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import mixpanel from "mixpanel-browser";

function OutOfCreditsAlert({isOpen, onClose}) {
    const history = useHistory();
    const cancelRef = React.useRef();
    const {t, i18n} = useTranslation();

    useEffect(() => {
        if (isOpen) {
            mixpanel.track('Dialogs Open', {name: "Out of Credits"});
        }
    }, [isOpen])


    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent borderRadius="16px" maxWidth="500px">
                        <CloseButton
                            position="absolute"
                            top={3}
                            right={3}
                            onClick={onClose}
                            bg={"white"}
                            borderRadius="full"
                            _hover={{bg: "gray.200"}}
                            _focus={{boxShadow: "outline"}}
                        />
                        <Image src={OutOfCreditSvg} alt="Alert Image" mb={4}/>
                        <AlertDialogHeader fontSize="4xl" fontWeight="bold" textAlign="center">
                            {t("Out of Credits")}
                        </AlertDialogHeader>
                        <AlertDialogBody textAlign="center" mt={-3}>
                            <Text fontSize="xl">
                                {t("Get more credits to unlock new questions and keep practicing")}
                            </Text>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Flex justifyContent="center" width="100%" ml={2} mr={2} mb={3}>
                                <Button width="100%"
                                        height={"60px"}
                                        bg="#FF8A00"
                                        onClick={() => {
                                            mixpanel.track('Dialogs Button Click', {
                                                name: "Out of Credits",
                                                to: "/purchase"
                                            });
                                            history.push('/purchase')
                                        }}
                                        color="black" p={4}
                                        fontSize={"xl"}
                                >
                                    {t("Buy now")}
                                </Button>
                            </Flex>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}

export default OutOfCreditsAlert;
