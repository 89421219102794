import React from "react";
import {useTranslation} from 'react-i18next';
import {Box, Center, Container, Flex, Icon, Tab, TabList, Tabs, Text, VStack} from "@chakra-ui/react";
import LLAudioPlayer from "./LLAudioPlayer";

export function IeltsSpeakingQuestionAnswerList(props) {
    const {t, i18n} = useTranslation();
    return (<Box p="20px">
        {
            props.data.map((item, index) => {
                let key = 'question_' + index;
                return (
                    <Flex flexDirection="column">
                        <Text fontSize="md" color="black">{`${index + 1}. ${item.question}`}</Text>
                        <Flex ml="10px">
                            <Center>
                                <Text>
                                    {t("your_answer")}:
                                </Text>
                            </Center>
                            <Center>
                                <LLAudioPlayer
                                    src={item.user_audio_url}
                                />
                            </Center>
                        </Flex>
                        <Text ml="10px">{item.answer}</Text>
                        <Flex ml="10px">
                            <Center>
                                <Text>
                                    {t("revised_answer")}:
                                </Text>
                            </Center>
                            <Center>
                                <LLAudioPlayer
                                    src={item.example_audio_url}
                                />
                            </Center>
                        </Flex>
                        <Text ml="10px" mb="20px">{item.revised_answer}</Text>
                    </Flex>

                );
            })
        }
    </Box>);
}