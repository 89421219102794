import React, { useState } from "react";
import {
    Box,
    Button,
    Flex,
    Input,
    useToast,
    FormControl,
    FormLabel,
    Switch,
    Text
} from "@chakra-ui/react";
import axios from "axios";
import Card from "./Card/Card";
import CardBody from "./Card/CardBody";
import CardHeader from "./Card/CardHeader";
import authHeader from "../services/auth-header";
import { useAddTagMutation, useRemoveTagMutation } from "services/cmApi";
import { useColorModeValue } from "@chakra-ui/react";
const AdminAddTags = ({ tags }) => {
    const textColor = useColorModeValue("gray.700", "white");
    const [en, setEn] = useState("");
    const [cn, setCn] = useState("");
    const [type, setType] = useState("");
    const [filter, setFilter] = useState(false); // filter is a boolean
    const toast = useToast();
    const [addTag, { isSuccess, isError, error }] = useAddTagMutation();
    const [removeTag, { isSuccess: removeIsSuccess }] = useRemoveTagMutation();

    // const handleSubmit = async () => {
    //     try {
    //         const response = await axios.post(API_URL + "/api/admin_add_tags", {
    //             en,
    //             cn,
    //             type,
    //             filter
    //         }, { headers: authHeader() });
    //         toast({
    //             title: "Tag Added",
    //             description: response.data.message,
    //             status: "success",
    //             duration: 5000,
    //             isClosable: true
    //         });
    //         setEn("");
    //         setCn("");
    //         setType("");
    //         setFilter(false);
    //         if (onTagAdded) {
    //             onTagAdded();
    //         }
    //     } catch (error) {
    //         toast({
    //             title: "Error",
    //             description: error.response.data.message,
    //             status: "error",
    //             duration: 5000,
    //             isClosable: true
    //         });
    //     }
    // };
    const handleSubmitTag = async () => {
        try {
            const payload = { en, cn, type, filter };
            await addTag({ payload }).unwrap();

            if (isSuccess) {
                toast({
                    title: "Tag Added",
                    description: "Your tag has been successfully added.",
                    status: "success",
                    duration: 5000,
                    isClosable: true
                });
                setEn("");
                setCn("");
                setType("");
                setFilter(false);
                if (onTagAdded) {
                    onTagAdded();
                }
            }
        } catch (err) {
            toast({
                title: "Error",
                description: err.data ? err.data.message : "An error occurred",
                status: "error",
                duration: 5000,
                isClosable: true
            });
        }
    };
    const handleRemoveTag = async (tagId) => {
        try {
            const payload = { tag_id: tagId };
            await removeTag({ payload }).unwrap();

            if (removeIsSuccess) {
                toast({
                    title: "Tag Removed",
                    description: "Your tag has been successfully added.",
                    status: "success",
                    duration: 5000,
                    isClosable: true
                });
                setEn("");
                setCn("");
                setType("");
                setFilter(false);
                if (onTagAdded) {
                    onTagAdded();
                }
            }
        } catch (err) {
            console.log(err)
            toast({
                title: "Error",
                description: err.data ? err.data.message : "An error occurred",
                status: "error",
                duration: 5000,
                isClosable: true
            });
        }
    };


    return (
        <>
            <Card>
                <CardHeader>
                    <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
                        Add Tags
                    </Text>
                </CardHeader>
                <CardBody>
                    <Flex direction="column" p={5}>
                        <FormControl id="en" mb={3}>
                            <FormLabel>English Tag</FormLabel>
                            <Input value={en} onChange={(e) => setEn(e.target.value)} />
                        </FormControl>
                        <FormControl id="cn" mb={3}>
                            <FormLabel>Chinese Tag</FormLabel>
                            <Input value={cn} onChange={(e) => setCn(e.target.value)} />
                        </FormControl>
                        <FormControl id="type" mb={3}>
                            <FormLabel>Type</FormLabel>
                            <Input value={type} onChange={(e) => setType(e.target.value)} />
                        </FormControl>
                        <FormControl display="flex" alignItems="center" mb={3}>
                            <FormLabel htmlFor="filter" mb="0">
                                Is Filter
                            </FormLabel>
                            <Switch id="filter" isChecked={filter} onChange={() => setFilter(!filter)} />
                        </FormControl>
                        <Box align="center">
                            <Button colorScheme="blue" onClick={handleSubmitTag}>Add Tag</Button>
                        </Box>
                    </Flex>
                    <Box>
                        <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
                            Manage Tags
                        </Text>
                        {tags && tags.map(tag => (
                            <Flex key={tag.id} align="center" justify="space-between" mb={2}>
                                <Text mr={4}>{tag.en} / {tag.cn}  ------ type: {tag.type}</Text>
                                <Button size="sm" colorScheme="red" onClick={() => handleRemoveTag(tag.id)}>
                                    Remove
                                </Button>
                            </Flex>
                        ))}
                    </Box>
                </CardBody>
            </Card>

        </>



    );
};

export default AdminAddTags;
