// Chakra imports
import {Box, Link, Portal, Stack, useColorMode, useDisclosure,} from "@chakra-ui/react";
import Configurator from "components/Configurator/Configurator";
// Layout components
import PageNavbar from "components/Navbars/PageNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import React, {useEffect, useState} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import routes from "routes.js";
// Custom Chakra theme
// Custom components
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
import bgAdmin from "assets/img/admin-background.png";
import Figure from "react-bootstrap/Figure";
import logoPng from "../assets/logo_long.png";
import {DarkMode} from "@chakra-ui/system";
import AuthService from "../services/auth.service";
import {RegisterAlertModal} from "../components/modals/RegisterAlertModal";
import {useTranslation} from "react-i18next";

export default function DashboardLayout(props) {
    const {...rest} = props;
    // states and functions
    const [fixed, setFixed] = useState(true);
    const {colorMode} = useColorMode();
    const {isOpen: isOpenRegisterAlert, onOpen: onOpenRegisterAlert, onClose: onCloseRegisterAlert} = useDisclosure();
    const {t, i18n} = useTranslation();

    useEffect(() => {
        if (!AuthService.getCurrentUser()) {
            return;
        }
        const query = new URLSearchParams(window.location.search);
        if (query.get("noreset")) {
            return;
        }

    }, []);

    // functions for changing the states from components
    const getRoute = () => {
        return window.location.pathname !== "/admin/full-screen-maps";
    };
    const getActiveRoute = (routes) => {
        let activeRoute = t("share");
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else if (routes[i].category) {
                let categoryActiveRoute = getActiveRoute(routes[i].views);
                if (categoryActiveRoute !== activeRoute) {
                    return categoryActiveRoute;
                }
            } else if (window.location.href.indexOf((routes[i].layout || "") + routes[i].path) !== -1) {
                return routes[i].name;
            }
        }
        return activeRoute;
    };
    // This changes navbar state(fixed or not)
    const getActiveNavbar = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].category) {
                let categoryActiveNavbar = getActiveNavbar(routes[i].views);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    if (routes[i].secondaryNavbar) {
                        return routes[i].secondaryNavbar;
                    }
                }
            }
        }
        return activeNavbar;
    };
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.category === "account") {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/toefl" || prop.layout === "/ielts" || prop.layout==='/chat') {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else if (!prop.layout) {
                return (
                    <Route
                        path={prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };
    const {isOpen, onOpen, onClose} = useDisclosure();
    document.documentElement.dir = "ltr";
    // Chakra Color Mode
    return (
        <Box>
            <Box
                minH='40vh'
                w='100%'
                position='absolute'
                bgImage={colorMode === "light" ? bgAdmin : "none"}
                bg={colorMode === "light" ? bgAdmin : "navy.900"}
                bgSize='cover'
                top='0'
            />
            <DarkMode>
                <Sidebar
                    routes={routes}
                    logo={
                        <Stack direction='row' spacing='12px' align='center' justify='center' ml={-5}>
                            {/*{colorMode === "dark" ? (*/}
                            {/*  <ArgonLogoLight w='74px' h='27px' />*/}
                            {/*) : (*/}
                            {/*  <ArgonLogoDark w='74px' h='27px' />*/}
                            {/*)}*/}
                            {/*<Box*/}
                            {/*  w='1px'*/}
                            {/*  h='20px'*/}
                            {/*  bg={colorMode === "dark" ? "white" : "gray.700"}*/}
                            {/*/>*/}
                            {/*{colorMode === "dark" ? (*/}
                            {/*  <ChakraLogoLight w='82px' h='21px' />*/}
                            {/*) : (*/}
                            {/*  <ChakraLogoDark w='82px' h='21px' />*/}
                            {/*)}*/}
                            <Link href='/'>
                                <Box>
                                    <Figure.Image className="circlex-logo" width={200} height={50} src={logoPng}
                                                  style={{marginBottom: "0px"}}/>
                                </Box>
                            </Link>
                        </Stack>

                    }
                    display='none'
                    onOpenRegisterAlert={onOpenRegisterAlert}
                    {...rest}
                />
            </DarkMode>
            <MainPanel
                w={{
                    base: "100%",
                    xl: "calc(100% - 275px)",
                }}>
                <Portal>
                    <PageNavbar
                        onOpen={onOpen}
                        brandText={getActiveRoute(routes)}
                        secondary={getActiveNavbar(routes)}
                        fixed={fixed}
                        isOpenRegisterAlert={isOpenRegisterAlert} onCloseRegisterAlert={onCloseRegisterAlert}
                        {...rest}
                    />
                </Portal>
                {getRoute() ? (
                    <PanelContent>
                        <PanelContainer>
                            <Switch>
                                {getRoutes(routes)}
                                <Redirect from='/toefl' to='/toefl/reading'/>
                            </Switch>
                        </PanelContainer>
                    </PanelContent>
                ) : null}
                {/*<Footer />*/}
                {/*<Portal>*/}
                {/*    <FixedPlugin*/}
                {/*        secondary={getActiveNavbar(routes)}*/}
                {/*        fixed={fixed}*/}
                {/*        onOpen={onOpen}*/}
                {/*    />*/}
                {/*</Portal>*/}
                <Configurator
                    secondary={getActiveNavbar(routes)}
                    isOpen={isOpen}
                    onClose={onClose}
                    isChecked={fixed}
                    onSwitch={(value) => {
                        setFixed(value);
                    }}
                />
            </MainPanel>
            <RegisterAlertModal isOpen={isOpenRegisterAlert} onClose={onCloseRegisterAlert}></RegisterAlertModal>
        </Box>
    );
}
