import React, { useState, useEffect } from "react";
import { IeltsQuestionInputPanel } from "./AdminQuestionBankToolsPanel";
import Card from "./Card/Card";
import CardHeader from "./Card/CardHeader";
import CardBody from "./Card/CardBody";
import axios from "axios";
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Tab,
    TabList,
    Tabs,
    Text,
    useColorModeValue,
    useDisclosure,
    useToast,
    Select,
    Checkbox,
    Stack,
    HStack
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
    useGetQuestionSetNamesQuery,
    useAdminGetQuestionListQuery,
    useGetTagsQuery,
    useAdminGetQuestionByQidQuery,
    useRemoveTagMutation,
    useAddTagMutation,
    useAddTagToQuestionMutation,
    useRemoveTagFromQuestionMutation,
    useAddQuestionMutation,
    useUpdateQuestionMutation,
    useUpdateQuestionStatusMutation
} from "services/cmApi";
import { useDispatch, useSelector } from "react-redux";
import {
    selectQuestionInputs,
    selectBundleId,
    selectBundleStatus,
    selectTest
} from "redux/adminSlice";
import QuestionList from "QuestionList";
import {
    loadQuestionInputs,
    clearQuestionInputs,
    updateQuestionInputs,
    updateTest
} from "redux/adminSlice";
import authHeader from "services/auth-header";
import AdminAddTags from "./AdminAddTags";

const mapTabIndexIelts = (part) => {
    if (part == "ispeaking_part1") {
        return 0
    } else if (part == "ispeaking_part2_3") {
        return 1
    } else if (part == "iwriting_task2") {
        return 2
    }

}
const indexToPartIelts = (tabIndex, partOnly = false) => {
    if (tabIndex == 0) {
        if (partOnly) {
            return "part1"
        }
        return "ispeaking_part1"
    } else if (tabIndex == 1) {
        if (partOnly) {
            return "part2&3"
        }
        return "ispeaking_part2_3";
    } else if (tabIndex == 2) {
        if (partOnly) {
            return "part2"
        }
        return "iwriting_task2";
    }
    return ""
};
export function AdminIeltsManagementPanel(props) {
    const dispatch = useDispatch();

    const textColor = useColorModeValue("gray.700", "white");
    const { t, i18n } = useTranslation();
    const toast = useToast()
    const [tabIndex, setTabIndex] = useState("");
    const [status, setStatus] = useState("ACTIVE");
    const [questionSet, setQuestionSet] = useState("")
    const [genType, setGenType] = useState("ispeaking_part1")
    const [UpdateQuestionSet, setUpdateQuestionSet] = useState("")
    // const [tags, setTags] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectQid, setSelectQid] = useState("")
    const [isEditSingleQuestion, setIsEditSingleQuestion] = useState(1)
    const questionInputs = useSelector(selectQuestionInputs);
    // RTK queries
    let {
        data: questions_raw,
        status: questions_status,
        refetch,
    } = useAdminGetQuestionListQuery({ question_set: questionSet, gen_type: genType });
    let {
        data: question_set_names_raw,
        status: question_set_names_status,
    } = useGetQuestionSetNamesQuery({});
    let {
        data: tags_raw,
        status: tags_status,
    } = useGetTagsQuery({});
    let { data: question_data_raw, status: question_data_status, refetch: question_data_refetch } = useAdminGetQuestionByQidQuery({ qid: selectQid }, { skip: selectQid == "" });
    let [updateQuestion, { isLoading: UpdateQuestionIsLoading, isSuccess: UpdateQuestionIsSuccess }] = useUpdateQuestionMutation();
    let [addQuestion, { isLoading: AddQuestionIsLoading, isSuccess: AddQuestionIsSuccess }] = useAddQuestionMutation();
    let [updateQuestionStatus, { isLoading: UpdateQuestionStatusIsLoading, isSuccess: UpdateQuestionStatusIsSuccess }] = useUpdateQuestionStatusMutation();

    //
    const tags = tags_status === 'fulfilled' && tags_raw
        ? tags_raw.tags
        : [];
    const question_set_names = question_set_names_status === 'fulfilled' && question_set_names_raw
        ? question_set_names_raw.data.map(item => item.question_set)
        : [];
    const questions = questions_status === 'fulfilled' && questions_raw
        ? questions_raw.data
        : [];

    useEffect(() => {
        if (question_set_names.length > 0) {
            setQuestionSet(question_set_names[0]);
            setUpdateQuestionSet(question_set_names[0]);
        }
    }, [question_set_names_status]);


    useEffect(() => {
        if (question_data_status === 'fulfilled' && question_data_raw) {
            dispatch(loadQuestionInputs(question_data_raw.data));
            setSelectedTags(question_data_raw.data.questions[0].tags.map((tag) => tag.id));
        }
    }, [question_data_status, question_data_raw, dispatch]);

    const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
    // const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

    const handleTagSelection = async (tagId, tabIndex, sync = true) => {
        const isTagSelected = selectedTags.includes(tagId);
        let updatedTags;

        if (isTagSelected) {
            updatedTags = selectedTags.filter(id => id !== tagId);
        } else {
            updatedTags = [...selectedTags, tagId];
        }

        dispatch(updateQuestionInputs({
            key: `${indexToPartIelts(tabIndex)}_tags`,
            value: updatedTags
        }));

        if (sync) {
            try {
                const apiEndpoint = isTagSelected ? '/api/admin_remove_tag_from_question' : '/api/admin_add_tag_to_question';
                await axios.post(API_URL + apiEndpoint, {
                    qid: selectQid,
                    tag_id: tagId,
                }, { headers: authHeader() });
                setSelectedTags(updatedTags);

                toast({
                    title: `Tag ${isTagSelected ? "Removed" : "Added"}`,
                    description: `Tag has been successfully ${isTagSelected ? "removed from" : "added to"} the question.`,
                    status: "success",
                    duration: 5000,
                    isClosable: true
                });
            } catch (error) {
                console.error(`Error ${isTagSelected ? "removing" : "adding"} tag to question:`, error);
            }
        } else {
            setSelectedTags(updatedTags);
        }
    };

    useEffect(() => {
        if (UpdateQuestionIsSuccess) {
            dispatch(clearQuestionInputs());
            setSelectQid("");
            toast({
                title: "Update Successful",
                description: "The question has been updated successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "bottom"
            });
        }
        if (AddQuestionIsSuccess) {
            dispatch(clearQuestionInputs());
            setSelectQid("");
            toast({
                title: "Update Successful",
                description: "The question has been updated successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "bottom"
            });
        }

    }, [UpdateQuestionIsSuccess, AddQuestionIsSuccess, dispatch]);
    const handleSubmitSingleQuestion = async () => {
        const payload = {
            test: "ielts",
            gen_type: genType,
            inputs: questionInputs,
            question_set: UpdateQuestionSet
        };
        try {
            await addQuestion({ payload }).unwrap();
        } catch (error) {
        }

    }
    const handleSubmitUpdateSingleQuestion = async () => {
        const payload = {
            test: "ielts",
            gen_type: genType,
            inputs: questionInputs,
            qid: selectQid,
            question_set: UpdateQuestionSet
        };
        try {
            await updateQuestion({ payload }).unwrap();
        } catch (error) {
        }
    };
    const handleUpdateQuestionStatus = async (status) => {
        const payload = {
            qid: selectQid,
            status: status
        };
        try {
            await updateQuestionStatus({ payload }).unwrap();
        } catch (error) {
        }
    };


    const handleOpenModal2 = async () => {
        setIsLoading(true);
        setIsLoading(false);
        onOpen2();
    };
    return (<>
        <AdminAddTags tags={tags} />
        <Card
            overflowX={{ sm: "scroll", xl: "hidden" }}
        >
            <CardHeader p="6px 0px 22px 0px">
                <Flex direction="column">
                    <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">修改单题/增加单题</Text>
                </Flex>
            </CardHeader>
            <Select
                value={isEditSingleQuestion}
                onChange={(e) => {
                    setIsEditSingleQuestion(e.target.value)
                    dispatch(clearQuestionInputs())
                    setSelectQid("")
                    setSelectedTags([])
                    dispatch(updateTest("ielts"))
                    dispatch(updateQuestionInputs({
                        key: `${genType}_status`,
                        value: "ACTIVE"
                    }));
                }}>
                <option value={1}>修改</option>
                <option value={0}>新增</option>
            </Select>

            {isEditSingleQuestion == 1 &&
                <>
                    <CardBody>
                        <Flex flexDirection="column">
                            <Text>选择题组</Text>
                            {questionSet}
                            <HStack>
                                <Select
                                    value={questionSet}
                                    onChange={(e) => {
                                        setTabIndex(0)
                                        setQuestionSet(e.target.value)
                                        setUpdateQuestionSet(e.target.value)
                                        dispatch(clearQuestionInputs())
                                        setSelectQid("")
                                        setGenType("ispeaking_part1")
                                        setSelectedTags([])
                                        dispatch(updateQuestionInputs({
                                            key: `${genType}_status`,
                                            value: "ACTIVE"
                                        }));
                                    }}>
                                    {question_set_names.map(item => {
                                        return <option value={item}>{item}</option>
                                    })}
                                </Select>
                                <Select
                                    value={genType}
                                    onChange={(e) => {
                                        setGenType(e.target.value)
                                        dispatch(clearQuestionInputs())
                                        setTabIndex(mapTabIndexIelts(e.target.value))
                                        setSelectQid("")
                                        setSelectedTags([])
                                        dispatch(updateQuestionInputs({
                                            key: `${e.target.value}_status`,
                                            value: "ACTIVE"
                                        }));
                                    }}>
                                    <option value={"ispeaking_part1"}>ispeaking_part1</option>
                                    <option value={"ispeaking_part2_3"}>ispeaking_part2_3</option>
                                    <option value={"iwriting_task2"}>iwriting_task2</option>

                                </Select>

                            </HStack>

                            <Flex>
                                <Button colorScheme='blue' onClick={handleOpenModal2}>
                                    导入单题
                                </Button>
                                <Box mx='10px'></Box>
                                <Modal isOpen={isOpen2} onClose={onClose2} size="full">
                                    <ModalOverlay />
                                    <ModalContent>
                                        <ModalHeader>选择题</ModalHeader>
                                        <ModalCloseButton />
                                        <ModalBody>

                                            {isLoading ? (
                                                <div>Loading...</div>
                                            ) : questions_status === "fulfilled" && (
                                                <QuestionList questions={questions} button={true} selectQid={selectQid} setSelectQid={setSelectQid} draggable={questionSet === "ALL" ? false : true} />)}
                                        </ModalBody>
                                    </ModalContent>
                                </Modal>
                            </Flex>
                        </Flex>
                        {selectQid != "" &&
                            <Box p="20px" mt="10px">
                                <Box p={4} borderWidth="1px" borderRadius="lg" overflow="hidden">
                                    <Text mb={4} fontWeight="bold">Select Tags for Question</Text>
                                    <Stack spacing={3}>
                                        {tags.map(tag => (
                                            <Checkbox
                                                key={tag.id}
                                                isChecked={selectedTags.includes(tag.id)}
                                                onChange={() => handleTagSelection(tag.id, tabIndex, false)}
                                                colorScheme="blue"
                                                iconColor="blue.500"
                                                borderColor="gray.300"
                                            >
                                                <Text as="span" ml={2}>
                                                    {tag.type}: {tag.en} / {tag.cn}
                                                </Text>
                                            </Checkbox>
                                        ))}
                                    </Stack>

                                </Box>
                                <Box p="20px" mt="10px">
                                    <Box p={4} borderWidth="1px" borderRadius="lg" overflow="hidden">
                                        <Text>选择修改题组</Text>
                                        <Select
                                            value={UpdateQuestionSet}
                                            onChange={(e) => {
                                                setUpdateQuestionSet(e.target.value)
                                                dispatch(updateQuestionInputs({
                                                    key: `${genType}_status`,
                                                    value: "ACTIVE"
                                                }));
                                                
                                            }}>
                                            {question_set_names.map(item => {
                                                return <option value={item}>{item}</option>
                                            })}
                                        </Select>
                                    </Box>
                                </Box>
                                <Box display={tabIndex == 0 ? 'block' : 'none'}>
                                    <IeltsQuestionInputPanel part="ispeaking_part1" questionInputs={questionInputs} dispatch={dispatch} />
                                </Box>
                                <Box display={tabIndex == 1 ? 'block' : 'none'}>
                                    <IeltsQuestionInputPanel part="ispeaking_part2_3" questionInputs={questionInputs} dispatch={dispatch} />
                                </Box>
                                <Box display={tabIndex == 2 ? 'block' : 'none'}>
                                    <IeltsQuestionInputPanel part="iwriting_task2" questionInputs={questionInputs} dispatch={dispatch} />
                                </Box>
                                <Flex direction="column">
                                    <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">题目状态</Text>
                                </Flex>
                                <Select
                                    value={questionInputs[`${genType}_status`]}
                                    onChange={(e) => {
                                        dispatch(updateQuestionInputs({
                                            key: `${genType}_status`,
                                            value: e.target.value
                                        }));
                                        handleUpdateQuestionStatus(e.target.value)
                                    }}>
                                    <option value={"ACTIVE"}>ACTIVE</option>
                                    <option value={"PUBLISHED"}>PUBLISHED</option>
                                </Select>
                                <Button
                                    colorScheme="blue"
                                    onClick={handleSubmitUpdateSingleQuestion}
                                    isLoading={UpdateQuestionIsLoading}
                                    loadingText="Submitting"
                                    mt="20px"
                                >
                                    Updating Question
                                </Button>

                            </Box>

                        }
                    </CardBody>
                </>
            }
            {isEditSingleQuestion == 0 &&
                <>
                    <Text>新增雅思</Text>
                    <Box p="20px" mt="10px">
                        <Box p={4} borderWidth="1px" borderRadius="lg" overflow="hidden"><Text>题型</Text>
                            <Select
                                value={genType}
                                onChange={(e) => {
                                    setGenType(e.target.value)
                                    dispatch(clearQuestionInputs())
                                    setSelectQid("")
                                    setSelectedTags([])
                                    dispatch(updateTest("ielts"))
                                    dispatch(updateQuestionInputs({
                                        key: `${e.target.value}_status`,
                                        value: "ACTIVE"
                                    }));

                                }}>
                                <option value={"ispeaking_part1"}>ispeaking_part1</option>
                                <option value={"ispeaking_part2_3"}>ispeaking_part2_3</option>
                                <option value={"iwriting_task2"}>iwriting_task2</option>
                            </Select></Box>

                    </Box>
                    <Box p="20px" mt="10px">
                        <Box p={4} borderWidth="1px" borderRadius="lg" overflow="hidden">
                            <Text>选择题组</Text>
                            {questionSet}
                            <Select
                                value={UpdateQuestionSet}
                                onChange={(e) => {
                                    setUpdateQuestionSet(e.target.value)
                                    dispatch(updateQuestionInputs({
                                        key: `${genType}_status`,
                                        value: "ACTIVE"
                                    }));
                                }}>
                                {question_set_names.map(item => {
                                    return <option value={item}>{item}</option>
                                })}
                            </Select>
                        </Box>
                    </Box>

                    <Box p="20px" mt="10px">
                        <Box p={4} borderWidth="1px" borderRadius="lg" overflow="hidden">
                            <Text mb={4} fontWeight="bold">Select Tags for Question</Text>
                            <Stack spacing={3}>
                                {tags.map(tag => (
                                    <Checkbox
                                        key={tag.id}
                                        isChecked={selectedTags.includes(tag.id)}
                                        onChange={() => handleTagSelection(tag.id, tabIndex, false)}
                                    >
                                        {tag.type}: {tag.en} / {tag.cn}
                                    </Checkbox>
                                ))}
                            </Stack>
                        </Box>
                    </Box>
                    <Box p="20px" mt="10px" >
                        <Box display={tabIndex == 0 ? 'block' : 'none'}>
                            <IeltsQuestionInputPanel part="ispeaking_part1" questionInputs={questionInputs} dispatch={dispatch} />
                        </Box>
                        <Box display={tabIndex == 1 ? 'block' : 'none'}>
                            <IeltsQuestionInputPanel part="ispeaking_part2_3" questionInputs={questionInputs} dispatch={dispatch} />
                        </Box>
                        <Box display={tabIndex == 2 ? 'block' : 'none'}>
                            <IeltsQuestionInputPanel part="iwriting_task2" questionInputs={questionInputs} dispatch={dispatch} />
                        </Box>
                    </Box>
                    <Flex direction="column">
                        <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">题目状态</Text>
                    </Flex>
                    <Select
                        value={questionInputs[`${genType}_status`]}
                        onChange={(e) => {
                            dispatch(updateQuestionInputs({
                                key: `${genType}_status`,
                                value: e.target.value
                            }));
                        }}>
                        <option value={"ACTIVE"}>ACTIVE</option>
                        <option value={"PUBLISHED"}>PUBLISHED</option>
                    </Select>
                    <Button
                        colorScheme="blue"
                        onClick={handleSubmitSingleQuestion}
                        isLoading={AddQuestionIsLoading}
                        loadingText="Submitting"
                        mt="20px"
                    >
                        Submit Question
                    </Button>

                </>
            }
        </Card >

    </>);
}

export default AdminIeltsManagementPanel;

