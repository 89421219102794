// Chakra imports
import {Box, Flex, Tab, TabList, Tabs, Text, useColorModeValue} from "@chakra-ui/react";
// Custom components
import React, {useState} from "react";
import {AdminSearchUserPanel} from "../../components/AdminSearchUserPanel";
import {AdminPendingCreditsPanel} from "../../components/AdminPendingCreditsPanel";
import {AdminViewFeedbackPanel} from "../../components/AdminViewFeedbackPanel";
import {AdminMiscellaneousPanel} from "../../components/AdminMiscellaneousPanel";
import {AdminIeltsManagementPanel} from "../../components/AdminIeltsManagementPanel";
import {AdminToeflManagementPanel} from "components/AdminToeflManagementPanel";
import {AdminCampusCertifyPanel} from "../../components/AdminCampusCertifyPanel";

function AdminView() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [tabIndex, setTabIndex] = useState("");

    return (
        <Flex direction="column" pt="80px">
            <Tabs width="100%" index={tabIndex} onChange={(index) => {
                setTabIndex(index);
            }}>
                <TabList>
                    <Tab px={{base: "5px", md: "16px"}}>
                        <Text fontSize='xl' fontWeight='bold'>Users</Text>
                    </Tab>
                    <Tab px={{base: "5px", md: "16px"}}>
                        <Text fontSize='xl' fontWeight='bold'>Feedback</Text>
                    </Tab>
                    <Tab px={{base: "5px", md: "16px"}}>
                        <Text fontSize='xl' fontWeight='bold'>Pending Credits</Text>
                    </Tab>
                    {/* <Tab px={{ base: "5px", md: "16px" }}>
                        <Text fontSize='xl' fontWeight='bold'>Question Bank</Text>
                    </Tab> */}
                    <Tab px={{base: "5px", md: "16px"}}>
                        <Text fontSize='xl' fontWeight='bold'>Miscellaneous</Text>
                    </Tab>
                    <Tab px={{base: "5px", md: "16px"}}>
                        <Text fontSize='xl' fontWeight='bold'>Ielts Questions</Text>
                    </Tab>
                    <Tab px={{base: "5px", md: "16px"}}>
                        <Text fontSize='xl' fontWeight='bold'>Toefl Questions</Text>
                    </Tab>
                    <Tab px={{base: "5px", md: "16px"}}>
                        <Text fontSize='xl' fontWeight='bold'>Campus Certify</Text>
                    </Tab>
                </TabList>
                <Box my="10px"></Box>
                <Box display={tabIndex == 0 ? 'block' : 'none'}>
                    <AdminSearchUserPanel></AdminSearchUserPanel>
                </Box>
                <Box display={tabIndex == 1 ? 'block' : 'none'}>
                    <AdminViewFeedbackPanel></AdminViewFeedbackPanel>
                </Box>
                <Box display={tabIndex == 2 ? 'block' : 'none'}>
                    <AdminPendingCreditsPanel></AdminPendingCreditsPanel>
                </Box>
                {/* <Box display={tabIndex == 3 ? 'block' : 'none'}>
                    <AdminQuestionBankToolsPanel></AdminQuestionBankToolsPanel>
                </Box> */}
                <Box display={tabIndex == 3 ? 'block' : 'none'}>
                    <AdminMiscellaneousPanel></AdminMiscellaneousPanel>
                </Box>
                <Box display={tabIndex == 4 ? 'block' : 'none'}>
                    <AdminIeltsManagementPanel></AdminIeltsManagementPanel>
                </Box>
                <Box display={tabIndex == 5 ? 'block' : 'none'}>
                    <AdminToeflManagementPanel></AdminToeflManagementPanel>
                </Box>
                <Box display={tabIndex == 6 ? 'block' : 'none'}>
                    <AdminCampusCertifyPanel></AdminCampusCertifyPanel>
                </Box>
            </Tabs>
        </Flex>
    );
}

export default AdminView;
