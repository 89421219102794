import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Text,
} from '@chakra-ui/react';

const FinishWarning = ({ isOpen, onClose, onReturn, onContinue }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Finish Warning</ModalHeader>
                <ModalBody>
                    <Text>You have seen all of the questions in this part of the Reading Section.</Text>
                    <Text>You may go back and Review. As long as there is time remaining, you can check your work.</Text>
                    <Text>Click on Return to continue working.</Text>
                    <Text>Click on Continue to go on.</Text>
                    <Text>Once you leave this part of the Reading section, you WILL NOT be able to return on it.</Text>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={onReturn}>RETURN</Button>
                    <Button colorScheme="green" onClick={onContinue} ml={3}>CONTINUE</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default FinishWarning;
