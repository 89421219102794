import React, {useEffect, useState} from "react";
import {
    Button,
    Center,
    Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay,
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectGid} from "../../redux/shareSlice";

export function RegisterAlertModal(props) {
    const {t, i18n} = useTranslation();
    let history = useHistory();

    const gid = useSelector(selectGid);

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t("ai_test_prep_expert")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Center>
                        {t("sharable_grading_page_message")}
                    </Center>
                    <Center mt="10px">
                        <Button
                            colorScheme="blue"
                            onClick={()=> {
                                history.push("/register?gid=" + gid);
                            }}
                        >{t("register_now")}</Button>
                    </Center>

                </ModalBody>
            </ModalContent>
        </Modal>
    );
}