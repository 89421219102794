import { createSlice } from '@reduxjs/toolkit';
import { extractSummary } from 'components/utils/common';

const initialState = {
    userDetails: {},
    bundleId: "",
    bundleStatus: "",
    test: "toefl",
    questionInputs: {}
};

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        updateUserDetails: (state, action) => {
            state.userDetails = action.payload;
        },
        updateQuestionInputs: (state, action) => {
            const key = action.payload['key']
            const value = action.payload['value']
            state.questionInputs[key] = value;
        },
        loadQuestionInputs: (state, action) => {
            // console.log(action.payload,"action")
            for (const [key, value] of Object.entries(action.payload.questions)) {
                console.log(value, "value")
                state.bundleId = value['bundle_id']
                state.questionInputs['bundleName'] = value['bundle_name']
                let test = value['test']
                let tags = value.tags.map(tag => (tag.id));
                state.test = test

                switch (test) {
                    case null:
                    case undefined:
                    case ("toefl"):
                        if ('reading' in value.question) {
                            state.questionInputs[`${key}_reading`] = value.question['reading'];
                        }
                        if ('listening' in value.question) {
                            state.questionInputs[`${key}_listening`] = value.question['listening'];
                        }
                        if ('topic' in value.question) {
                            state.questionInputs[`${key}_topic`] = value.question['topic'];
                        }
                        if (value.question.listening_audio && value.question.listening_audio.url) {
                            state.questionInputs[`${key}_listening_audio`] = value.question['listening_audio'];
                        }
                        state.questionInputs['discussion1_name'] = value.question.discussion1_name;
                        state.questionInputs['discussion2_name'] = value.question.discussion2_name;
                        state.questionInputs['discussion3_name'] = value.question.discussion3_name;
                        state.questionInputs['discussion1_content'] = value.question.discussion1_content;
                        state.questionInputs['discussion2_content'] = value.question.discussion2_content;
                        state.questionInputs['discussion3_content'] = value.question.discussion3_content;
                        state.bundleStatus = value.status
                        break;
                    case ("ielts"):
                        let short_summary_cn = extractSummary(value.short_summary, 'cn');
                        let short_summary_en = extractSummary(value.short_summary, 'en');

                        if (value.gen_type == "ispeaking_part1" || (`${value.section}_${value.task}` == "speaking_part1")) {
                            state.questionInputs[`ispeaking_part1_topic`] = short_summary_en
                            state.questionInputs[`ispeaking_part1_topic_en`] = short_summary_en
                            state.questionInputs[`ispeaking_part1_topic_cn`] = short_summary_cn
                            state.questionInputs[`ispeaking_part1_tags`] = tags
                            state.questionInputs[`ispeaking_part1_status`] = value.status

                            for (let i = 0; i < value.question.questions.length; i++) {
                                state.questionInputs[`ispeaking_part1_question_${i}`] = value.question.questions[i];
                            }
                        }
                        if (value.gen_type == "ispeaking_part2_3" || (`${value.section}_${value.task}` == "speaking_part2&3")) {
                            state.questionInputs[`ispeaking_part2_3_topic`] = short_summary_en
                            state.questionInputs[`ispeaking_part2_3_topic_en`] = short_summary_en
                            state.questionInputs[`ispeaking_part2_3_topic_cn`] = short_summary_cn
                            state.questionInputs[`ispeaking_part2_3_tags`] = tags
                            state.questionInputs[`ispeaking_part2_3_status`] = value.status
                            for (let i = 0; i < value.question.questions.length; i++) {
                                state.questionInputs[`ispeaking_part2_3_question_${i}`] = value.question.questions[i];
                            }
                        }
                        if (value.gen_type == "iwriting_task2" || (`${value.section}_${value.task}` == "writing_part2")) {
                            state.questionInputs[`iwriting_task2_topic`] = short_summary_en
                            state.questionInputs[`iwriting_task2_topic_cn`] = short_summary_cn
                            state.questionInputs[`iwriting_task2_topic_en`] = short_summary_en
                            state.questionInputs[`iwriting_task2_tags`] = tags
                            state.questionInputs[`iwriting_task2_question_${0}`] = value.question.question;
                            state.questionInputs[`iwriting_task2_status`] = value.status

                        }

                        state.bundleStatus = value.status
                        break;
                }

            }

        },
        deleteQuestionInputs: (state, action) => {
            const key = action.payload
            delete state.questionInputs[key];
        },
        clearQuestionInputs: (state, action) => {
            state.bundleId = ''
            state.questionInputs = {}
            state.bundleStatus = 'UNKNOWN'
            state.test = "toefl"
        },
        updateTest: (state, action) => {
            state.test = action.payload;
            state.bundleId = "",
                state.bundleStatus = "",
                state.questionInputs = {}
        },
        setQuestionInputs: (state, action) => {
            const key = action.payload['key']
            const value = action.payload['value']
            state.questionInputs = { [key]: value }
        },
    }
});

export const {
    updateUserDetails,
    updateQuestionInputs,
    deleteQuestionInputs,
    loadQuestionInputs,
    clearQuestionInputs,
    updateTest,
    setQuestionInputs,
} = adminSlice.actions;

export const selectUserDetails = (state) => state.admin.userDetails;
export const selectQuestionInputs = (state) => state.admin.questionInputs;
export const selectBundleStatus = (state) => state.admin.bundleStatus;
export const selectBundleId = (state) => state.admin.bundleId;
export const selectTest = (state) => state.admin.test;

export default adminSlice.reducer;
