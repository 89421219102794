import Card from "./Card/Card";
import { Button, Text, Box } from "@chakra-ui/react";
import { AiOutlineMessage } from "react-icons/ai";
import React, { useState, useEffect, useRef } from 'react';
import { Schema } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';
import { EditorState } from "prosemirror-state";
import { RiFireFill } from "react-icons/ri";
import { CiStreamOn } from "react-icons/ci";
import { TfiWrite } from "react-icons/tfi";
import { useGetGradingQuery } from "../services/cmApi";
import { FaImage } from "react-icons/fa";
import { IoIosLink } from "react-icons/io";
import { useSelector } from "react-redux";
import { selectToeflData } from "redux/toeflSlice";
import { schema as basicSchema } from 'prosemirror-schema-basic';
import { AnimatePresence, motion } from "framer-motion";
import { VscCircleFilled } from "react-icons/vsc";

import '../editor.css';

const annotationMark = {
    attrs: { title: { default: "" }, id: { default: "" }, type: { default: "" } },
    inclusive: false,
    parseDOM: [
        { tag: "span.annotation", getAttrs: dom => ({ title: dom.getAttribute("title"), id: dom.getAttribute("data-id"), type: dom.getAttribute("data-type") }) }
    ],
    toDOM: node => {
        return ["span", { class: `annotation ${node.attrs.type}`, title: node.attrs.title, "data-id": node.attrs.id }, 0];
    }
};

const mySchema = new Schema({
    nodes: basicSchema.spec.nodes,
    marks: {
        ...basicSchema.spec.marks,
        annotation: annotationMark
    }
});

export default function EssayComments(props) {
    const editorRef = useRef();
    const [annotations, setAnnotations] = useState([]);
    const [selectedAnnotationId, setSelectedAnnotationId] = useState(null);
    const [words, setWordCount] = useState("");
    const [sentences, setSentenceCount] = useState("");
    const [result, setResult] = useState([]);

    const grading_result = useGetGradingQuery({ gid: props.gid });

    useEffect(() => {
        if (grading_result?.data?.data) {
            try {
                // Split the JSON string into separate JSON objects
                const jsonStrings = grading_result.data.data.result.split('\n').filter(Boolean);
                const parsedData = jsonStrings.map(jsonStr => JSON.parse(jsonStr));
                setResult(parsedData);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }
    }, [grading_result]);

    useEffect(() => {
        if (result[0]?.content) {
            const annotationText = result[0].content;
            const wordsArray = annotationText.split(/\s+/);
            setWordCount(wordsArray.length);
            const sentencesArray = annotationText.split(/[.!?]+/);
            setSentenceCount(sentencesArray.length - 1);

            const startDoc = mySchema.nodeFromJSON({
                type: "doc",
                content: [{
                    type: "paragraph",
                    content: [{
                        type: "text",
                        text: annotationText,
                    }]
                }]
            });

            const state = EditorState.create({
                doc: startDoc,
                schema: mySchema
            });

            const view = new EditorView(editorRef.current, {
                state,
                dispatchTransaction(transaction) {
                    let newState = view.state.apply(transaction);
                    view.updateState(newState);
                }
            });

            view.dom.addEventListener('click', (event) => {
                const { pos } = view.posAtCoords({ left: event.clientX, top: event.clientY });
                const resolvedPos = view.state.doc.resolve(pos);
                const marks = resolvedPos.marks();

                const annotation = marks.find(mark => mark.type === mySchema.marks.annotation);
                if (annotation) {
                    handleCommentClick(annotation.attrs.id);
                    scrollToComment(annotation.attrs.id);
                }
            });

            initAnnotations(view);

            return () => {
                view.destroy();
            };
        }
    }, [result]);

    const initAnnotations = (view) => {
        const initialAnnotations = [];

        if (result[1]?.corrections) {
            const grammarErrorsAnnotations = result[1]?.corrections.map((error) => ({
                type: error.type,
                color: 'red',
                word: error.original,
                suggestion: { annotation: error.revised, desc: error.reason }
            }));
            initialAnnotations.push(...grammarErrorsAnnotations);
        }

        initialAnnotations.forEach(({ word, suggestion, type }, index) => {
            addAnnotation(view.state, view.dispatch, word, suggestion.annotation, type, index.toString());
        });

        setAnnotations(initialAnnotations.map((a, index) => ({ ...a, id: index.toString() })));
    };

    const addAnnotation = (state, dispatch, phrase, annotationText, type, id) => {
        let tr = state.tr;
        let regex = new RegExp(`\\b${phrase.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\b`, "gi");

        state.doc.descendants((node, pos) => {
            if (!node.isText) return;

            let text = node.text;
            let matches;
            while ((matches = regex.exec(text)) !== null) {
                let from = pos + matches.index;
                let to = from + matches[0].length;
                tr = tr.addMark(from, to, mySchema.marks.annotation.create({ title: annotationText, id, type }));
            }
        });

        if (tr.docChanged) {
            dispatch(tr);
        }
    };

    const handleCommentClick = (id) => {
        setSelectedAnnotationId(id);
    };

    const scrollToComment = (id) => {
        const commentElement = document.getElementById(`comment-${id}`);
        if (commentElement) {
            commentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <Box display="flex" flexDirection="row" gap="12px" width="full" bgColor="white">
            <Box experimental_spaceY="12px" paddingLeft="50px">
                <Box width="600px" display="flex" flexDirection="column" rowGap="12px" borderRadius="0px">
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Text fontWeight="bold">Transcript</Text>
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap="8px">
                            <Box borderRadius="full" bgColor="gray.100" padding="10px">{words} words</Box>
                            <Box borderRadius="full" bgColor="gray.100" padding="10px">{sentences} sentences</Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" maxW="600px" gap="12px" color="blue.600" fontWeight="bold">
                        <Text display="flex" alignItems="center" gap="10px" color="yellow.400">
                            <VscCircleFilled color="yellow" />Grammar Errors
                        </Text>
                        <Text display="flex" alignItems="center" gap="10px" color="green.400">
                            <VscCircleFilled color="lightgreen" />Spelling Errors
                        </Text>
                        <Text display="flex" alignItems="center" gap="10px" color="red.400">
                            <VscCircleFilled color="" />Rephrases
                        </Text>
                    </Box>
                    <Box>
                        {result[0]?.content && (
                            <Text ref={editorRef}>
                                {result != null && <Text id="annotationText" border="none" hidden>{result[0]?.content}</Text>}
                            </Text>
                        )}
                    </Box>
                </Box>
            </Box>
            <Box paddingLeft="0rem" display="flex" flexDirection="column" gap="2rem">
                <Box display="flex" flexDirection="row" padding="4px" gap="4px" alignItems="center" borderBottom="2px" maxW="fit-content" borderColor="blue.300">
                    <AiOutlineMessage />
                    <Text fontWeight="bold">All Comments</Text>
                </Box>
                <Box display="flex" flexDirection="row" gap="25px" height="fit-content" paddingLeft="40px">
                    {annotations.map(annotation => (
                        <React.Fragment key={annotation.id}>
                            {selectedAnnotationId === annotation.id ? (
                                <motion.div
                                    animate={{
                                        x: -10,
                                        y: 5,
                                        scale: 1.1,
                                        rotate: 0,
                                    }}
                                >
                                    <Card
                                        id={`comment-${annotation.id}`}
                                        borderLeft="4px"
                                        borderColor={`${annotation.color}.400`}
                                        maxW="fit-content"
                                        width="20rem"
                                    >
                                        <Text color={`${annotation.color}.600`} fontWeight="bold">{annotation.suggestion.annotation}</Text>
                                        <Text>{annotation.suggestion.desc}</Text>
                                    </Card>
                                </motion.div>
                            ) : (
                                <Card
                                    id={`comment-${annotation.id}`}
                                    height="fit"
                                    borderLeft="4px"
                                    borderColor={`${selectedAnnotationId === annotation.id ? annotation.color : "black"}`}
                                    rowGap="8px"
                                    maxW="fit-content"
                                    width="20rem"
                                    onClick={() => handleCommentClick(annotation.id)}
                                >
                                    <Text>{annotation.suggestion.annotation}</Text>
                                    <Text>{annotation.suggestion.desc}</Text>
                                </Card>
                            )}
                        </React.Fragment>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};
