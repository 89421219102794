import {Box, Divider, Heading, Text, VStack} from '@chakra-ui/react';
import React from "react";

function ToeflReadingDirections() {
    return (
        <Box maxWidth="860px" margin="auto" padding="4">
            <VStack spacing={8} align="stretch">
                <Heading as="h1" size="lg" textAlign="left" style={{fontWeight: 'normal'}}>
                    Reading Section Directions
                </Heading>
                <Divider borderWidth={1}/>
                <Text>
                    In this section, you will be able to demonstrate your ability to understand academic passages in
                    English. You will read and answer questions about <span style={{fontWeight: 'bold'}}>two
                    passages</span>. A clock will indicate how much time remains.
                </Text>
                <Text>
                    Some passages may include an underlined word or phrase. Select the word or phrase to see a
                    definition, an explanation, or an illustration.
                </Text>
                <Text>
                    Move to the next question by selecting <span style={{fontWeight: 'bold'}}>Next</span>. You can skip
                    questions and go back to them later if there is time remaining. Return to previous screens by
                    selecting <span style={{fontWeight: 'bold'}}>Back</span>
                </Text>
                <Text>
                    Select <span style={{fontWeight: 'bold'}}>Review Questions</span> at any time to see which
                    questions you have answered and which questions you have not answered. From the Review screen, you
                    may go only to questions you have already encountered.
                </Text>
                <Text>
                    Select <span style={{fontWeight: 'bold'}}>Continue</span> to go on.
                </Text>
            </VStack>
        </Box>
    );
}

export default ToeflReadingDirections;
