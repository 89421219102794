import React, {useEffect, useRef, useState} from 'react';
import {Box, Image, Progress, Text, VStack} from '@chakra-ui/react';
import {useHistory, useParams} from "react-router-dom";
import {ToeflIbtContext} from "./ToeflIbtContext";

function ToeflListeningAudioRe() {
    const {partIdx, subIdx} = useParams();
    const {
        questionContent,
    } = React.useContext(ToeflIbtContext);
    const [replayAudio, setReplayAudio] = useState(null);
    const [progress, setProgress] = useState(0);
    const [imgPath, setImgPath] = useState(null);
    const reAudioRef = useRef(null);
    const {volume} = React.useContext(ToeflIbtContext); // 获取音量
    let history = useHistory();

    useEffect(() => {
        setReplayAudio(null)
        getQuestionInfo();
    }, [questionContent, partIdx, subIdx]);

    useEffect(() => {
        if (reAudioRef.current) {
            reAudioRef.current.volume = volume; // 设置音量
        }
    }, [volume]);

    function getQuestionInfo() {
        const taskObj = questionContent?.tasks[partIdx - 1];
        setImgPath(taskObj?.listening_img)
        if (taskObj && taskObj?.questions) {
            for (const questionObj of taskObj?.questions) {
                if (questionObj.sub_question_idx.toString() === subIdx) {
                    if (questionObj.sub_question_audio && questionObj.sub_question_audio.replay_url) {
                        setReplayAudio(questionObj.sub_question_audio.replay_url);
                    }
                    return;
                }
            }
        }
    }

    const handleAudioEnded = () => {
        const nextUrl = location.pathname.replace("re_play", "question");
        history.push(nextUrl);
    };


    const handleTimeUpdate = () => {
        if (reAudioRef.current) {
            const currentTime = reAudioRef.current.currentTime - 6;
            const duration = reAudioRef.current.duration - 6;
            const percent = (currentTime / duration) * 100;
            setProgress(percent); // 更新进度
        }
    };

    return (
        <Box maxWidth="860px" margin="auto" padding="4">
            <VStack spacing={4} align="stretch">
                <audio
                    src={replayAudio}
                    autoPlay={true} // 自动播放音频
                    ref={reAudioRef} // 使用 ref 来控制音频
                    onTimeUpdate={handleTimeUpdate} // 监听时间更新
                    onEnded={handleAudioEnded}
                    style={{display: 'none'}} // 隐藏音频控件
                />
                {reAudioRef.current && reAudioRef.current.currentTime > 6 ? (
                    <Box>
                        <Box>
                            <Image
                                src={imgPath}
                                alt="Listening question image"
                                width="100%"
                                objectFit="cover"
                            />
                        </Box>
                        <Box mt={4}>
                            <Box display="flex" justifyContent="center" mt={12}> {/* 添加容器并居中 */}
                                <Progress
                                    value={progress} // 使用进度状态
                                    size="lg" // 设置进度条大小
                                    colorScheme="teal" // 设置进度条颜色为绿色
                                    border="1px solid black" // 边框颜色
                                    width="80%" // 设置宽度
                                    borderRadius={"xl"}
                                />
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <Box>
                        <Text fontSize="lg" fontWeight="bold" mb={1}>Listen again to part of the
                            conversation.</Text>
                        <Text fontSize="lg" fontWeight="bold">Then answer the question.</Text>
                    </Box>
                )}
            </VStack>
        </Box>
    );
}

export default ToeflListeningAudioRe;
