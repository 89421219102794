// Chakra imports
import {Flex, Grid} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import React, {useEffect} from "react";
import {IeltsRightPanel} from "components/IeltsRightPanel";
import {QuickLinksPanel} from "../../components/QuickLinksPanel";
import AuthService from "../../services/auth.service";
import {useHistory} from "react-router-dom";
import UpdatesModal from "../../components/UpdatesModal";

export default function IeltsView(props) {
    let history = useHistory();

    useEffect(() => {
        console.log('load ipbs')
        if (!AuthService.getCurrentUser()) {
            history.push("/login");
        }
    }, []);


    return (
        <Flex flexDirection='column' pt="80px">
            <Grid
                templateColumns={{sm: "1fr", lg: "4fr 1fr"}}
                templateRows={{lg: "repeat(2, auto)"}}
                gap='20px'>
                <Card overflowX={{sm: "scroll", xl: "hidden"}} width="100%" p={{base: "0px", xs: "22px"}} py="22px"
                >
                    <IeltsRightPanel></IeltsRightPanel>
                </Card>
                <QuickLinksPanel></QuickLinksPanel>
            </Grid>
            <UpdatesModal></UpdatesModal>
        </Flex>
    );
}

