import React, { useEffect, useState } from "react";
import { useMediaQuery } from '@chakra-ui/react';

import {
    Box,
    VStack,
    FormControl,
    Input,
    Link,
    Button,
    FormErrorMessage,
    useToast,
    Flex,
    Image,
    Heading,
    Text,
} from '@chakra-ui/react'
import { useRegisterMutation } from "../../../services/cmApi";
import AuthService from "../../../services/auth.service";
import { useTranslation } from 'react-i18next';
import arrowLeft from '../../../assets/login1.2_img/arrow-left.png'
import axios from "axios";
import { ReactComponent as EyeOutlineSVG } from '../../../assets/login1.2_img/eye_outline.svg';
import { ReactComponent as EyeSlashSVG } from '../../../assets/login1.2_img/eye_slash.svg';
const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;

export const VerifyAccount = (props) => {
    const [isLargerThan400] = useMediaQuery('(min-width: 400px)');
    const [codeErr, setCodeErr] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [codeInput, setCodeInput] = useState('')
    const [countdown, setCountdown] = useState(null);
    const [registerRequest, isRegisterError] = useRegisterMutation()
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const [countDownTime, setCountdownTime] = useState('0')
    const [intervalID, setIntervalID] = useState(-1)
    const [isVerifyAttempted, setIsVerifyAttempted] = useState(false);
    const [codeError, setCodeError] = useState('');

    

    useEffect(() => {
        console.log("start count down")
        startCountdown()
    }, [])

    const startCountdown = () => {
        let remainingTime = 60; // 假设倒计时为60秒
        const intervalId = setInterval(() => {
            setCountdown(remainingTime);
            remainingTime -= 1;
            console.log("verify remaining time: ", remainingTime)
            setCountdownTime(remainingTime + 's')
            if (remainingTime <= 0) {
                clearInterval(intervalId);
                setIntervalID(-1)
                setCountdown(-1);
                setCodeErr(true)
            }

        }, 1000);
        setIntervalID(intervalId)
    };

    const resendCode = async () => {
        setCodeErr(false);
        const input = props.props;
        let signupInfo = {
            "routeName": input.isResetPasswordFlow ? 'resend_verification' : 'signup',
        };
        if (input.phone) {
            signupInfo["phone"] = input.phone;
        }
        if (input.email) {
            signupInfo["email"] = input.email;
        }

        console.log("Sending request with data:", signupInfo);

        try {
            const response = await axios.post(API_URL + '/api/send_totp', signupInfo);
            if (response.status === 200) {
                startCountdown();
            }
        } catch (error) {
            console.error("Error sending code:", error.response?.data || error.message);
            let errMsg = "An error occurred. Please try again or contact support.";
            if (error.response?.data?.error) {
                errMsg = error.response.data.error;
                
                if (errMsg === "User already exists") {
                    startCountdown();
                    return;
                }
            }
            setErrMsg(errMsg);
            setCodeErr(true);
            toast({
                title: "Error sending code",
                description: errMsg,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const verifyCode = async () => {
        setIsVerifyAttempted(true);
        console.log("Verification attempt started");
        console.log("Code input:", codeInput);

        if (!codeInput.trim()) {
            console.log("Empty code input");
            setCodeErr(true);
            setCodeError("Code is required.");
            return;
        }

        try {
            const verifyInfo = props.props;
            console.log("Verify info:", verifyInfo);

            /*

            */
                console.log("Verification successful");
                if (verifyInfo.isResetPasswordFlow) {
                    const requestData = {
                        'email': verifyInfo.email,
                        'phone': verifyInfo.phone,
                        'totp_code': codeInput.trim(),
                    };
                    console.log("Sending verification request with:", requestData);
        
                    const response = await axios.post(API_URL + '/api/verify_totp', requestData);
                    console.log("Verification response:", response);
        
                    if (response.status === 200) {
                        console.log("Handling password reset");
                        props.props.callback({
                            type: "setNewPassword",
                            resetToken: response.data.password_reset_token,
                            authToken: response.data.token
                        });
                    } else {
                        console.log("Verification failed with status:", response.status);
                        setCodeErr(true);
                        setErrMsg("Sorry, we are not able to verify the code. Please make sure you enter the right information and code.");
                    }
                    
                } else {
                    console.log("Handling account creation");
                    let formData = {
                        "username": verifyInfo.phone || verifyInfo.email,
                        "full_name": verifyInfo.fullName || "",
                        "country_code": verifyInfo.countryCode || "",
                        "password": verifyInfo.password,
                        "referral_code": verifyInfo.invitationCode || "",
                        "gid": verifyInfo.gid || "",
                        "totp_code": codeInput.trim()
                    };
                    
                    if (verifyInfo.email) {
                        formData["email"] = verifyInfo.email;
                    } else {
                        formData["phone_number"] = verifyInfo.phone;
                    }
                    
                    console.log("Sending registration request with:", formData);
                    const registerResponse = await registerRequest(formData).unwrap();
                    console.log("Registration response:", registerResponse);
                    
                    if (registerResponse.status !== 400) {
                        console.log("Registration successful, proceeding to login");
                        AuthService.login(verifyInfo.email, verifyInfo.phone, verifyInfo.password).then(
                            () => {
                                console.log("Login successful, redirecting to /toefl/reading");
                                window.location.href = '/toefl/reading'
                            },
                            (error) => {
                                console.log("Login error:", error);
                                window.location.href = '/login'
                            }
                        );
                    } else {
                        console.log("Registration failed with status 400");
                        setCodeErr(true);
                        setErrMsg("Registration failed. Please try again.");
                    }
                }
            /*

                        */
        } catch (error) {
            console.log("Verification error:", error);
            console.log("Error response:", error.response);
            setCodeErr(true);
            if (error.response && error.response.data && error.response.data.error) {
                const backendError = error.response.data.error;
                if (backendError === "Invalid or expired top code") {
                    setErrMsg("Sorry, we are not able to verify the code. Please make sure you enter the right information and code.");
                } else {
                    setErrMsg(backendError);
                }
            } else {
                setErrMsg("Sorry, we are not able to verify the code. Please make sure you enter the right information and code.");
            }
        }

    }

    const inputStyles = {
        color: "#232323",
        fontWeight: 'medium',
        fontSize: '16px',
        _placeholder: { color: '#847E7C', fontWeight: 'medium', fontSize: '16px' },
        border: '2px solid #E4E6E6',
        height: "53px",
        _focus: { 
            borderColor: "#232323",
            color: "#232323"
        },
        bg: isVerifyAttempted && codeErr ? "#FDF2F2" : "white",
        _hover: { bg: "#F7F7F7" },
        sx: {
            '&[aria-invalid=true]': {
                borderColor: '#C81E1E',
                boxShadow: 'none',
                _focus: {
                    borderColor: '#C81E1E',
                    boxShadow: 'none',
                }
            }
        }
    };

    const getVerificationMessage = () => {
        if (props.props.phone) {
            return t('verifyAccount.phoneMessage');
        } else if (props.props.email) {
            return t('verifyAccount.emailMessage');
        }
        return t('verifyAccount.defaultMessage');
    };

    return (
        <Flex 
            direction="column" 
            h="100%" 
            p={isLargerThan400 ? "70px" : 4} 
            justifyContent="center" 
            alignItems="center"
        >
            <VStack spacing={4} width={isLargerThan400 ? "600px" : "100%"}>
                <Box w="100%" shadow="md" borderWidth="1px" bg="white" borderRadius="8">
                    <VStack alignItems="stretch" spacing={4} p={isLargerThan400 ? "7%" : "5%"}>
                        <Flex onClick={(e) => {
                            clearInterval(intervalID)
                            const callback = props.props.callback
                            callback({
                                type: "login",
                            })
                        }} alignItems="center" cursor="pointer">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.8572 6.81378H3.90239L6.52282 4.06273C6.63197 3.95206 6.71903 3.81967 6.77892 3.6733C6.83881 3.52692 6.87034 3.36949 6.87166 3.21019C6.87298 3.05088 6.84406 2.8929 6.7866 2.74545C6.72914 2.59801 6.64428 2.46405 6.53698 2.3514C6.42968 2.23875 6.30209 2.14967 6.16164 2.08934C6.0212 2.02902 5.87072 1.99866 5.71898 2.00005C5.56724 2.00143 5.41728 2.03453 5.27786 2.0974C5.13843 2.16028 5.01233 2.25168 4.90691 2.36627L0.335739 7.16531C0.229315 7.27676 0.144878 7.40916 0.0872666 7.55491C0.0296551 7.70067 0 7.85693 0 8.01474C0 8.17255 0.0296551 8.32881 0.0872666 8.47457C0.144878 8.62033 0.229315 8.75273 0.335739 8.86417L4.90691 13.6632C5.12244 13.8818 5.41111 14.0027 5.71075 14C6.01039 13.9972 6.29702 13.871 6.5089 13.6486C6.72078 13.4262 6.84097 13.1252 6.84357 12.8107C6.84618 12.4961 6.73099 12.193 6.52282 11.9668L3.90239 9.2133H14.8572C15.1603 9.2133 15.451 9.0869 15.6653 8.8619C15.8796 8.6369 16 8.33174 16 8.01354C16 7.69535 15.8796 7.39018 15.6653 7.16519C15.451 6.94019 15.1603 6.81378 14.8572 6.81378Z" fill="#232323"/>
                            </svg>
                            <Text ml={2} color="#232323" fontSize={14} fontWeight="500">
                                {t('verifyAccount.backToCreate')}
                            </Text>
                        </Flex>

                        <Box>
                            <Heading as="h2" fontSize="24px" fontWeight="bold" color="#232323" mb={1}>
                                {t('verifyAccount.title')}
                            </Heading>
                            <Text color="#717171" fontSize="16px" fontWeight="medium">
                                {getVerificationMessage()}
                            </Text>
                        </Box>

                        <FormControl isInvalid={isVerifyAttempted && codeErr}>
                            <Text as="label" mt="3px" fontWeight="semibold" fontSize="14px" mb={1} display="block">
                                {t('verifyAccount.codeLabel')}
                            </Text>
                            <Box px="1px">
                                <Input
                                    type='string'
                                    placeholder={t('verifyAccount.codePlaceholder')}
                                    value={codeInput}
                                    onChange={(e) => {
                                        setCodeErr(false);
                                        setIsVerifyAttempted(false);
                                        setCodeInput(e.target.value);
                                        setCodeError('');
                                    }}
                                    {...inputStyles}
                                    autoComplete="new-password"
                                    borderColor={isVerifyAttempted && codeErr ? "#C81E1E" : "#E4E6E6"}
                                    _focus={{
                                        borderColor: isVerifyAttempted && codeErr ? "#C81E1E" : "#232323",
                                        boxShadow: "none"
                                    }}
                                />
                                {isVerifyAttempted && codeErr && (
                                    <FormErrorMessage fontSize="14px" fontWeight="medium" color="#C81E1E">
                                        {codeError || t('verifyAccount.invalidCode')}
                                    </FormErrorMessage>
                                )}
                            </Box>
                        </FormControl>

                        <Flex alignItems="center" mt={-2}> 
                            <Text color="#545454" fontSize={14} fontWeight="500" mr={1}>
                                {t('verifyAccount.didntReceive')}
                            </Text>
                            {(countdown !== null && countdown < 0) && (
                                <Link
                                    onClick={resendCode}
                                    color="#6062FF"
                                    fontSize={14}
                                    fontWeight="700"
                                    _hover={{ textDecoration: 'none' }}
                                >
                                    {t('verifyAccount.resend')}
                                </Link>
                            )}
                            {(countdown >= 0) && (
                                <Text color="#232323" fontSize={14} fontWeight="700">
                                    {countDownTime}
                                </Text>
                            )}
                        </Flex>

                        <Button
                            w={'100%'}
                            borderRadius={'8px'}
                            bg={'black'}
                            color="white"
                            height="53px"
                            _hover={{ bg: "#232323" }}
                            onClick={verifyCode}
                        >
                            {t('verifyAccount.verifyButton')}
                        </Button>

                        <Text
                            fontSize={16}
                            fontWeight={500}
                            color="#717171"
                            fontFamily="THICCCBOI, sans-serif"
                            textAlign="center"
                        >
                            {t('verifyAccount.helpText')}{' '}
                            <Text as="span" fontWeight={600} color="#232323">
                                support@lingoleap.ai
                            </Text>
                        </Text>
                    </VStack>
                </Box>
            </VStack>
        </Flex>
    )
}