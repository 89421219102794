// Chakra imports
import {
    Box, Center,
    Flex, Grid, Text,
    useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import React from "react";
import {AccountPanel} from "../../components/AccountPanel";
import {QuickLinksPanel} from "../../components/QuickLinksPanel";
import {PaginatedTable} from "../../components/PaginatedTable";
import {useGetCreditHistoryQuery} from "../../services/cmApi";
import {CreditHistoryRow} from "../../components/Tables/CreditHistoryRow";
import {useTranslation} from "react-i18next";
import {SettingsPanel} from "../../components/SettingsPanel";

function AccountView() {
    let creditHistory = useGetCreditHistoryQuery({});
    const { t, i18n } = useTranslation();

    return (
        <Flex flexDirection='column' pt="80px">
            <Grid
                templateColumns={{sm: "1fr", lg: "4fr 1fr"}}
                templateRows={{lg: "repeat(2, auto)"}}
                gap='20px'>
                <Flex direction="column">
                    <Flex flexDirection={{base: "column", md: "row"}}>
                        <Card overflowX={{sm: "scroll", xl: "hidden"}} p={{base: "0px", xs: "22px"}} py="22px">
                            <AccountPanel></AccountPanel>
                        </Card>
                        <Box m="10px"></Box>
                        <Card px="22px">
                            <SettingsPanel></SettingsPanel>
                        </Card>
                    </Flex>
                    <Box my="10px"></Box>
                    <Card overflowX={{sm: "scroll", xl: "hidden"}} p={{base: "0px", xs: "22px"}} py="22px">
                        <Text px="30px" fontWeight="bold" fontSize="xl">{t("credit_history")}</Text>
                        <Center>
                            <PaginatedTable
                                data={creditHistory.status === 'fulfilled' ? creditHistory.data.events : []}
                                onRowSelected={null}
                                rowComponent={CreditHistoryRow}
                                pageSize={10}
                            ></PaginatedTable>
                        </Center>
                    </Card>
                </Flex>
                <QuickLinksPanel></QuickLinksPanel>
            </Grid>
        </Flex>
    );
}

export default AccountView;
