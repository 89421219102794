// Chakra imports
import { Flex, Grid } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import React, { useEffect } from "react";
import { QuickLinksPanel } from "../../components/QuickLinksPanel";
import AuthService from "../../services/auth.service";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectIeltsData } from "redux/ieltsSlice";
import IPBPracticePanel from "components/IPBPracticePanel";
import IPBWritingPracticePanel from "components/IPBWritingPracticePanel";

export default function IeltsPracticeView(props) {
    const ieltsData = useSelector(selectIeltsData);
    const isSpeaking = ieltsData.section === "speaking";
    const isWriting = ieltsData.section === "writing";
    let history = useHistory();


    useEffect(() => {
        console.log('load ipbs')
        if (!AuthService.getCurrentUser()) {
            history.push("/login");
        }
    }, []);


    return (
        <Flex flexDirection='column' pt="80px">
            <Grid
                templateColumns={{ sm: "1fr", lg: "4fr 1fr" }}
                templateRows={{ lg: "repeat(2, auto)" }}
                gap='20px'>
                <Card overflowX={{ sm: "scroll", xl: "hidden" }} width="100%" p={{ base: "0px", xs: "22px" }} py="22px"
                >
                    {isSpeaking && <IPBPracticePanel></IPBPracticePanel>}
                    {console.log(isWriting)}
                    {isWriting && <IPBWritingPracticePanel></IPBWritingPracticePanel>}

                </Card>
                <QuickLinksPanel></QuickLinksPanel>
            </Grid>
        </Flex>
    );
}

