import { createSlice } from '@reduxjs/toolkit';
import {cmApi} from "../services/cmApi";

const initialState = {
    isShared: false,
    gid: null,
    showLoginAlert: false,
    grading: {
        'is_shared': false,
        'created_at': 0,
        'estimated_score': null,
        'gen_type': null,
        'is_admin_privileged': false,
        'is_owner': false,
        'result': null,
        'tpo': null
    }
};

export const shareSlice = createSlice({
    name: 'share',
    initialState,
    reducers: {
        updateIsShared: (state, action) => {
            state.grading.is_shared = action.payload;
        },
        updateGid: (state, action) => {
            state.gid = action.payload;
        },
        updateShowLoginAlert: (state, action) => {
            state.showLoginAlert = action.payload;
        },
        updateIsOwner: (state, action) => {
            state.is_owner = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            cmApi.endpoints.updateSharingStatus.matchFulfilled,
            (state, { payload }) => {
                state.grading.is_shared = payload['is_shared']
            }
        ).addMatcher(
            cmApi.endpoints.getGrading.matchFulfilled,
            (state, { payload }) => {
                state.grading = payload.data
            }
        )
    },
});

export const {
    updateIsShared,
    updateGid,
    updateShowLoginAlert,
    updateIsOwner
} = shareSlice.actions;

export const selectIsShared = (state) => state.share.isShared;
export const selectGrading = (state) => state.share.grading;
export const selectGid = (state) => state.share.gid;
export const selectShowLoginAlert = (state) => state.share.showLoginAlert;
export const selectIsOwner = (state) => state.share.is_owner;

export default shareSlice.reducer;
