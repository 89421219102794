import authHeader from "../../services/auth-header";
import axios from "axios";

export function getCurrentProduct(userPreferences) {
    let product = userPreferences.product || 'toefl';
    if (location.pathname.includes('ielts')) {
        product = 'ielts'
    }
    if (location.pathname.includes('toefl')) {
        product = 'toefl'
    }
    return product;
}


export function mapToeflTabIndex(tabIndex) {
    if (tabIndex == 0) {
        return 'TPO';
    } else if (tabIndex == 1) {
        return 'QuestionBank'
    } else if (tabIndex == 2) {
        return 'CustomQuestion'
    } else {
        return 'Unknown'
    }
}

export function mapIeltsTabIndex(tabIndex) {
    if (tabIndex == 0) {
        return 'QuestionBank';
    } else if (tabIndex == 1) {
        return 'Jianya'
    } else if (tabIndex == 2) {
        return 'previous_season_questions'
    } else {
        return 'Unknown'
    }
}

export function getGenTypesForQuestionList(test, section) {
    if (test === 'toefl' && section === 'speaking') {
        return ['speaking_task1', 'speaking_task2', 'speaking_task3', 'speaking_task4'];
    } else if (test === 'toefl' && section === 'writing') {
        return ['writing_task1', 'writing_task3'];
    } else if (test === 'toefl' && section === 'listening') {
        return ['listening_task1', 'listening_task2', 'listening_task3', 'listening_task4', 'listening_task5'];
    } else if (test === 'toefl' && section === 'reading') {
        return ['reading_task1', 'reading_task2'];
    } else if (test === 'ielts' && section === 'speaking') {
        return ['ispeaking_part1', 'ispeaking_part2_3'];
    } else if (test === 'ielts' && section === 'writing') {
        return ['iwriting_task2'];
    } else {
        return []
    }
}

export function mapIeltsTaskName(selectedPart, section) {
    if (section == "speaking") {
        if (selectedPart == 1) {
            return 'part1'
        }
        if (selectedPart == 2) {
            return 'part2_3'
        }
    } else {
        if (selectedPart == 2) {
            return 'part2'
        }
    }
}

export function mapIeltsPartIndex(taskName, section) {
    if (section == "speaking") {
        if (taskName == 'part1') {
            return 1
        } else if (taskName == 'part2_3') {
            return 2
        }

    } else {
        if (taskName == 'part2') {
            return 2
        }
    }
}

export function getChargeAmount(genType) {
    switch (genType) {
        case 'speaking_task1':
        case 'speaking_task2':
        case 'speaking_task3':
        case 'speaking_task4':
            return 100;
        case 'speaking_generation':
        case 'speaking_generation_task1':
        case 'speaking_generation_task2':
        case 'speaking_generation_task3':
        case 'speaking_generation_task4':
        case 'writing_generation_task3':
            return 50;
        case 'writing_task1':
        case 'writing_task2':
        case 'writing_task3':
            return 200;
        case 'writing_generation':
        case 'writing_generation_task1':
            return 100;
        default:
            return 0;
    }
}

export function getGenTypeFromSectionAndTaskName(section, taskName, isCorpusGeneration) {
    if (isCorpusGeneration) {
        return `${section}_generation_${taskName}`;
    } else {
        return `${section}_${taskName}`;
    }
}

export function isGenTypeGeneration(genType) {
    return genType.includes('generation');
}

export const getOS = () => {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
};

export const getBackendUrl = () => {
    return process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
}

export const AuthorizedBackendGetRequest = (path) => {
    return axios.get(getBackendUrl() + path, {headers: authHeader()});
}


export function arrayToDict(questions) {
    let questionDict = {};
    questions.forEach((question, index) => {
        let key = `question_${index}`;
        questionDict[key] = question;
    });

    return questionDict;
}


export function extractSummary(summary, lang) {
    let summaryText = "";
    if (typeof summary[lang] === 'object' && summary[lang] !== null) {
        summaryText = summary[lang].name;
    } else {
        summaryText = summary[lang];
    }
    return summaryText;
}