import React from 'react';

import {
    Center,
    Image,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack
} from "@chakra-ui/react";
import {useTranslation} from 'react-i18next';
import qrcode2 from "../../assets/qrcode2.jpeg";

const ContactUsModal = (props) => {
    var {title, forceWechat, ...other} = props;
    const { t, i18n } = useTranslation();

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t(title)}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Center >
                        {/*<Image width={250} src={qrcode1} marginBottom={"20px"}/>*/}
                        {i18n.language == "cn" || forceWechat
                            ? <Image width={250} src={qrcode2} marginBottom={"0px"} />
                            : <VStack  mb={"15px"} align={"left"}><Text>Email: <Link href="mailto:support@lingoleap.ai" target="_blank">support@lingoleap.ai</Link></Text>
                                {/*<Text >Discord: <Link href="https://discord.gg/lingoleapai" target="_blank">https://discord.gg/lingoleapai</Link></Text>*/}
                                <Text >Facebook: <Link href="https://m.me/lingoleapai">https://m.me/lingoleapai</Link></Text></VStack>
                        }

                    </Center>
                </ModalBody>
            </ModalContent>
        </Modal>
    );


}

export default ContactUsModal;