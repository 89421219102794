import React, {useEffect, useState} from "react";
import {
    Alert, AlertDescription, AlertTitle,
    Box,
    Button,
    Center, Divider,
    Flex, Grid, Heading, Image, Link,
    SimpleGrid,
    Text,
    VStack
} from "@chakra-ui/react";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import AuthService from "../services/auth.service";
import {useTranslation} from "react-i18next";
import mixpanel from 'mixpanel-browser';

const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;

const Purchase = (price_id) => {
    window.location.href = API_URL + "/api/start_checkout?user_id=" + AuthService.getCurrentUser()['user_id'] + "&price_id=" + price_id
}

export function LineItemCard(props) {
    const {t, i18n} = useTranslation();

    const { item, hideButton, ...rest } = props;

    return (
        <Flex flexDirection="column" minWidth="80%">
            <Card bgColor={item.promoted? '#F8F4DF': "white"} borderWidth='5px' borderColor={item.promoted? "#EDBC77": "#EBEBEB"} p="0">
                <CardBody>
                    <VStack width="100%" spacing="10px">
                        <Box m="15px"></Box>
                        <Heading size='xl' color={item.promoted? "#744D08": "#454545"}>{t(item.display_name)}</Heading>
                        <Text fontSize='6xl' fontWeight='bold' color={item.promoted? "#F96730": "#454545"} fontFamily="system-ui">
                            {t('currency_symbol')}{item.amount}
                        </Text>
                        <Text fontSize='2xl' fontWeight='bold' color={item.promoted? "#B49B60": "#AAAAAA"} m="0px">
                            <s>{t('currency_symbol')}{item.original_amount}</s>
                        </Text>
                        <Text color="#9B9489" fontSize='md'>{item.description1}</Text>
                        <Box
                            bgColor={item.promoted? "#F96730": "#EBEBEB"}
                            borderBottomRadius="2xl"
                            width="full" p="15px">
                            <Center>
                                <Text color={item.promoted? "white": "#454545"} fontWeight='bold' fontSize='md'>
                                    {item.description2}
                                </Text>
                            </Center>
                        </Box>
                    </VStack>
                </CardBody>
            </Card>
            <Center mt="20px">
                <Button
                    colorScheme="yellow"
                    bgColor={item.promoted? "#F96730": "yellow.400"}
                    color={item.promoted? "white": "black"}
                    borderRadius='20px'
                    width="50%"
                    onClick={() => {
                        mixpanel.track('Purchase Button Click', {
                            order_name_string: item.display_name,
                            order_amount_string: item.amount,
                            price_id_string: item.price_id,
                        });
                        Purchase(item.price_id)
                    }}
                    display={hideButton ? 'none' : 'block'}

                >
                    {t('buy_now')}
                </Button>
            </Center>
        </Flex>

    )
}