import React, { ReactNode, useRef, useState } from 'react';

import {
    Box, Button, FormErrorMessage,
    FormControl, FormLabel, Input, Text,
    Checkbox, Textarea, Modal,
    ModalOverlay, ModalContent,
    Switch, ModalHeader, ModalBody,
    ModalFooter, ModalCloseButton,
    useDisclosure, NumberInput,
    Heading, Tr,
    IconButton, HStack
} from "@chakra-ui/react";
import {

    FiUpload,

} from 'react-icons/fi';
import { useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';

import { useSubmitFeedbackMutation } from '../../services/cmApi';
const GeneralFeedbackModal = (props) => {
    const toast = useToast()
    const disclosure = useDisclosure();
    const { isOpen, onOpen } = disclosure;
    const onClose = () => {
        disclosure.onClose();
        setText("");
        setIsNotBug('');
        setContactInfo('');
        setFileName('')
        setErrorMessage(null)
    };
    const FeedBackType = props.FeedBackType;
    const [text, setText] = useState("");
    const [isNotBug, setIsNotBug] = useState("");
    const [contactInfo, setContactInfo] = useState('');
    const [fileName, setFileName] = useState("");
    const fileInput = useRef(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const { t, i18n } = useTranslation();

    const handleFileChange = (event) => {

        setFileName(event.target.files[0]?.name || "");
    };


    const [submitFeedback, { isLoading, isError, error }] = useSubmitFeedbackMutation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!text.trim()) {
            setErrorMessage(t("error_feedback_content_empty"));
            return; // exit
        } else {
            setErrorMessage(null);
        }

        const details = JSON.stringify({ content: text });

        const formData = new FormData();
        formData.append('details', details);
        formData.append('is_bug', !isNotBug);
        formData.append('contact_info', contactInfo);
        if (fileInput.current && fileInput.current.files[0]) {
            formData.append('photo', fileInput.current.files[0]);
        }
        await submitFeedback(formData).unwrap().then(response => {
            // Use the response data to create a toast
            if (response.message === 'success') {
                toast({
                    title: t("feedback_successful"),
                    description: t("feedback_thank_you"),
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: t("feedback_failed"),
                    description: t("feedback_error"),
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }

            onClose();
        }).catch(err => {
            console.log(err)
            toast({
                title: t("feedback_failed"),
                description: err.data.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        });
    };

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('feedback_modal_header')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>{t('feedback_modal_body')}</Text>
                    <Box as="form" onSubmit={handleSubmit} mt={5}>
                        <FormControl id="isNotBug">
                        </FormControl>
                        <FormControl id="content_text" mt={5} isInvalid={!!errorMessage}>
                            <Textarea value={text} placeholder={t('feedback_describe_problem')} onChange={(e) => setText(e.target.value)} isRequired
                            spellcheck="false"/>
                            <FormErrorMessage>
                                {errorMessage}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl id="photo" mt={5}>
                            <Box display="flex" alignItems="center">
                                <Input
                                    type="file"
                                    ref={fileInput}
                                    onChange={handleFileChange}
                                    display="none"
                                    id="file-upload"
                                    accept="image/*"
                                />
                                <IconButton as="label" icon={<FiUpload />} aria-label="file-upload" htmlFor="file-upload" mr={3} />
                                <FormLabel>{t('upload_screenshot')}</FormLabel>
                                {fileName && <Text fontSize="md">{fileName}</Text>}
                            </Box>
                        </FormControl>
                        <FormControl id="contactInfo" mt={5}>
                            <HStack>
                                <FormLabel>{t('contact_method')}:</FormLabel>
                                <Input width="50%" type="text" placeholder={t("wechat") + '/' + t("telephone")} value={contactInfo} onChange={(e) => setContactInfo(e.target.value)} />
                            </HStack>
                        </FormControl>
                    </Box>
                </ModalBody>

                <ModalFooter>
                    <form onSubmit={handleSubmit}>
                        <Button mt={4} colorScheme="blue" type="submit">
                            {t("submit")}
                        </Button>
                    </form>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );


}

export default GeneralFeedbackModal;