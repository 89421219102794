import { Button, ButtonGroup, Stack, VisuallyHidden, HStack, Text } from '@chakra-ui/react'
import { GitHubIcon, GoogleIcon, TwitterIcon } from './ProviderIcons'

const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;

const providers = [
  {
    name: 'Google',
    icon: <GoogleIcon />,
    url: `${API_URL}/api/google_login`,
  },
  /*
  {
    name: 'Twitter',
    icon: <TwitterIcon />,
    url: '',
  },
  {
    name: 'GitHub',
    icon: <GitHubIcon />,
    url: '',
  },
  */
]

export const OAuthButtonGroup = () => {
  const queryString = new URLSearchParams(window.location.search);
  const rParam = queryString.get('r');

  const googleLoginUrl = rParam ? `${API_URL}/api/google_login?r=${rParam}` : `${API_URL}/api/google_login`;

  return (
    <Button key={"Google"} flexGrow={1} onClick={() => window.location.href = googleLoginUrl} >
    <HStack><GoogleIcon /> <Text size='lg' textColor={"grey.100"}>Continue with Google</Text></HStack>
    
  </Button>
  )
}