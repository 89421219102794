// Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  useColorModeValue, useDisclosure
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import AdminNavbarLinks from "./PageNavbarLinks";
import {useTranslation} from "react-i18next";
import logoPng from "assets/favicon.png";
import Figure from "react-bootstrap/Figure";
import {HamburgerIcon} from "@chakra-ui/icons";

export default function PageNavbar(props) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    }
  })

  const {
    variant,
    children,
    fixed,
    secondary,
    brandText,
    onOpen,
    ...rest
  } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = (fixed && scrolled) ? useColorModeValue("gray.700", "gray.200") : useColorModeValue("white", "gray.200");
  let secondaryText = (fixed && scrolled) ? useColorModeValue("gray.700", "gray.200") : useColorModeValue("white", "gray.200");
  let navbarPosition = "absolute";
  let navbarFilter = "none";
  let navbarBackdrop = "none";
  let navbarShadow = "none";
  let navbarBg = "none";
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";
  if (props.fixed === true)
    if (scrolled === true) {
      navbarPosition = "fixed";
      navbarShadow = useColorModeValue(
        "0px 7px 23px rgba(0, 0, 0, 0.05)",
        "none"
      );
      navbarBg = useColorModeValue(
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
      );
      navbarBorder = useColorModeValue("#FFFFFF", "rgba(255, 255, 255, 0.31)");
      navbarFilter = useColorModeValue(
        "none",
        "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
      );
    }

  if (props.secondary) {
    navbarBackdrop = "none";
    navbarPosition = "absolute";
    mainText = "white";
    secondaryText = "white";
    secondaryMargin = "22px";
    paddingX = "30px";
  }
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  const { t, i18n } = useTranslation();

  // SIDEBAR
  const {isOpen:isSideBarOpen, onOpen:onSideBarOpen, onClose: onSideBarClose} = useDisclosure();
  const hamburgerButtonRef = React.useRef();

  let navbarIconColor =
      props.fixed && scrolled
          ? useColorModeValue("gray.700", "gray.200")
          : useColorModeValue("white", "gray.200");
  if (props.secondary) {
    navbarIconColor = "white";
  }

  return (
    <Flex
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: "center" }}
      borderRadius="16px"
      display="flex"
      minH="75px"
      justifyContent={{ xl: "center" }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      left=""
      right="0px"
      px={{
        sm: paddingX,
        md: "30px",
      }}
      ps={{
        xl: "12px",
      }}
      pt="8px"
      top="18px"
      w={{ sm: "calc(100vw)", xl: "calc(100vw - 75px - 275px)" }}
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: "row",
          md: "row",
        }}
        alignItems={{ xl: "center" }}
        id="test"
      >
        <Box>
            <HamburgerIcon
                color={navbarIconColor}
                w="30px"
                h="30px"
                mr="10px"
                cursor='pointer'
                display={{ sm: "block", xl: "none" }}
                ref={hamburgerButtonRef}
                onClick={onSideBarOpen}
            />
        </Box>
        <Flex flexDirection="column" mr="10px" mt="-3px" display={{ sm: "block", xl: "none" }}>
          <Figure.Image width="32px" height="32px" src={logoPng}/>
        </Flex>
        <Box mb="0px">
          <Breadcrumb>
            <BreadcrumbItem color={mainText}>
              <BreadcrumbLink href="/" color={secondaryText}>
                LingoLeap
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem color={mainText}>
              <BreadcrumbLink href="#" color={mainText}>
                {t(brandText)}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
        <Box ms="auto" w={{ sm: "unset", md: "unset" }}>
          <AdminNavbarLinks
            onOpen={props.onOpen}
            logoText={props.logoText}
            secondary={props.secondary}
            navbarIconColor={navbarIconColor}
            isOpenRegisterAlert={props.isOpenRegisterAlert}
            onCloseRegisterAlert={props.onCloseRegisterAlert}
            isSideBarOpen={isSideBarOpen}
            onSideBarOpen={onSideBarOpen}
            onSideBarClose={onSideBarClose}
            hamburgerButtonRef={hamburgerButtonRef}
          />
        </Box>
      </Flex>
    </Flex>
  );
}