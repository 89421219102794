import {
    Button,
    Center,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    SimpleGrid,
    Switch,
    Text,
    useBreakpointValue,
    useDisclosure
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next';
import {useGetBalanceQuery, useGetQuestionListQuery} from "../services/cmApi";
import React, {useEffect, useState} from "react";
import {QuestionCard1} from "./QuestionCard1";
import TaskFilterRow from "./TaskFilterRow";
import axios from "axios";
import authHeader from "../services/auth-header";
import OutOfCreditsAlert from "./Dialogs/OutOfCreditsAlert";

const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;


export default function QuestionListPanel1(props) {
    let balance = useGetBalanceQuery({});
    var {test, section, questionSet, tags, hideTags, ...other} = props;
    const columnsValue = useBreakpointValue({base: 1, md: 2, xl: 2});
    const {t, i18n} = useTranslation();
    // let gen_types = getGenTypesForQuestionList(test, section);
    // const [selectedGenType, setSelectedGenType] = useState(gen_types[0]);
    const tpoOptions = generateTpoOptions();
    const [selectedTpoOptions, setSelectedTpoOptions] = useState(tpoOptions[0]);
    const yearOptions = generateYearOptions();
    const [selectedYearOptions, setSelectedYearOptions] = useState(yearOptions[0]);
    const [completionStatus, setCompletionStatus] = useState('all');
    const [isFullSet, setIsFullSet] = useState(false);
    let params = {
        test: test,
        section: section,
        question_set: questionSet,
        gen_types: [],
        completion_status: completionStatus,
        lang: i18n.language,
        tags: tags,
        is_full_set: isFullSet
    };
    if (questionSet === "tpo") {
        params.tpo_numbers = parseRangeToNumbers(selectedTpoOptions);
    } else if (questionSet === "questionBank") {
        params.question_year = Number(selectedYearOptions);
    }
    let questionList = []
    questionList = useGetQuestionListQuery(params);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = useBreakpointValue({base: 4, md: 10, xl: 10});
    const [updatedQuestionList, setUpdatedQuestionList] = useState([]);

    const {
        isOpen: isOutOfCreditsAlertOpen,
        onOpen: onOutOfCreditsAlertOpen,
        onClose: onOutOfCreditsAlertClose
    } = useDisclosure()

    useEffect(() => {
        setUpdatedQuestionList(questionList?.data?.data)
        if (questionList.status !== 'fulfilled') {
            return;
        }
        // console.log("questionList", questionList)
        let qids = [];
        questionList.data.data.forEach(item => {
            if (Array.isArray(item.qids)) {
                qids = qids.concat(item.qids);
            } else {
                qids.push(item.qid);
            }
        });
        // const practice_type = isFullSet ? "mock_test" : "practice";
        try {
            const param = {qids: qids};
            let headers = authHeader();
            axios.post(API_URL + '/api/get_question_list_status', param, {headers: headers})
                .then(response => {
                    if (response.status === 200) {
                        const updatedData = questionList.data.data.map(item => {
                            const status = isFullSet
                                ? item.qids.every(qid => response.data.data[qid])
                                : response.data.data[item.qid];
                            return {
                                ...item,
                                completed: status
                            };
                        });
                        setUpdatedQuestionList(updatedData);
                    }
                }).catch(error => {
                console.error('There was an error!', error);
            });
        } catch (error) {
            console.error('Error occurred while obtaining the content of the problem:', error);
        }
    }, [questionList]);

    function parseRangeToNumbers(rangeString) {
        const [start, end] = rangeString.split('-').map(Number);
        const numbers = [];
        for (let i = start; i <= end; i++) {
            numbers.push(i.toString());
        }
        return numbers.join(',');
    }

    const getQuestionListBody = () => {
        if (!updatedQuestionList?.length) {
            return null;
        }
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;

        function getTitle(item) {
            if (isFullSet) {
                return `${item.title} ${t(item.gen_type).slice(0, -1)}`;
            } else {
                return `${item.title} ${t(item.gen_type)}`;
            }
        }

        return <>
            <SimpleGrid columns={columnsValue} spacing="10px" gap="10px" w={'100%'}>
                {updatedQuestionList.slice(startIndex, endIndex).map((item, index) => {
                    return <>
                        <Center>
                            <QuestionCard1
                                genType={item.gen_type}
                                tpoNumber={item.tpo_number || item.order_index}
                                title={getTitle(item)}
                                summary={item.summary}
                                tags={hideTags ? [] : item.tags}
                                qid={item.qid}
                                completed={item.completed}
                                isFullSet={isFullSet}
                                balanceValue={(balance && balance.data && balance.data["balance"]) || 0}
                                onAlertOpen={onOutOfCreditsAlertOpen}
                            ></QuestionCard1>
                        </Center>
                    </>
                })}
            </SimpleGrid>
        </>
    }

    const totalPages = updatedQuestionList?.length ? Math.ceil(updatedQuestionList?.length / itemsPerPage) : 0;

    const paginationControls = (
        <Flex mt={4} justifyContent="center">
            <Button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
            >
                ←
            </Button>
            <Center>
                <Text mx={4}>
                    {currentPage}/ {totalPages}
                </Text>
            </Center>
            <Button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
            >
                →
            </Button>
        </Flex>
    );

    function generateTpoOptions() {
        const options = [];
        for (let i = 75; i >= 1; i -= 10) {
            options.push((i - 9) > 0 ? `${i - 9}-${i}` : `1-${i}`);
        }
        return options;
    }

    function generateTpoNames(options) {
        return options.map(option => `TPO ${option}`);
    }

    function generateYearOptions() {
        const currentYear = new Date().getFullYear();
        const options = [];
        for (let year = currentYear; year >= 2016; year--) {
            options.push(year.toString());
        }
        return options;
    }

    function generateYearNames(options) {
        return options.map(option => option);
    }

    return <>
        <Flex mx="40px" flexDirection="column" gap="5px">
            {questionSet === 'tpo' ? (
                <TaskFilterRow
                    rowName={t("number") + ":"}
                    options={tpoOptions}
                    names={generateTpoNames(tpoOptions)}
                    selectedOption={selectedTpoOptions}
                    onChange={(value) => {
                        setSelectedTpoOptions(value);
                        // dispatch(updatePart(mapIeltsPartIndex(value, section)));
                        setCurrentPage(1);
                    }}
                />
            ) : (
                <TaskFilterRow
                    rowName={t('year') + ":"}
                    options={yearOptions}
                    names={yearOptions}
                    selectedOption={selectedYearOptions}
                    onChange={(value) => {
                        setSelectedYearOptions(value);
                        // dispatch(updatePart(mapIeltsPartIndex(value, section)));
                        setCurrentPage(1);
                    }}
                />
            )}

            <Flex direction={"row"} justify="space-between">
                <TaskFilterRow
                    rowName={t('completion_status') + ":"}
                    options={['all', 'completed', 'not_completed']}
                    selectedOption={completionStatus}
                    names={[t("all"), t("completed"), t("not_completed")]}
                    onChange={(value) => {
                        setCompletionStatus(value)
                        setCurrentPage(1);
                    }}
                > </TaskFilterRow>
                <Flex>
                    <FormControl display='flex' alignItems='center'>
                        <FormLabel htmlFor='full_set' mb='0' size={'sm'} color={"black"}>
                            {t("full_exercise_set")}
                        </FormLabel>
                        <Switch id='full_set' size='md' isChecked={isFullSet} colorScheme={"green"} onChange={(e) => {
                            setIsFullSet(!isFullSet)
                        }}/>
                    </FormControl>
                </Flex>
            </Flex>
        </Flex>

        <Divider my="26px" mx="20px"/>
        <Flex mt={5}>
            {getQuestionListBody()}
        </Flex>
        <OutOfCreditsAlert isOpen={isOutOfCreditsAlertOpen} onClose={onOutOfCreditsAlertClose}/>
        {paginationControls}

    </>

}
