import React, { useState } from 'react';
import {Icon, Button, useToast, Center, Flex} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import formatRecordingSeconds from './utils/formatRecordingSeconds';
import { FaMicrophone } from 'react-icons/fa';
import {getOS} from "./utils/common";


function AudioRecorder({ onAudioSave, isRecording, setIsRecording, showRecordingFunctions }) {
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [recordingSeconds, setRecordingSeconds] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const { t, i18n } = useTranslation();
    const toast = useToast();

    const handleRecord = () => {
        if ("MediaRecorder" in window) {
            if (!isRecording) {
                navigator.mediaDevices.getUserMedia({ audio: true })
                    .then((mediaStream) => {
                        const recorder = new MediaRecorder(mediaStream);
                        setMediaRecorder(recorder);
                        recorder.start();

                        recorder.addEventListener('dataavailable', (e) => {
                            const file = new File([e.data], 'recorded_audio.webm', { type: e.data.type });
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onloadend = () => {
                                onAudioSave(reader.result);
                                recorder.stream.getTracks().forEach((t) => t.stop());
                                setMediaRecorder(null);
                            };
                        });

                        setIsRecording(true);
                        setRecordingSeconds(0);
                        const newIntervalId = setInterval(() => {
                            setRecordingSeconds(prevSeconds => prevSeconds + 1);
                        }, 1000);
                        setIntervalId(newIntervalId);

                    })
                    .catch((r) => {
                        const os = getOS();
                        let msg = 'Please allow microphone access';  // Default message
                        if (os === "iOS") {
                            msg = 'Please go to Settings and allow microphone access for this app';  // Adjust the message for iOS
                        }
                        toast({
                            title: 'Unable to access the microphone',
                            description: msg,
                            status: 'error',
                            duration: 9000,
                            isClosable: true,
                        });
                        console.log("Error in obtaining media stream: ", r.message, os);
                    });
            } else {
                if (mediaRecorder) {
                    mediaRecorder.stop();
                }

                setIsRecording(false);
                if (intervalId) {
                    clearInterval(intervalId);
                }
            }
        } else {
            alert("The MediaRecorder API is not supported in your browser.");
        }
    };

    return (
        <Button fontSize={'sm'}
            rounded={'full'}
            bg={'blue.400'}
            color={'white'}
            _hover={{
                bg: 'blue.500',
            }}
            _focus={{
                bg: 'blue.500',
            }}
            minWidth={120}
            maxWidth={120}
            onClick={handleRecord}
            display={showRecordingFunctions ? "block" : "none"}
        >
            <Center>
                <Flex>
                    <Center>
                        <Icon as={FaMicrophone} w='17px' h='17px' mr="5px"/>
                    </Center>
                    <Center>
                        {isRecording ? t('stop_record') + formatRecordingSeconds(recordingSeconds) : t('answer')}
                    </Center>
                </Flex>
            </Center>

        </Button>
    );
}

export default AudioRecorder;
