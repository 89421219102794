import { createSlice } from '@reduxjs/toolkit';
import {cmApi} from "../services/cmApi";

const initialState = {
    userPreferences: {}
};

export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            cmApi.endpoints.getUserPreferences.matchFulfilled,
            (state, { payload }) => {
                state.userPreferences = payload;
            }
        )
    },
});

export const {
} = generalSlice.actions;

export const selectUserPreferences = (state) => state.general.userPreferences;

export default generalSlice.reducer;
