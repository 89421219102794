import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const PageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page views in Mixpanel whenever the route changes
    mixpanel.track('Web App - Page View', {
      page: location.pathname,
    });
  }, [location]);

  return null; // This component doesn't render anything
};

export default PageViewTracker;
