import {useTranslation} from "react-i18next";
import {Badge, Box, Button, Center, Flex, Stack, StackDivider, Text} from "@chakra-ui/react";
import {useHistory} from "react-router-dom";
import React from "react";
import mixpanel from 'mixpanel-browser';

export function QuestionCard1(props) {
    var {
        genType,
        summary,
        tags,
        tpoNumber,
        title,
        qid,
        completed,
        isFullSet,
        balanceValue,
        onAlertOpen,
        ...other
    } = props;
    let section = getSection(genType);
    let history = useHistory();
    const {t, i18n} = useTranslation();
    const generationTasks = ["speaking_task1", "speaking_task2", "speaking_task3", "speaking_task4", "writing_task1", "writing_task3"]
    const enableGeneration = generationTasks.includes(genType);
    const disabled = balanceValue <= 0;

    function getSection(genType) {
        if (genType.includes('listening')) {
            return 'listening';
        } else if (genType.includes('reading')) {
            return 'reading';
        } else if (genType.includes('writing')) {
            return 'writing';
        } else if (genType.includes('speaking')) {
            return 'speaking';
        } else {
            return 'unknown';
        }
    }


    return (
        <Box
            bg="gray.100"
            borderRadius='20px'
            px={{lg: "20px", xl: "20px", md: "20px", sm: "6px"}}
            pt="20px"
            pb="30px"
            shadow="md"
            height="90%"
            width={{lg: "90%", md: "100%", sm: "100%"}}
        >
            <Flex>
                <Box flex={1}>
                    <Text fontSize="md" fontWeight='bold'>{title}</Text>
                </Box>
                <Center display={completed ? "block" : "none"}>
                    <Badge
                        variant='solid' colorScheme='yellow' py="4px" px="8px" borderRadius="full"
                    >
                        {t("completed")}
                    </Badge>
                </Center>
            </Flex>
            <Stack divider={<StackDivider/>} spacing="4">
                <Box>
                    <Text pt="2" fontSize="sm" isTruncated>
                        {summary && summary.replace(/<br\s*\/?>/gi, ' ')}
                        {/* <div dangerouslySetInnerHTML={{ __html: summary }} /> */}
                    </Text>
                    {tags &&
                        <Stack direction="row" wrap="wrap" spacing={2}>
                            {tags.map(tag => (
                                <Box key={tag.id} p={1}>
                                    <Badge
                                        colorScheme="blue"
                                        fontSize="0.85em"
                                        borderRadius="lg"
                                        px={3}
                                        py={1}
                                        textTransform="none"
                                        boxShadow="sm"
                                    >
                                        <Text isTruncated maxWidth="150px">
                                            {tag[i18n.language]}
                                        </Text>
                                    </Badge>
                                </Box>
                            ))}
                        </Stack>}
                    <Box mt="20px">
                        <Flex gap="10px" direction={{lg: "column", xl: "row", md: "column", sm: "column"}}>
                            {isFullSet ? <Button
                                    sx={{
                                        color: disabled ? 'white' : 'white',
                                        bg: disabled ? 'gray.500' : 'blue.500',
                                        borderColor: disabled ? 'gray.700' : 'blue.500',
                                        '&:hover': {
                                            bg: disabled ? 'gray.600' : 'blue.600',
                                            borderColor: disabled ? 'gray.600' : 'blue.600'
                                        }
                                    }}
                                    value={tpoNumber} size='sm' borderRadius='full' p="15px"
                                    width={{sm: "full"}} onClick={(e) => {
                                    mixpanel.track('Practice Button Click', {
                                        section: section,
                                        isFullSet: isFullSet
                                    });
                                    if (disabled) {
                                        onAlertOpen();
                                    } else {
                                        history.push(`/toefl_ibt/${tpoNumber}/${section}/all`)
                                    }
                                }}>
                                    {t("ibt_practice")}
                                </Button> :
                                <Button
                                    sx={{
                                        color: disabled ? 'white' : 'white',
                                        bg: disabled ? 'gray.500' : 'blue.500',
                                        borderColor: disabled ? 'gray.700' : 'blue.500',
                                        '&:hover': {
                                            bg: disabled ? 'gray.600' : 'blue.600',
                                            borderColor: disabled ? 'gray.600' : 'blue.600'
                                        }
                                    }}
                                    value={tpoNumber} size='sm' borderRadius='full'
                                    p="15px" width={{sm: "full"}} onClick={(e) => {
                                    mixpanel.track('Practice Button Click', {
                                        section: section,
                                        isFullSet: isFullSet
                                    });
                                    if (disabled) {
                                        onAlertOpen();
                                    } else {
                                        history.push(`/toefl_ibt/${tpoNumber}/${section}/${qid}`)
                                    }
                                }}>
                                    {t("practice_mode")}
                                </Button>}
                        </Flex>
                    </Box>

                </Box>
            </Stack>

        </Box>
    );
}