import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Center,
    Flex,
    Grid,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    useColorModeValue,
    useDisclosure
} from "@chakra-ui/react";
import CardHeader from "./Card/CardHeader";
import CardBody from "./Card/CardBody";
import Card from "./Card/Card";
import {useTranslation} from "react-i18next";
import AuthService from "../services/auth.service";
import {useHistory} from "react-router-dom";
import {RegisterAlertModal} from "./modals/RegisterAlertModal";
import authHeader from "../services/auth-header";
import axios from "axios";
import GeneralFeedbackModal from "./modals/GeneralFeedbackModal";
import StudyGroupModal from "./modals/StudyGroupModal";
import ContactUsModal from "./modals/ContactUsModal";
import IconBox from "./Icons/IconBox";
import {AiFillApple} from "react-icons/ai";

function PromotionPanel(props) {
    const [linkCopied, setLinkCopied] = useState(false);
    const {t, i18n} = useTranslation();

    return (
        <Flex m="5px" flexDirection="column">
            <Heading as='h8' size='md' textTransform='uppercase'>
                {props.shareChannels}
            </Heading>
            <Text my='25px' fontSize='sm' whiteSpace="pre-line">
                {props.shareRules}
            </Text>
            <Flex flexDirection="column" flex="1" bgColor="#FFECD1" px="10px">
                <Text pt='2' fontSize='sm' my="10px" p="5px" whiteSpace="pre-line">
                    {props.shareMessage}
                </Text>
                <Box flex="1"></Box>
                <Flex flexDirection="row" mb="10px">
                    <Box flex="1"></Box>
                    <Button
                        mx="10px"
                        colorScheme="yellow"
                        bgColor="#F96730"
                        color="white"
                        display={props.copiable ? "block" : "none"}
                        onClick={() => {
                            navigator.clipboard.writeText(props.shareMessage);
                            setLinkCopied(true);
                            setTimeout(() => {
                                setLinkCopied(false);
                            }, "2000");
                        }}
                    >
                        {linkCopied ? t("already_copied") : t("copy")}
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    )
}

export function QuickLinksPanel(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const {t, i18n} = useTranslation();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const currentUser = AuthService.getCurrentUser();
    let history = useHistory();
    const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
    const [referralCode, setReferralCode] = useState('');
    const {isOpen: isOpenRegisterAlert, onOpen: onOpenRegisterAlert, onClose: onCloseRegisterAlert} = useDisclosure();
    const [shareLink, setShareLink] = useState('');
    const [linkCopied, setLinkCopied] = useState(false);
    const {isOpen: isOpenFeedback, onOpen: onOpenFeedback, onClose: onCloseFeedback} = useDisclosure()
    const {
        isOpen: isOpenStudyGroupTOEFL,
        onOpen: onOpenStudyGroupTOEFL,
        onClose: onCloseStudyGroupTOEFL
    } = useDisclosure()
    const {
        isOpen: isOpenStudyGroupIELTS,
        onOpen: onOpenStudyGroupIELTS,
        onClose: onCloseStudyGroupIELTS
    } = useDisclosure()
    const {isOpen: isOpenContactUs, onOpen: onOpenContactUs, onClose: onCloseContactUs} = useDisclosure()


    useEffect(() => {
        if (!currentUser) {
            return;
        }
        let headers = authHeader();
        axios.get(API_URL + '/api/get_referral', {headers: headers}).then((response) => {
            setReferralCode(response.data);
            setShareLink(`${API_URL}/register?r=${response.data}`);
        })
    }, []);  // <-- empty dependency array to run only once on mount

    return (
        <Card
            overflowX={{sm: "scroll", xl: "hidden"}}
        >
            <CardHeader p="6px 0px 22px 0px">
                <Flex direction="column">
                    <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
                        {t("quick_links")}
                    </Text>
                </Flex>
            </CardHeader>
            <CardBody>
                <Center>
                    <Flex flexDirection="column">
                        <Center width="100%">
                            <Button
                                bgGradient="linear(to-r, orange.400,#D83531)"
                                color='white'
                                width="100%"
                                // ml="-100px"
                                // mb="55px"
                                // mt={2}
                                borderRadius='20px'
                                onClick={() => {
                                    if (!AuthService.getCurrentUser()) {
                                        onOpenRegisterAlert();
                                        return;
                                    }
                                    history.push("/purchase?_lltrck=nav_btn");
                                }}
                            >{t('purchase_credits')}</Button>
                        </Center>
                        <Box m="3px"></Box>
                        <Center width="100%">
                            <Button
                                width="100%"
                                onClick={() => {
                                    if (!AuthService.getCurrentUser()) {
                                        onOpenRegisterAlert();
                                        return;
                                    }
                                    onOpen();
                                }} colorScheme='yellow' borderRadius='20px'
                            >{t("get_free_credits")}</Button>
                        </Center>
                        <Flex flexDirection={'column'}>
                            <Box m="3px"></Box>
                            <Center width="100%">
                                <Button
                                    width="100%"
                                    height={'50px'}
                                    colorScheme='gray'
                                    style={{backgroundColor: 'black', color: 'white'}}
                                    borderRadius='12px'
                                    onClick={() => {
                                        if (!AuthService.getCurrentUser()) {
                                            onOpenRegisterAlert();
                                            return;
                                        }
                                        window.open("https://apps.apple.com/us/app/lingoleap-ai-toefl-prep/id6480381514?platform=iphone", "_blank");
                                    }}
                                    leftIcon={
                                        <IconBox
                                            color="inherit"
                                            h="45px"
                                            w="45px"
                                            borderRadius="50%"
                                            border="2px solid gray"
                                            style={{marginLeft: '-12px'}}
                                        >
                                            <AiFillApple
                                                fontSize='30px'
                                                style={{marginTop: '-4px'}}
                                            />
                                        </IconBox>
                                    }
                                >
                                    <span style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        letterSpacing: '1px',
                                    }}>
                                        <span style={{
                                            fontSize: i18n.language == "cn" ? '10px' : '8px',
                                            color: 'gray'
                                        }}>{t("available on the")}</span>
                                        Apple  Store
                                    </span>
                                </Button>
                            </Center>
                        </Flex>

                        <Box m="15px"></Box>
                        <Center width="100%">
                            <Button
                                colorScheme='blue'
                                width="100%"
                                borderRadius='20px'
                                onClick={onOpenStudyGroupTOEFL}
                            >{t('study_group_toefl')}</Button>
                            <StudyGroupModal
                                isOpen={isOpenStudyGroupTOEFL} onOpen={onOpenStudyGroupTOEFL}
                                onClose={onCloseStudyGroupTOEFL} project='toefl'
                            ></StudyGroupModal>
                        </Center>
                        <Box m="3px"></Box>
                        <Center width="100%">
                            <Button
                                colorScheme='blue'
                                width="100%"
                                borderRadius='20px'
                                onClick={onOpenStudyGroupIELTS}
                            >{t('study_group_ielts')}</Button>
                            <StudyGroupModal
                                isOpen={isOpenStudyGroupIELTS} onOpen={onOpenStudyGroupIELTS}
                                onClose={onCloseStudyGroupIELTS} project='ielts'
                            ></StudyGroupModal>
                        </Center>
                        <Box m="3px"></Box>
                        <Center width="100%">
                            <Button
                                colorScheme='blue'
                                width="100%"
                                borderRadius='20px'
                                onClick={onOpenContactUs}
                            >{t('contact_us')}</Button>
                            <ContactUsModal
                                isOpen={isOpenContactUs} onOpen={onOpenContactUs} onClose={onCloseContactUs}
                                title={'contact_us_message'}
                            ></ContactUsModal>

                        </Center>
                        <Box m="3px"></Box>
                        <Center width="100%">
                            <Button
                                colorScheme='blue'
                                width="100%"
                                borderRadius='20px'
                                onClick={onOpenFeedback}
                            >{t('feedback_button_text')}</Button>
                            <GeneralFeedbackModal
                                isOpen={isOpenFeedback} onOpen={onOpenFeedback} onClose={onCloseFeedback}
                            ></GeneralFeedbackModal>
                        </Center>
                    </Flex>
                </Center>
                <RegisterAlertModal isOpen={isOpenRegisterAlert} onClose={onCloseRegisterAlert}></RegisterAlertModal>
                <Modal isOpen={isOpen} onClose={onClose} width="800px">
                    <ModalOverlay/>
                    <ModalContent maxWidth="66rem" bgColor="#F9F6E7" borderRadius="3xl">
                        <ModalHeader>{t("backtoschool_title0")}</ModalHeader>
                        <ModalCloseButton/>
                        <ModalBody>
                            {i18n.language == "cn" ?
                                <Flex flexDirection="column">
                                    <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}} gap={6}>
                                        <PromotionPanel
                                            shareChannels={t("backtoschool_title1")}
                                            shareRules={t("share_rules_qq_wechat")}
                                            shareMessage={t("share_message_qq_wechat") + " " + shareLink}
                                            copiable={true}
                                        ></PromotionPanel>
                                        <PromotionPanel
                                            shareChannels={t("backtoschool_title2")}
                                            shareRules={t("share_rules_forum")}
                                            shareMessage={t("share_message_forum")}
                                            copiable={false}
                                        ></PromotionPanel>
                                        <PromotionPanel
                                            shareChannels={t("backtoschool_title3")}
                                            shareRules={t("share_rules_chinese_social_media")}
                                            shareMessage={t("share_message_chinese_social_media")}
                                            copiable={false}
                                        ></PromotionPanel>
                                    </Grid>
                                    <Flex flexDirection="row" m="10px">
                                        <Box flex="1"></Box>
                                        <Text>{t("backtoschool_footnote")}</Text>
                                    </Flex>
                                </Flex> :
                                <Flex flexDirection="column">
                                    <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap={6}>
                                        <PromotionPanel
                                            shareChannels={t("backtoschool_title1")}
                                            shareRules={t("share_rules_invite")}
                                            shareMessage={t("share_message_invite") + " " + shareLink}
                                            copiable={true}
                                        ></PromotionPanel>
                                        <PromotionPanel
                                            shareChannels={t("backtoschool_title2")}
                                            shareRules={t("share_rules_forum")}
                                            shareMessage={t("share_message_forum")}
                                            copiable={false}
                                        ></PromotionPanel>
                                    </Grid>
                                    <Flex flexDirection="row" m="10px">
                                        <Box flex="1"></Box>
                                        <Text>{t("backtoschool_footnote")}</Text>
                                    </Flex>
                                </Flex>
                            }
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </CardBody>
        </Card>
    );
}
