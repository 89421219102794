import React from "react";
import { selectIeltsData, updateTabIndex } from "redux/ieltsSlice";
import { useSelector } from "react-redux";
import { FiBookmark, FiMonitor, FiArchive } from "react-icons/fi";
import { mapIeltsTabIndex } from "./utils/common";
import QuestionListPanel from "./QuestionListPanel";
import { RightPanel } from "./RightPanel";

export function IeltsRightPanel(props) {
    const ieltsData = useSelector(selectIeltsData);
    const tabIndex = ieltsData.tabIndex;
    var tabList = [
        {
            "icon": FiMonitor,
            "display": true,
            "title": "QuestionBank",
            "showName": "question_bank",
            "component": <QuestionListPanel test='ielts' section={ieltsData.section} questionSet={"realExam"} hideTags={false} />
        },
        {
            "icon": FiBookmark,
            "display": true,
            "title": "Jianya",
            "showName": "jianya",
            "component": <QuestionListPanel test='ielts' section={ieltsData.section} questionSet={"jianya"} hideTags={false} />
        },
        {
            "icon": FiArchive,
            "display": true,
            "title": "previous_season_questions",
            "showName": "Previous season question",
            "component": <QuestionListPanel test='ielts' section={ieltsData.section} tags={["Previous+season+question"]} hideTags={true} /> //tags keyword filter question by tags
        },
    ]
    return <>
        <RightPanel tabIndex={tabIndex} updateTabIndexfunc={updateTabIndex} mapTabIndexfunc={mapIeltsTabIndex} tabList={tabList} />
    </>
}