import {
    Box,
    Button, Flex,
    Stack,
    useColorMode,
    useColorModeValue,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import AuthService from "../../services/auth.service";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectGid} from "../../redux/shareSlice";

export function SidebarExtras(props) {
    // Pass the computed styles into the `__css` prop
    const {children, sidebarVariant, ...rest} = props;
    const textColor = useColorModeValue("gray.700", "white");
    const {colorMode} = useColorMode();
    let history = useHistory();
    const {t, i18n} = useTranslation();

    const gid = useSelector(selectGid);

    return (
        <Stack
            justify='center'
            direction='column'
            align='center'
            spacing='20px'
            mb="22px"
            mt="auto"
            mx='20px'
        >
            <Flex flexDirection="row" width="full">
                {AuthService.getCurrentUser() ? (
                    <Button
                        variant='primary' minW='50%' fontSize='14px'
                        onClick={() => {
                            AuthService.logout();
                            history.push("/");
                        }}
                    >
                        {t("log_out")}
                    </Button>) : (
                    <Button
                        variant='primary' minW='50%' fontSize='14px'
                        onClick={() => {
                            history.push("/register?gid=" + gid);
                        }}
                    >
                        {t("register")}
                    </Button>)
                }
                <Box flex="1"></Box>
            </Flex>
        </Stack>
    );
}
