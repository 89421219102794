import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as EyeOutlineSVG } from '../../../assets/login1.2_img/eye_outline.svg';
import { ReactComponent as EyeSlashSVG } from '../../../assets/login1.2_img/eye_slash.svg';
import axios from "axios";
import debounce from "lodash/debounce";
import {
    FormControl,
    Input,
    InputGroup,
    InputRightElement,
    Box,
    FormErrorMessage,
    Link,
    Flex,
    VStack,
    Text,
} from '@chakra-ui/react'
import { SignupZone } from "../SignUpZone/signupZone";

const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;

export const EmailRegistry = (props) => {
    const { t } = useTranslation();
    const [showPass, setShowPass] = useState(false)
    const [mail, setMail] = useState('')
    const [password, setPassword] = useState('')
    const [passErr, setPassErr] = useState(false)
    const [mailErr, setMailErr] = useState(false)
    const [invitationCode, setInvitationCode] = useState(props.props.invitationCode)
    const [loginErr, setLoginErr] = useState('')
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isEmailFocused, setIsEmailFocused] = useState(false);
    const [isEmailAvailable, setIsEmailAvailable] = useState(true);
    const [loginError, setLoginError] = useState(false);


    useEffect(() => {
        if (props.props.invitationCode !== '') {
            console.log('props', props)
            setInvitationCode(props.props.invitationCode);
        }
    }, [props]);

    const inputStyles = {
        color: "#232323",
        fontWeight: 'medium',
        fontSize: '16px',
        _placeholder: { color: '#847E7C', fontWeight: 'medium', fontSize: '16px' },
        border: '2px solid #E4E6E6',
        height: "53px",
        _focus: { 
            borderColor: "#232323",
            color: "#232323"
        },
    };

    const isValidEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const clearLoginError = () => {
        if (props.props.isLogin) {
            setLoginError(false);
            setMailErr(false);
            setPassErr(false);
            setEmailError('');
            setPasswordError('');
        }
    };

    useEffect(() => {
        // This effect runs when isLogin prop changes (i.e., when switching between signup and login modes)
        resetErr();
        setLoginError(false);
        if (mail) {
            validateEmail(true);
        }
    }, [props.props.isLogin]);

    const resetErr = () => {
        setPassErr(false);
        setMailErr(false);
        setEmailError('');
        setPasswordError('');
    }

    const validateEmail = (isModeSwitching = false) => {
        if (!mail.trim()) {
            setMailErr(true);
            setEmailError("emailRegistry.emailRequired");
            return false;
        } else if (!isValidEmail(mail)) {
            setMailErr(true);
            setEmailError("emailRegistry.emailInvalid");
            return false;
        }

        if (!props.props.isLogin) {
            // Check availability in signup mode, even when switching modes
            checkEmailAvailability(mail);
        } else {
            setMailErr(false);
            setEmailError('');
        }

        return true;
    }

    const checkEmailAvailability = useCallback(
        debounce(async (email) => {
            if (!props.props.isLogin && email && isValidEmail(email)) {
                try {
                    const response = await axios.post(API_URL + '/api/send_totp', { 
                        email: email,
                        routeName: 'test' // Use 'test' instead of 'signup' to avoid sending verification code
                    });
                    
                    if (response.status === 200) {
                        console.log("The verification code has been sent successfully");
                        setIsEmailAvailable(true);
                        setMailErr(false);
                        setEmailError("");
                    }
                } catch (error) {
                    console.error("Error checking email:", error);
                    setIsEmailAvailable(false);
                    setMailErr(true);
                    let errMsg = error.response?.data?.error || "An error occurred while checking the email.";
                    if (errMsg === "User already exists") {
                        errMsg = "emailRegistry.emailInUse";
                    }
                    setEmailError(errMsg);
                }
            }
        }, 300),
        [props.props.isLogin]
    );

    const validateInputs = () => {
        let isValid = validateEmail();

        if (!password.trim()) {
            setPassErr(true);
            setPasswordError("emailRegistry.passwordRequired");
            isValid = false;
        } else {
            setPassErr(false);
            setPasswordError("");
        }

        return isValid;
    }

    const handleLoginResult = async (loginInfo) => {
        resetErr();
        setLoginError(false);

        let hasError = false;

        // Validate email
        if (!validateEmail()) {
            hasError = true;
        }

        // Validate password
        if (!password.trim()) {
            setPassErr(true);
            setPasswordError('emailRegistry.passwordRequired');
            hasError = true;
        }

        // If there are any errors, return early
        if (hasError) {
            return;
        }

        if (props.props.isLogin) {
            // Login mode
            try {
                const response = await axios.post(API_URL + '/api/login', { 
                    email: mail,
                    password: password
                });
                
                if (response.status === 200) {
                    // Login successful
                    const prop = props.props
                    const callback = prop.callback
                    callback({
                        type: "login",
                        result: "ok",
                        email: mail,
                    })
                }
            } catch (error) {
                console.error("Login error:", error);
                setLoginError(true);
                setMailErr(true);
                setPassErr(true);
                setPasswordError("emailRegistry.incorrectCredentials");
            }
        } else {
            // Signup mode

                    const prop = props.props
                    const callback = prop.callback
                    callback({
                        type: "mail",
                        result: "ok",
                        email: mail, // Change 'mail' to 'email' for consistency
                        password,
                        invitationCode,
                    })
        }
    }

    return (
        <Flex direction="column" h="90%" overflow="auto" w="100%">
            <VStack spacing={4} align="stretch" flex={1}>
                <FormControl isInvalid={mailErr || loginError}>
                    <Text as="label" mt="3px" fontWeight="semibold" fontSize="14px" mb={2} display="block">
                        {t('emailRegistry.email')}
                    </Text>
                    <Box px="1px">
                        <Input
                            type='email'
                            placeholder={t('emailRegistry.emailPlaceholder')}
                            value={mail}
                            onChange={(e) => {
                                setMail(e.target.value)
                                setMailErr(false)
                                setEmailError('')
                            }}
                            onFocus={() => {
                                setIsEmailFocused(true)
                                clearLoginError()
                            }}
                            onBlur={() => {
                                setIsEmailFocused(false);
                                if (validateEmail()) {
                                    checkEmailAvailability(mail);
                                }
                            }}
                            {...inputStyles}
                            autoComplete="new-password"
                            borderColor={isEmailFocused ? "#232323" : "#E4E6E6"}
                            _hover={ {bg: "#F7F7F7"} }
                            bg={mailErr ? "#FDF2F2" : "white"}
                            _focus={{
                                borderColor: mailErr ? "#C81E1E" : "#232323",
                                boxShadow: "none"
                            }}
                            sx={{
                                '&[aria-invalid=true]': {
                                    borderColor: '#C81E1E',
                                    boxShadow: 'none',
                                    _focus: {
                                        borderColor: '#C81E1E',
                                        boxShadow: 'none',
                                    }
                                }
                            }}
                        />
                        {(mailErr && !loginError) && (
                            <FormErrorMessage fontSize="14px" fontWeight="medium" color="#C81E1E">
                                {t(emailError)}
                            </FormErrorMessage>
                        )}
                    </Box>
                </FormControl>

                <FormControl isInvalid={passErr || loginError}>
                    <Text as="label" mt="3px" fontWeight="semibold" fontSize="14px" mb={2} display="block">
                        {t('emailRegistry.password')}
                    </Text>
                    <Box px="1px">
                        <InputGroup>
                            <Input
                                pr='4.5rem'
                                type={showPass ? 'text' : 'password'}
                                placeholder={t('emailRegistry.passwordPlaceholder')}
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                    setPassErr(false)
                                    setPasswordError('')
                                }}
                                onFocus={() => {
                                    clearLoginError()
                                }}
                                onBlur={() => {
                                    if (!password.trim()) {
                                        setPassErr(true)
                                        setPasswordError('emailRegistry.passwordRequired')
                                    }
                                }}
                                {...inputStyles}
                                autoComplete="new-password"
                                borderColor={passErr ? "#C81E1E" : "#E4E6E6"}
                                _hover={ {bg: "#F7F7F7"} }
                                bg={passErr ? "#FDF2F2" : "white"}
                                _focus={{
                                    borderColor: passErr ? "#C81E1E" : "#232323",
                                    boxShadow: "none"
                                }}
                                sx={{
                                    '&[aria-invalid=true]': {
                                        borderColor: '#C81E1E',
                                        boxShadow: 'none',
                                        _focus: {
                                            borderColor: '#C81E1E',
                                            boxShadow: 'none',
                                        }
                                    }
                                }}
                            />
                            <InputRightElement width='2.5rem' height="100%">
                                <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
                                    <Box 
                                        as="button" 
                                        onClick={() => setShowPass(!showPass)}
                                        aria-label={showPass ? "Hide password" : "Show password"}
                                    >
                                        {showPass ? <EyeOutlineSVG /> : <EyeSlashSVG />}
                                    </Box>
                                </Flex>
                            </InputRightElement>
                        </InputGroup>
                        {(passErr || loginError) && (
                            <FormErrorMessage fontSize="14px" fontWeight="medium" color="#C81E1E">
                                {t(passwordError || 'emailRegistry.incorrectCredentials')}
                            </FormErrorMessage>
                        )}
                    </Box>
                </FormControl>

                {!props.props.isLogin && (
                    <FormControl>
                        <Text as="label" mt="3px" fontWeight="semibold" fontSize="14px" mb={2} display="block">
                            {t('emailRegistry.invitationCode')}
                            <Box as="span" ml={1} color='#B0B0B0' fontSize={14} fontWeight={600}>
                                {t('emailRegistry.optional')}
                            </Box>
                        </Text>
                        <Box px="1px">
                            <Input
                                type='string'
                                placeholder={t('emailRegistry.invitationCodePlaceholder')}
                                value={invitationCode}
                                onChange={(e) => setInvitationCode(e.target.value)}
                                {...inputStyles}
                                autoComplete="new-password"
                                borderColor="#E4E6E6"
                                _hover={ {bg: "#F7F7F7"} }
                            />
                        </Box>
                    </FormControl>
                )}

                {props.props.isLogin && (
                    <Link
                        onClick={() => {
                            const callback = props.props.callback
                            callback({
                                type: "resetPass",
                                from: "mail"
                            })
                        }}
                        color="#6062FF"
                        _hover={{ color: "#5145CD" }}
                        alignSelf="flex-start"
                        fontFamily="THICCCBOI, sans-serif"
                        mt="3px"
                        fontWeight="medium"
                    >
                        {t('emailRegistry.forgotPassword')}
                    </Link>
                )}

                <Box mt={6}>
                    <SignupZone props={{
                        login: {
                            loginType: "mail",
                            mail,
                            password,
                            invitationCode,
                            handleLoginResult,
                            isLogin: props.props.isLogin,
                        }
                    }} />
                </Box>
            </VStack>
        </Flex>
    )
}