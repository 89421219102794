import React from "react";
import { useTranslation } from 'react-i18next';
import { Text, Link, Box } from '@chakra-ui/react';

export const TermOfUse = () => {
    const { t } = useTranslation();

    return (
        <Box textAlign="center" mt={4}>
            <Text fontSize="14px" fontWeight="medium" color="#545454">
                {t('termsOfUse.text')}{' '}
                <Link
                    href="https://lingoleap.ai/terms-of-use"
                    isExternal
                    fontWeight="semibold"
                    color="#232323"
                    _hover={{ color: "#000000", textDecoration: "underline" }}
                    textDecoration="none"
                >
                    {t('termsOfUse.termsLink')}
                </Link>
                {' '}{t('termsOfUse.and')}{' '}
                <Link
                    href="https://lingoleap.ai/privacy-policy"
                    isExternal
                    fontWeight="semibold"
                    color="#232323"
                    _hover={{ color: "#000000", textDecoration: "underline" }}
                    textDecoration="none"
                >
                    {t('termsOfUse.privacyLink')}
                </Link>
                .
            </Text>
        </Box>
    );
};