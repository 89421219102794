import axios from "axios";
import React, {useState} from 'react';
import { useParams } from 'react-router-dom';
import {withRouter} from "react-router";
import { PasswordField } from '../components/PasswordField'
import { useTranslation } from 'react-i18next';

import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input, Link,
    Stack,
    Text,
} from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react';
import Figure from "react-bootstrap/Figure";
import logoPng from "../assets/logo_long.png";


export function PasswordResetPage(props) {
    const { t, i18n } = useTranslation();

    let [password, setPassword] = useState('');
    let params = new URLSearchParams(window.location.search);
    let token = params.get("token")
    //let { token } = useParams();
    const toast = useToast()

    const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;

    console.log("token", token);

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = await axios.post(API_URL + '/api/reset_password', { token, password });
            //alert(response.data.message);
            toast({
                title: "Password Reset",
                description: response.data.message,
                status: "success",
                duration: 3500,
                isClosable: true,
                onCloseComplete: () => {
                    window.location.href = '/login'
                },
            });
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
        <Stack spacing="8">
            <Stack direction='row' spacing='12px' align='center' justify='center' bgColor='gray.500' p='10px' borderRadius='xl'>
                <Link href='/'>
                    <Box >
                        <Figure.Image className="circlex-logo" width={200} height={50} src={logoPng}
                                      style={{marginBottom: "0px"}}/>
                    </Box>
                </Link>
            </Stack>
            <Stack spacing="6">
                <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                    <Heading size={{ base: 'xs', md: 'sm' }}>{props.isSetPassword ? t('set_password') : t('reset_password')}</Heading>
                    <HStack spacing="1" justify="center">
                        <Text color="muted" style={{ marginBottom: "0px" }}>{t('registration_already_registered')}?</Text>
                        <Button variant="link" colorScheme="blue" onClick={event => window.location.href = '/login'}>
                            {t('login_now')}
                        </Button>
                    </HStack>
                </Stack>
            </Stack>
            <Box
                py={{ base: '0', sm: '8' }}
                px={{ base: '4', sm: '10' }}
                bg={{ base: 'transparent', sm: 'bg-surface' }}
                boxShadow="0 2px 12px 0 rgb(0 0 0 / 16%)"
                borderRadius={{ base: 'none', sm: 'xl' }}
            >
                <Stack spacing="6">
                    <Stack spacing="5">
                        <FormControl>
                            <FormLabel htmlFor="email">{t('new_password')}</FormLabel>
                            <Input type="password" onChange={(e) => setPassword(e.target.value)} />
                        </FormControl>
                    </Stack>
                    <Stack spacing="6">
                        <Button colorScheme='blue'
                            onClick={handleSubmit}
                        >{props.isSetPassword ? t('set_password') : t('reset_password')}</Button>
                    </Stack>
                </Stack>
            </Box>
        </Stack>
        </Container>
    );
}

export default withRouter(PasswordResetPage);
