import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {cmApi} from "../services/cmApi";

const initialState = {
    rightSideContent: "ielts",
    ipb_lists: {
        loading: false,
        ispeaking_part1: [],
        ispeaking_part2_3: [],
        iwriting_task1: [],
        iwriting_task2: [],
    },
    ieltsData: {
        tabIndex: 0,
        section: 'speaking',
        selectedPart: 1,
        completionStatus: "all",
        topics: [],
        qid: -1,
        questions: [],
        questionText: "",
        answerText: "",
        currentQuestionIdx: 0,
        direction: '',
        userAudios: {},
        userAudioNames: {},
        userInputReady: false,
        isStreaming: false,
        hasStreamingError: false,
        abortController: new AbortController(),
        stream_short_id: null,
        shouldShowMarkdown: false,
        gid: ""
    },
    markdownResponse: {}
};

export const ieltsSlice = createSlice({
    name: 'ielts',
    initialState,
    reducers: {
        updateTabIndex: (state, action) => {
            state.ieltsData.tabIndex = action.payload;
            if (state.ieltsData.tabIndex === 3) {
                // init the default part and topic
                state.ieltsData.topics = state.ipb_lists.ispeaking_part1;
                state.ieltsData.qid = state.ipb_lists.ispeaking_part1[0].id;
                state.ieltsData.questions = [];
                state.ieltsData.selectedPart = 1;
            }
            state.userInputReady = false;
            state.ieltsData.userAudios = {};
            state.ieltsData.userAudioNames = {};
            state.ieltsData.currentQuestionIdx = 0;
            state.ieltsData.questions = [];
            state.ieltsData.userInputReady = false;
            state.ieltsData.gid = action.payload.gid;

        },
        updateTabIndexNoReset: (state, action) => {
            state.ieltsData.tabIndex = action.payload;
        },
        abortGeneration: (state, action) => {
            state.ieltsData.abortController.abort();
            state.ieltsData.abortController = new AbortController()
            state.ieltsData.stream_short_id = null;
            state.ieltsData.isStreaming = false;
        },
        updateSelectedPart: (state, action) => {
            state.ieltsData.selectedPart = parseInt(action.payload);
            if (action.payload == 1 && state.ieltsData.section == "speaking") {
                state.ieltsData.qid = state.ipb_lists.ispeaking_part1[0].id;
                state.ieltsData.topics = state.ipb_lists.ispeaking_part1;
            } else if (action.payload == 2 && state.ieltsData.section == "speaking") {
                state.ieltsData.qid = state.ipb_lists.ispeaking_part2_3[0].id;
                state.ieltsData.topics = state.ipb_lists.ispeaking_part2_3;
            } else if (action.payload == 1 && state.ieltsData.section == "writing") {
                state.ieltsData.qid = state.ipb_lists.iwriting_task1[0].id;
                state.ieltsData.topics = state.ipb_lists.iwriting_task1;
            } else if (action.payload == 2 && state.ieltsData.section == "writing") {
                state.ieltsData.qid = state.ipb_lists.iwriting_task2[0].id;
                state.ieltsData.topics = state.ipb_lists.iwriting_task2;
            }
            state.ieltsData.questions = [];
            state.ieltsData.currentQuestionIdx = 0;
            state.ieltsData.userAudios = {};
            state.ieltsData.userAudioNames = {};
            state.ieltsData.userInputReady = false;
            state.ieltsData.gid = action.payload.gid;
        },
        updatePart: (state, action) => {
            state.ieltsData.selectedPart = parseInt(action.payload);
            state.ieltsData.questions = [];
            state.ieltsData.currentQuestionIdx = 0;
            state.ieltsData.userAudios = {};
            state.ieltsData.userAudioNames = {};
            state.ieltsData.userInputReady = false;
        },
        updateQid: (state, action) => {
            state.ieltsData.qid = action.payload;
        },
        updateSelectedQuestionId: (state, action) => {
            state.ieltsData.qid = action.payload;
            state.ieltsData.questions = [];
            state.ieltsData.currentQuestionIdx = 0;
            state.ieltsData.userAudios = {};
            state.ieltsData.userAudioNames = {};
            state.ieltsData.userInputReady = false;
            state.ieltsData.gid = action.payload.gid;

        },
        updateCurrentQuestionIdx: (state, action) => {
            state.ieltsData.currentQuestionIdx = action.payload;
        },
        updateCompletionStatus: (state, action) => {
            state.ieltsData.completionStatus = action.payload;
        },
        addUserAudio: (state, action) => {
            const data = action.payload.data;
            const q_key = action.payload.key;
            const audio_name = action.payload.audio_name; // audio file name stored on CDN
            state.ieltsData.userAudios[q_key] = data;
            state.ieltsData.userAudioNames[q_key] = audio_name;
        },
        clearAnswers: (state, action) => {
            state.ieltsData.userAudios = {};
            state.ieltsData.userAudioNames = {};
            state.ieltsData.questionText = "";
            state.ieltsData.questions = [];
            state.ieltsData.currentQuestionIdx = 0;
            state.ieltsData.userInputReady = false;
            state.markdownResponse = {};
            state.ieltsData.shouldShowMarkdown = false;
            state.ieltsData.part_id = 0;
            state.ieltsData.qid = "";
            state.ieltsData.answerText = "";
        },
        updateUserInputReady: (state, action) => {
            state.ieltsData.userInputReady = action.payload;
        },
        updateQuestions: (state, action) => {
            state.ieltsData.questions = action.payload;
        },
        updateQuestionText: (state, action) => {
            state.ieltsData.questionText = action.payload;
        },
        updateIsStreaming: (state, action) => {
            if (state.ieltsData.isStreaming && !action.payload) { // If just finished streaming
                state.ieltsData.shouldShowMarkdown = true;
            }
            if (action.payload) {
                state.ieltsData.hasStreamingError = false;
            }
            state.ieltsData.isStreaming = action.payload;
        },
        appendResponseTrunk: (state, action) => {
            if (!state.markdownResponse.result) {
                state.markdownResponse.result = "";
            }
            if (action.payload.sid !== state.ieltsData.stream_short_id) {
                return;
            }

            if (action.payload.c.startsWith('<!-- diff_start -->')) {
                // Special message for diff content. We need to replace all content from
                // `<!-- content_start -->` to here with content after `<!-- diff_start -->`
                let diff = action.payload.c.split('<!-- diff_start -->')[1];
                let result = state.markdownResponse.result
                if (result.includes('<!-- content_start -->')) {
                    // Found special content tag
                    console.log("replacing old content with diff")
                    state.markdownResponse.result = result.split('<!-- content_start -->')[0] + diff
                } else {
                    // No special tag found, can't replace diff
                    console.log("Trying to replacing old content with diff but no <!-- content_start --> found")
                }
            } else {
                // Regular message
                state.markdownResponse.result += action.payload.c.replaceAll("\\n", "\n");
            }

        },
        clearResponse: (state, action) => {
            state.ieltsData.stream_short_id = action.payload.sid;
            state.markdownResponse = {};
            state.ieltsData.gid = action.payload.gid;
            state.ieltsData.shouldShowMarkdown = false;
        },
        handleErrorResponse: (state, action) => {
            if (action.payload.c.sid !== state.ieltsData.stream_short_id) {
                return;
            }
            state.markdownResponse.result = action.payload.c.message;
            state.ieltsData.hasStreamingError = true;
        },
        updateIeltsSection: (state, action) => {
            state.ieltsData.section = action.payload;
            state.ieltsData.selectedPart = state.ieltsData.section === "speaking" ? 1 : 2
            state.ieltsData.targetScore = "30";
            state.ieltsData.answerOutline = "";
            state.markdownResponse = {};
            state.ieltsData.shouldShowMarkdown = false;
            state.ieltsData.stream_short_id = null;
            state.ieltsData.isStreaming = false;
            state.ieltsData.selectedExampleLevel = "";
            if (state.ipb_lists.ispeaking_part1.length > 0 && state.ipb_lists.iwriting_task2.length > 0) {
                state.ieltsData.qid = state.ieltsData.section === "speaking" ? state.ipb_lists.ispeaking_part1[0].id : state.ipb_lists.iwriting_task2[0].id
                state.ieltsData.topics = state.ieltsData.section === "speaking" ? state.ipb_lists.ispeaking_part1 : state.ipb_lists.iwriting_task2;
            }
        },
        updateQuestion: (state, action) => {
            state.ieltsData.questionText = action.payload;
        },
        updateAnswer: (state, action) => {
            state.ieltsData.answerText = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            cmApi.endpoints.getQuestionByQid.matchFulfilled,
            (state, {payload}) => {
                console.log("payload.data", payload.data)
                state.ieltsData.qid = payload.data.qid;
                state.ieltsData.selectedPart = parseInt(payload.data.task_name[payload.data.task_name.length - 1]);
                console.log("state.ieltsData.selectedPart", state.ieltsData.selectedPart)
                if (payload.data.gen_type.includes('speaking')) {
                    state.ieltsData.section = 'speaking'
                } else if (payload.data.gen_type.includes('writing')) {
                    state.ieltsData.section = 'writing'
                }
                state.ieltsData.questions = payload.data.questions.map(item => item.question);
            }
        )
    },
});

export const {
    updateTabIndex,
    updateTabIndexNoReset,
    updateSelectedPart,
    updatePart,
    updateQid,
    updateIeltsSection,
    updateSelectedQuestionId,
    updateCurrentQuestionIdx,
    updateCompletionStatus,
    addUserAudio,
    clearAnswers,
    updateUserInputReady,
    updateQuestions,
    updateQuestionText,
    appendResponseTrunk,
    updateIsStreaming,
    clearResponse,
    handleErrorResponse,
    updateQuestion,
    updateAnswer
} = ieltsSlice.actions;


export const selectIeltsData = (state) => state.ielts.ieltsData;
export const selectIpbLists = (state) => state.ielts.ipb_lists;
export const selectRightSideContent = (state) => state.ielts.rightSideContent;
export const selectMarkdownResponse = (state) => state.ielts.markdownResponse;

export default ieltsSlice.reducer;

