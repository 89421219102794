import React, {useEffect, useState} from "react";
import {Button, Flex, Text, useColorModeValue,} from "@chakra-ui/react";
import CardHeader from "./Card/CardHeader";
import CardBody from "./Card/CardBody";
import Card from "./Card/Card";
import authHeader from "../services/auth-header";
import axios from "axios";

export function AdminMiscellaneousPanel(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;

    return (
        <Card
            overflowX={{sm: "scroll", xl: "hidden"}}
        >
            <CardHeader p="6px 0px 22px 0px">
                <Flex direction="column">
                    <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
                        Miscellaneous
                    </Text>
                </Flex>
            </CardHeader>
            <CardBody>
                <Flex gap={20}>
                    <Button
                        ml="10px"
                        colorScheme='blue'
                        onClick={() => {
                            axios.get(
                                `${API_URL}/api/admin_export_question_bank`,
                                {headers: authHeader(), responseType: 'blob'}
                            ).then((response) => {
                                const href = URL.createObjectURL(response.data);
                                // create "a" HTML element with href to file & click
                                const link = document.createElement('a');
                                link.href = href;
                                let filename = 'question_bank_'+new Date().toLocaleString('sv-SE').replaceAll('-', "").replaceAll(':', "").replaceAll(" ", "_")+'.json'

                                link.setAttribute('download', filename); //or any other extension
                                document.body.appendChild(link);
                                link.click();

                                // clean up "a" element & remove ObjectURL
                                document.body.removeChild(link);
                                URL.revokeObjectURL(href);

                            });

                        }}
                    >
                        Export Question Bank as JSON
                    </Button>
                </Flex>
            </CardBody>
        </Card>
    );
}