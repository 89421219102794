import React, {useEffect, useState} from "react";
import {Box, Flex} from '@chakra-ui/react';
import {Route, Switch, useHistory} from 'react-router-dom';
import {getRouteInfo, ToeflProvider} from '../../components/toeflIbt/ToeflIbtContext';
import ToeflIbtMenu from '../../components/toeflIbt/ToeflIbtMenu';
import ToeflListeningDirections from '../../components/toeflIbt/ToeflListeningDirections';
import ToeflListeningAudio from '../../components/toeflIbt/ToeflListeningAudio';
import ToeflListeningQuestion from '../../components/toeflIbt/ToeflListeningQuestion';
import ToeflListeningAnswer from "../../components/toeflIbt/ToeflListeningAnswer";
import ToeflListeningAudioRe from "../../components/toeflIbt/ToeflListeningAudioRe";
import ToeflReadingDirections from '../../components/toeflIbt/ToeflReadingDirections';
import ToeflReadingQuestion from '../../components/toeflIbt/ToeflReadingQuestion';
import ToeflReadingText from "../../components/toeflIbt/ToeflReadingText";
import ToeflReadingAnswer from "../../components/toeflIbt/ToeflReadingAnswer";

function ToeflIbt() {
    let history = useHistory();
    const {parentPath, section, childPath} = getRouteInfo();
    const [isCountdownPaused, setIsCountdownPaused] = useState(false);
    useEffect(() => {
        if (!childPath) {
            history.replace(`${parentPath}/directions`);
        }
    }, [parentPath, section]);

    return (
        <ToeflProvider>
            <Flex direction="column" height="100vh">
                <ToeflIbtMenu isCountdownPaused={isCountdownPaused} setIsCountdownPaused={setIsCountdownPaused}/>
                <Box flex="1" overflowY="auto">
                    {/*<Box height="25%"></Box>*/}
                    <Switch>
                        <Route path={`${parentPath}/directions`} render={() => (
                            <>
                                <Box height="20%"></Box>
                                {section === 'listening' && <ToeflListeningDirections/>}
                                {section === 'reading' && <ToeflReadingDirections/>}
                            </>
                        )}/>
                        <Route path={`${parentPath}/audio/:partIdx`} render={() => (
                            <>
                                <Box height="10%"></Box>
                                <ToeflListeningAudio/>
                            </>
                        )}/>
                        <Route path={`${parentPath}/re_play/:partIdx/:subIdx`} render={() => (
                            <>
                                <Box height="10%"></Box>
                                <ToeflListeningAudioRe/>
                            </>
                        )}/>
                        <Route path={`${parentPath}/text/:partIdx/`} render={() => (
                            <>
                                <ToeflReadingText/>
                            </>
                        )}/>
                        <Route path={`${parentPath}/question/:partIdx/:subIdx`} render={() => (
                            <>
                                {section === 'listening' &&
                                    <>
                                        <Box height="10%"></Box>
                                        <ToeflListeningQuestion setIsCountdownPaused={setIsCountdownPaused}/>
                                    </>}
                                {section === 'reading' && <ToeflReadingQuestion/>}
                            </>)}/>
                        <Route path={`${parentPath}/answer`} render={() => (
                            <>
                                {section === 'listening' && <ToeflListeningAnswer/>}
                                {section === 'reading' && <ToeflReadingAnswer/>}
                            </>
                        )}/>
                    </Switch>
                </Box>
            </Flex>
        </ToeflProvider>
    );
}

export default ToeflIbt;
